import React from 'react'
import BarChart from './Charts/BarChart'
import LineChart from './Charts/LineChart'
import PieDonutChart from './Charts/PieDonutChart'
import PieSimpleChart from './Charts/PieSimpleChart'

export const Dashboard = () => {
    return (
        <div>
 <div className='content-page'>
            <div className="content">
                {/* Start Content*/}
                <div className="container-fluid">
                    {/* start page title */}
                    <div className="row">
                        <div className="col-12">
                            <div className="page-title-box">
                                <h4 className="page-title">Dashboard</h4>
                                <div>
                                    <ol className="breadcrumb m-0">
                                        <li className="breadcrumb-item">
                                            <a href="javascript: void(0);">{window.projectName}</a>
                                        </li>
                                        <li className="breadcrumb-item">
                                            <a href="javascript: void(0);">Navigation</a>
                                        </li>
                                        <li className="breadcrumb-item active">Dashboard</li>
                                    </ol>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="row">
                        <div className="col-12">
                            <div className="card">
                                <div className="card-body">
                                    <div className="row">
                                        <div className="col-md-6 col-xl-3">
                                            <div className="widget-rounded-circle card bg-purple shadow-none">
                                                <div className="card-body">
                                                    <div className="row">
                                                        <div className="col-6">
                                                            <div className="avatar-lg rounded-circle bg-soft-light">
                                                                <i className="fe-bar-chart-line- font-28 avatar-title text-white" />
                                                            </div>
                                                        </div>
                                                        <div className="col-6">
                                                            <div className="text-end">
                                                                <h2 className="text-white mt-2">
                                                                    <span data-plugin="counterup">92,84</span>
                                                                </h2>
                                                                <p className="text-white mb-0 text-truncate">Statistics</p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    {/* end row*/}
                                                </div>
                                            </div>
                                            {/* end widget-rounded-circle*/}
                                        </div>
                                       
                                        <div className="col-md-6 col-xl-3">
                                            <div className="widget-rounded-circle card bg-info shadow-none">
                                                <div className="card-body">
                                                    <div className="row">
                                                        <div className="col-6">
                                                            <div className="avatar-lg rounded-circle bg-soft-light">
                                                                <i className="fe-users font-28 avatar-title text-white" />
                                                            </div>
                                                        </div>
                                                        <div className="col-6">
                                                            <div className="text-end">
                                                                <h2 className="text-white mt-2">
                                                                    <span data-plugin="counterup">56</span>k
                                                                </h2>
                                                                <p className="text-white mb-0 text-truncate">Users</p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    {/* end row*/}
                                                </div>
                                            </div>
                                            {/* end widget-rounded-circle*/}
                                        </div>
                                       
                                        <div className="col-md-6 col-xl-3">
                                            <div className="widget-rounded-circle card bg-pink shadow-none">
                                                <div className="card-body">
                                                    <div className="row">
                                                        <div className="col-6">
                                                            <div className="avatar-lg rounded-circle bg-soft-light">
                                                                <i className="fe-shuffle font-28 avatar-title text-white" />
                                                            </div>
                                                        </div>
                                                        <div className="col-6">
                                                            <div className="text-end">
                                                                <h2 className="text-white mt-2">
                                                                    <span data-plugin="counterup">8</span>
                                                                </h2>
                                                                <p className="text-white mb-0 text-truncate">
                                                                    Admins
                                                                </p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    {/* end row*/}
                                                </div>
                                            </div>
                                            {/* end widget-rounded-circle*/}
                                        </div>
                                       
                                        <div className="col-md-6 col-xl-3">
                                            <div className="widget-rounded-circle card bg-success shadow-none">
                                                <div className="card-body">
                                                    <div className="row">
                                                        <div className="col-6">
                                                            <div className="avatar-lg rounded-circle bg-soft-light">
                                                                <i className="fe-download font-28 avatar-title text-white" />
                                                            </div>
                                                        </div>
                                                        <div className="col-6">
                                                            <div className="text-end">
                                                                <h2 className="text-white mt-2">
                                                                    <span data-plugin="counterup">523</span>
                                                                </h2>
                                                                <p className="text-white mb-0 text-truncate">New Downloads</p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    {/* end row*/}
                                                </div>
                                            </div>
                                            {/* end widget-rounded-circle*/}
                                        </div>
                                       
                                    </div>

                                </div>
                            </div>
                            
                        </div>
                       
                    </div>
                    <div className="row">
                        <div className="col-lg-6">
                            <div className="card">
                                <div className="card-body">
                                    
                                    <div className="dropdown float-end">
                                        <a
                                            href="#"
                                            className="dropdown-toggle arrow-none card-drop"
                                            data-bs-toggle="dropdown"
                                            aria-expanded="false"
                                        >
                                            <i className="mdi mdi-dots-vertical" />
                                        </a>
                                        <div className="dropdown-menu dropdown-menu-end">
                                            <a href="javascript:void(0);" className="dropdown-item">
                                                Sales Report
                                            </a>
                                            <a href="javascript:void(0);" className="dropdown-item">
                                                Export Report
                                            </a>
                                            <a href="javascript:void(0);" className="dropdown-item">
                                                Profit
                                            </a>
                                            <a href="javascript:void(0);" className="dropdown-item">
                                                Action
                                            </a>
                                        </div>
                                    </div>
                                    <h4 className="header-title mb-0">Bar Chart</h4>
                                    <BarChart/>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-6">
                            <div className="card">
                                <div className="card-body">
                                    
                                    <div className="dropdown float-end">
                                        <a
                                            href="#"
                                            className="dropdown-toggle arrow-none card-drop"
                                            data-bs-toggle="dropdown"
                                            aria-expanded="false"
                                        >
                                            <i className="mdi mdi-dots-vertical" />
                                        </a>
                                        <div className="dropdown-menu dropdown-menu-end">
                                            <a href="javascript:void(0);" className="dropdown-item">
                                                Sales Report
                                            </a>
                                            <a href="javascript:void(0);" className="dropdown-item">
                                                Export Report
                                            </a>
                                            <a href="javascript:void(0);" className="dropdown-item">
                                                Profit
                                            </a>
                                            <a href="javascript:void(0);" className="dropdown-item">
                                                Action
                                            </a>
                                        </div>
                                    </div>
                                    <h4 className="header-title mb-0">Line Chart</h4>
                                    <LineChart/>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="row">
                        <div className="col-lg-6">
                            <div className="card">
                                <div className="card-body">
                                    
                                    <div className="dropdown float-end">
                                        <a
                                            href="#"
                                            className="dropdown-toggle arrow-none card-drop"
                                            data-bs-toggle="dropdown"
                                            aria-expanded="false"
                                        >
                                            <i className="mdi mdi-dots-vertical" />
                                        </a>
                                        <div className="dropdown-menu dropdown-menu-end">
                                            <a href="javascript:void(0);" className="dropdown-item">
                                                Sales Report
                                            </a>
                                            <a href="javascript:void(0);" className="dropdown-item">
                                                Export Report
                                            </a>
                                            <a href="javascript:void(0);" className="dropdown-item">
                                                Profit
                                            </a>
                                            <a href="javascript:void(0);" className="dropdown-item">
                                                Action
                                            </a>
                                        </div>
                                    </div>
                                    <h4 className="header-title mb-0">Pie Donut Chart</h4>
                                    <PieDonutChart/>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-6">
                            <div className="card">
                                <div className="card-body">
                                    
                                    <div className="dropdown float-end">
                                        <a
                                            href="#"
                                            className="dropdown-toggle arrow-none card-drop"
                                            data-bs-toggle="dropdown"
                                            aria-expanded="false"
                                        >
                                            <i className="mdi mdi-dots-vertical" />
                                        </a>
                                        <div className="dropdown-menu dropdown-menu-end">
                                            <a href="javascript:void(0);" className="dropdown-item">
                                                Sales Report
                                            </a>
                                            <a href="javascript:void(0);" className="dropdown-item">
                                                Export Report
                                            </a>
                                            <a href="javascript:void(0);" className="dropdown-item">
                                                Profit
                                            </a>
                                            <a href="javascript:void(0);" className="dropdown-item">
                                                Action
                                            </a>
                                        </div>
                                    </div>
                                    <h4 className="header-title mb-0">Pie Chart</h4>
                                    <PieSimpleChart/>
                                </div>
                            </div>
                        </div>
                    </div>

                    
                </div>
             
            </div>
            </div>

        </div>
    )
}
