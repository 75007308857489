import React from 'react'

export const AddMachinery_RB_insurance = () => {
  return (
    <div>
    <div
 id="Mdl_AddMachinery_RB_insurance"
 className="modal fade"
 tabIndex={-1}
 role="dialog"
 aria-hidden="true"
>
 <div
   className="modal-dialog modal-md modal-right"
   style={{ maxWidth: "500px", width: "100%" }}
 >
   <div className="modal-content h-100 ">
     <div className="modal-header border-0 p-0 w-100">
       <h6 class="fw-semibold px-3 m-0 py-2 font-16 bg-light w-100">
         <span class="d-block py-2">Add Insurance Details</span>
       </h6>
       <button
         type="button"
         className="btn-close"
         data-bs-dismiss="modal"
         aria-label="Close"
       />
     </div>
     <div className="modal-body">
      
     <div className='col-sm-12'>
         <div className="row">
         <div className='col-sm-12 mb-2'>
                 <label htmlFor="txt_adduser_name">Valid From</label>
                 <input type="date" className="form-control"  required></input>
               </div>
               <div className='col-sm-12 mb-2'>
                 <label htmlFor="txt_adduser_name">Valid To</label>
                 <input type="date" className="form-control"  required></input>
               </div>
               <div className='col-sm-12 mb-2'>
                 <label htmlFor="txt_adduser_name">Upload Insurance Certificate</label>
                 <input type="file" className="form-control"  required></input>
               </div>
             
               <div className='col-sm-12 mb-2'>
                 <label htmlFor="txt_adduser_name">Description</label>
                 <textarea  rows ="3" type="text" className="form-control"  required></textarea>
               </div>
           <div className='col-sm-12'>
           </div>
           <div className='col-sm-12'>
           </div>
         </div>
      
       </div>
       <div class="modal-footer mdlFooter">
         <button
           type="button"
           class="btn btn-secondary"
           data-bs-dismiss="modal"
         >
           Close
         </button>
         <button type="submit" class="btn btn-primary">
           Submit
         </button>
       </div>
     </div>
   </div>
 </div>
</div>
</div>
  )
}
