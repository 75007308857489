import { logDOM } from "@testing-library/react";
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { padLeadingZeros } from "../Include/Function/padLeadingZeros";
import { AddEmployee_RB } from "./Rightbar/Employee/AddEmployee_RB";

export const AddEmployee = () => {
  const [EmployeeListData, setEmployeeListData] = useState([]);
  const [EmployeeUpdate, setEmployeeUpdate] = useState(false)
  const [EmployeeEditData, setEmployeeEditData] = useState();
  const [EmployeeEditStatus, setEmployeeEditStatus] = useState(0);
  useEffect(() => {
    // return () => {
      GetMachineryListData();
    // }
  }, [EmployeeUpdate])
  
  const GetMachineryListData=()=>{
    fetch(`${window.endpoint}employee/list`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
      }})
    .then((response) => response.json())
    .then((data) => {
      if(data.status==="0"){
      console.log('Success:', data.data);
      setEmployeeListData(data.data)
      }
    })
    .catch((error) => {
      console.error('Error:', error);
    });
    
  }
  const EmployeeEdit = (e,data)=>{
    console.log('Edit');
    setEmployeeEditStatus(1)
    setEmployeeEditData(data)
  }
  const EmployeeAdd = ()=>{
    console.log('Add');
    setEmployeeEditStatus(0)
  }
 
  return (
    <div> <div className='content-page'>
      <div className="content">
        <div className="container-fluid">
          <div className="row">
            <div className="col-12">
              <div className="page-title-box">
                <h4 className="page-title">Employee List</h4>
                <div>
                  <ol className="breadcrumb m-0">
                    <li className="breadcrumb-item">
                      <a href="javascript: void(0);">{window.projectName}</a>
                    </li>
                    <li className="breadcrumb-item">
                      <a href="javascript: void(0);">Master</a>
                    </li>
                    <li className="breadcrumb-item active">Employee</li>
                  </ol>
                </div>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-12">
              <div className="card">
                <div className="card-body">
                  <div className="row">
                  <div className="col-lg-10 col-md-7 col-sm-6">
                      <div className="row">
                        <div className="col-sm-3 mt-1">
                        <form className="text-lg-end">
                          <input
                            type="search"
                            className="form-control"
                            id="inputPassword2"
                            placeholder="Search..."
                          />
                        </form>
                        </div>
                        <div className="col-sm-5 mt-1">
                        <button
                          type="button"
                          className="btn btn-danger waves-effect waves-light mb-sm-0"
                        >
                          <i className="mdi mdi-download" /> Download Pdf
                        </button>
                        <button
                          type="button"
                          className="btn btn-success waves-effect waves-light mb-sm-0 ms-1"
                        >
                          <i className="mdi mdi-download" /> Download1 Xlsx
                        </button>
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-2 col-md-5 col-sm-6 mt-1">
                      <button
                        type="button"
                        className="btn btn-primary waves-effect waves-light mb-sm-0 mb-2 me-2 w-100 "
                        data-bs-toggle="modal"
                        data-bs-target="#Mdl_AddEmployee_RB"
                        onClick={(e)=>{EmployeeAdd(e)}}
                      >
                        <i className="mdi mdi-plus-circle" 
                        /> Employee
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-12">
              <div className="card">
                <div className="card-body">
                  <div className="responsive-table-plugin">
                    <div className="table-rep-plugin">
                      <div
                        className="table-responsive"
                        data-pattern="priority-columns"
                      >
                        <table
                          id="tech-companies-1"
                          className="table table-striped pb-5 table-full-width-td"
                        >
                          <thead>
                            <tr>
                            <th>Action</th> 
                            <th>Sr.</th>
                            <th>Employee Id</th>
                            <th>Position</th>
                              <th>Name</th>
                              <th data-priority={1}>Contact No</th>
                              <th data-priority={3}>Alt Contact No</th>
                              <th data-priority={3}>Gender</th>
                              <th data-priority={1}>City</th>
                              <th data-priority={3}>State</th>
                              <th data-priority={3}>Aadhaar Card No.</th>
                              <th data-priority={6}>Pan Card No.</th>
                              <th data-priority={6}>Driving License No.</th>
                              <th data-priority={6}>Provident Fund No.</th>
                              <th data-priority={6}>ESI Code No.</th>
                              <th data-priority={6}>Emergency Contact Name</th>
                              <th data-priority={6}>Emergency Contact No.</th>
                            </tr>
                          </thead>
                          <tbody>
                          {EmployeeListData && EmployeeListData.map((data,index)=>{
                              return(
                            <tr key={index}>
                                  <td>
                              <div className="dropdown float-start">
                                      <a
                                        href="#"
                                        className="dropdown-toggle arrow-none card-drop"
                                        data-bs-toggle="dropdown"
                                        aria-expanded="false"
                                      >
                                        <i className="mdi mdi-dots-vertical" />
                                      </a>
                                      <div className="dropdown-menu dropdown-menu-start">
                                        {/* item*/}
                                        <Link to={`${process.env.PUBLIC_URL}/employee/details`} type="button" className="dropdown-item text-primary text-decoration-none">
                                        <i className="mdi mdi-view-array-outline me-1"></i>View Details
                                        </Link>
                                       
                                        <span type="button" data-bs-toggle="modal"
                                        data-bs-target="#Mdl_AddEmployee_RB" onClick={(e)=>{EmployeeEdit(e,data)}} className="dropdown-item text-danger">
                                        <i className="mdi mdi-book-edit-outline me-1"></i>Edit
                                        </span>

                                        <a href="javascript:void(0);" className="dropdown-item text-danger">
                                        <i className="mdi mdi-eye-off-outline me-1"></i>Deactivate
                                        </a>

                                        <a href="javascript:void(0);" className="dropdown-item text-danger">
                                        <i className="mdi mdi-delete-outline me-1"></i> Delete
                                        </a>
                                      </div>
                                    </div>

                              </td>
                              <td>{index+1}</td>
                             
                              <td><span className="badge bg-primary">{padLeadingZeros(data.employee_id,4)}</span></td>
                               <td>{data.employee_position==="1"?'Truck Driver':data.employee_position==="2"?'Hywa Driver':data.employee_position==="3"?'Machine Operator':data.employee_position==="4"?'Helper':data.employee_position==="5"?'Mechanic':data.employee_position==="6"?'Super Wiser':data.employee_position==="7"?'Manager':data.employee_position==="8"?'Other':'-'}</td>
                              <td>{data.employee_name}</td>
                              <td>{data.employee_contactno}</td>
                              <td>{data.employee_contactno_alt}</td>
                              <td>{data.employee_gender==="1"?'MALE':'FEMALE'}</td>
                              <td>{data.employee_city}</td>
                              <td>{data.employee_state}</td>
                              <td>{data.employee_aadhar_card_no}</td>
                              <td>{data.employee_pancard_no}</td>
                              <td>{data.employee_driving_license_no}</td>
                              <td>{data.employee_pfno}</td>
                              <td>{data.employee_esicno}</td>
                              <td>{data.employee_emer_name}</td>
                              <td>{data.employee_emer_contactno}</td>
                              
                            </tr>
                              )})}
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <AddEmployee_RB EmployeeEditData={EmployeeEditData} setEmployeeUpdate={setEmployeeUpdate} EmployeeUpdate={EmployeeUpdate} EmployeeEditStatus={EmployeeEditStatus} time={Date().toLocaleString()}/>
      </div>
      </div>
    </div>
  );
};
