import React, { useContext, useEffect, useRef, useState } from 'react'
import LanguageContext from '../../../Include/Context/LanguageContext';

export const AddProduct_RB = ({ProductUpdate,setProductUpdate,ProductEditStatus,ProductEditData,Time}) => {
  let closeAddProduct_RB = useRef();
  let focusAddProduct_RB = useRef();
  const [BtnProductLoader, setBtnProductLoader] = useState(false);
  const {setSnackBarData} = useContext(LanguageContext) ;

  const [AddProductFormData, setAddProductFormData] = useState({
    txt_addproduct_id:'0',
    txt_addproduct_name:"",
    txt_addproduct_status:'0'
  });
  const AddProductFormDataClear = ()=>{
    setAddProductFormData({
      txt_addproduct_id:'0',
      txt_addproduct_name:"",
      txt_addproduct_status:'0'
    })
  }
  const AddCityFormDataUpdate = (data)=>{
    setAddProductFormData({
      txt_addproduct_id:data.product_id,
      txt_addproduct_name:data.product_name,
      txt_addproduct_status:data.status
    })
  }
  useEffect(() => {
    // return () => {
      if(ProductEditStatus===0){
        AddProductFormDataClear();
   
      }
      if(ProductEditStatus===1){
       console.log(ProductEditData)
       AddCityFormDataUpdate(ProductEditData)
      
      }
      focusAddProduct_RB.current.focus();

  }, [Time]);
  const InputChange = (e)=>{
    setAddProductFormData({...AddProductFormData,[e.target.name]:e.target.value})
    }
  const frmProductCity=(e)=>{
    e.preventDefault();
    setBtnProductLoader(true)
    
    fetch(`${window.endpoint}product/add`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(AddProductFormData),
    })
    .then((response) => response.json())
    .then((data) => {
      console.log('Success:', data.data.status);
      if(data.data.status===0 || data.data.status==="0"){
        ProductUpdate?setProductUpdate(false):setProductUpdate(true)
        closeAddProduct_RB.current.click();
        setSnackBarData({
          alert: "success",
          message:data.data.message,
          open: true
        })
      }
      setBtnProductLoader(false)
    })
    .catch((error) => {
      console.error('Error:', error);
      setBtnProductLoader(false)
    });
    
  }
    return (
        <>
          {/* Right modal content */}
          <div
            id="Mdl_AddProduct_RB"
            className="modal fade"
            tabIndex={-1}
            role="dialog"
            aria-hidden="true"
          >
            <div
              className="modal-dialog modal-md modal-right"
              style={{ maxWidth: "500px", width: "100%" }}
            >
              <div className="modal-content  h-100">
                <h6 className="fw-semibold px-3 m-0 py-2 font-16 bg-light">
                  <span className="d-block py-2">ADD PRODUCT</span>
                </h6>
                <div className="modal-header p-0 border-0">
                  {/* <button
                    type="button"
                    className="btn-close"
                    data-bs-dismiss="modal"
                    aria-label="Close"
                  /> */}
                </div>
                <form id="frm_AddProduct" onSubmit={(e)=>{frmProductCity(e)}}>
                  <div className="modal-body">
                    <label className="form-label">Product Name</label>
                    <input
                      type="text"
                      name="txt_addproduct_name"
                      id="txt_addproduct_name"
                      value={AddProductFormData.txt_addproduct_name}
                      className="form-control mb-2"
                      placeholder="Enter Product Name"
                      onChange={InputChange} 
                      required
                      ref={focusAddProduct_RB}
                    />
                   
                    
                  
    
                    <div className="modal-footer mdlFooter">
                      <button
                        type="button"
                        className="btn btn-secondary"
                        data-bs-dismiss="modal"
                        ref={closeAddProduct_RB}
                      >
                        Close
                      </button>
                      {!BtnProductLoader && <><button type="submit" className="btn btn-primary btnLoader" >
                Submit
              </button>
              </>
              }
              {BtnProductLoader && <>
                <button className="btn btn-primary btnLoader" type="button" disabled="">
                                                        <span className="spinner-border spinner-border-sm me-1" role="status" aria-hidden="true"></span>
                                                        Loading...
                                                    </button>
              </>}
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </>
      );
}
