import React, { useContext, useEffect, useRef, useState } from 'react'
import LanguageContext from '../../../Include/Context/LanguageContext';



export const AddMachinery_RB = ({setMachineryUpdate,MachineryUpdate,MachineryEditData,MachineryEditStatus,time}) => {
  let closeAddMachiner_RB = useRef();
  const {setSnackBarData} = useContext(LanguageContext) ;
const [BtnSubmitLoader, setBtnSubmitLoader] = useState(false)
const [FormMachineryDetails, setFormMachineryDetails] = useState({
txt_machinery_id:"0",
txt_machinery_ownername:"",
txt_machinery_ownercontact:"",
txt_machinery_owneraddress:"",
txt_machinery_brandname:"",
txt_machinery_detail_type:"0",
txt_machinery_machineryno:"",
txt_machinery_modelno:"",
txt_machinery_modelyear:"",
txt_machinery_chasisno:"",
txt_machinery_engineno:"",
txt_machinery_capacity:"",
txt_machinery_rcno:"",
txt_machinery_description:"",
txt_machinery_purchasedate:"",
txt_machinery_modeofpayment:"",
txt_machinery_bankname:"",
txt_machinery_installmentamount:"",
txt_machinery_installmentmonth:"",
txt_machinery_paymentdescription:"",
txt_machinery_showroomcontactname:"",
txt_machinery_showroomcontactno:"",
txt_machinery_status:"0",
})
const FormMachineryDetailsClear = ()=>{
  setFormMachineryDetails({
    txt_machinery_id:"0",
    txt_machinery_ownername:"",
    txt_machinery_ownercontact:"",
    txt_machinery_owneraddress:"",
    txt_machinery_brandname:"",
    txt_machinery_detail_type:"0",
    txt_machinery_machineryno:"",
    txt_machinery_modelno:"",
    txt_machinery_modelyear:"",
    txt_machinery_chasisno:"",
    txt_machinery_engineno:"",
    txt_machinery_capacity:"",
    txt_machinery_rcno:"",
    txt_machinery_description:"",
    txt_machinery_purchasedate:"",
    txt_machinery_modeofpayment:"",
    txt_machinery_bankname:"",
    txt_machinery_installmentamount:"",
    txt_machinery_installmentmonth:"",
    txt_machinery_paymentdescription:"",
    txt_machinery_showroomcontactname:"",
    txt_machinery_showroomcontactno:"",
    txt_machinery_status:"0",
    })
}
const FormMachineryDetailsUpdate = (data)=>{
  console.log(data,'from Edit')
  setFormMachineryDetails({
    txt_machinery_id:data.machinery_id,
    txt_machinery_ownername:data.owner_name,
    txt_machinery_ownercontact:data.owner_contact_no,
    txt_machinery_owneraddress:data.owner_address,
    txt_machinery_brandname:data.machinery_brand,
    txt_machinery_detail_type:data.machinery_type,
    txt_machinery_machineryno:data.machinery_no,
    txt_machinery_modelno:data.machinery_model,
    txt_machinery_modelyear:data.machinery_year,
    txt_machinery_chasisno:data.machinery_chasis_no,
    txt_machinery_engineno:data.machinery_engine_no,
    txt_machinery_capacity:data.machinery_capacity,
    txt_machinery_rcno:data.machinery_rc_no,
    txt_machinery_description:data.machinery_description,
    txt_machinery_purchasedate:data.machinery_purchase_date,
    txt_machinery_modeofpayment:data.machinery_modeof_payment,
    txt_machinery_bankname:data.machinery_financer_bankname,
    txt_machinery_installmentamount:data.machinery_installmentamount,
    txt_machinery_installmentmonth:data.machinery_installmentmonth,
    txt_machinery_paymentdescription:data.machinery_payment_description,
    txt_machinery_showroomcontactname:data.machinery_showroomname,
    txt_machinery_showroomcontactno:data.machinery_showroomcontact,
    txt_machinery_status:"0",
    })
}
const InputChange = (e)=>{
setFormMachineryDetails({...FormMachineryDetails,[e.target.name]:e.target.value})
}
useEffect(() => {
if(MachineryEditStatus===0){
  FormMachineryDetailsClear();
}else{
  FormMachineryDetailsUpdate(MachineryEditData)
}
}, [time])

const frmAddMachinery=(e)=>{
  e.preventDefault();
  setBtnSubmitLoader(true)
  
  fetch(`${window.endpoint}machinery/add`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(FormMachineryDetails),
  })
  .then((response) => response.json())
  .then((data) => {
    console.log('Success:', data.data.status);
    if(data.data.status===0 || data.data.status==="0"){
      MachineryUpdate?setMachineryUpdate(false):setMachineryUpdate(true)
      closeAddMachiner_RB.current.click();
      setSnackBarData({
        alert: "success",
        message:data.data.message,
        open: true
      })
    }
    setBtnSubmitLoader(false)
  })
  .catch((error) => {
    console.error('Error:', error);
  });
  
}
return (
    <>
    <div
      id="Mdl_AddMachinery_RB"
      className="modal fade"
      tabIndex={-1}
      role="dialog"
      aria-hidden="true"
    >
      <div
        className="modal-dialog modal-md modal-right"
        style={{ maxWidth: "500px", width: "100%" }}
      >
        <div className="modal-content h-100 ">
          <div className="modal-header border-0 p-0 w-100">
            <h6 className="fw-semibold px-3 m-0 py-2 font-16 bg-light w-100">
              <span className="d-block py-2">Add Machinery</span>
            </h6>
            {/* <button
              type="button"
              className="btn-close"
              data-bs-dismiss="modal"
              aria-label="Close"
            /> */}
          </div>
          <div className="modal-body">
            <form onSubmit={(e)=>{frmAddMachinery(e)}}>
            <div className="row ms-1 me-1">
              <div className="col-sm-12">
                <div className="row">
                  <fieldset className="border  pb-2">
                    <legend className="float-none w-auto legendFontsize text-primary">
                      Owner Details:
                    </legend>
                    <div className="col-sm-12">
                      <div className="row">
                        <div className="col-sm-12 mb-1">
                          <label>Name:</label>
                            <input type="text" className="form-control" name="txt_machinery_ownername" id="txt_machinery_ownername" value={FormMachineryDetails.txt_machinery_ownername} onChange={InputChange} required></input>
                        </div>
                        <div className="col-sm-12 mb-1">
                          <label>Contact No.:</label>
                          <input type="text" className="form-control" name="txt_machinery_ownercontact" id="txt_machinery_ownercontact" value={FormMachineryDetails.txt_machinery_ownercontact} onChange={InputChange} required></input>
                        </div>
                        <div className="col-sm-12 mb-1">
                          <label>Address:</label>
                          <input type="text" className="form-control" name="txt_machinery_owneraddress" id="txt_machinery_owneraddress" value={FormMachineryDetails.txt_machinery_owneraddress} onChange={InputChange} required></input>
                        </div>
                      </div>
                    </div>
                  </fieldset>
                </div>
              </div>
              <div className="col-sm-12">
                <div className="row">
                  <fieldset className="border pb-2">
                    <legend className="float-none w-auto legendFontsize text-primary">
                      Machinery Details:
                    </legend>
                    <div className="col-sm-12">
                      <div className="row">
                      <div className="col-sm-12 mb-1">
                          <label>Brand Name</label>
                          <input type="text" className="form-control" name="txt_machinery_brandname" id="txt_machinery_brandname" value={FormMachineryDetails.txt_machinery_brandname} onChange={InputChange} required></input>
                        </div>
                        <div className="col-sm-12 mb-1">
                          <label>Type </label>
                          <select type="text" className="form-select" name="txt_machinery_detail_type" id="txt_machinery_detail_type"  value={FormMachineryDetails.txt_machinery_detail_type}  onChange={InputChange} required>
                             <option value="">Select</option>
                             <option value="1">Truck</option>
                             <option value="2">Hywa</option>
                             <option value="3">Equipment</option>
                          </select>
                        </div>
                     
                        {(FormMachineryDetails.txt_machinery_detail_type==="1" || FormMachineryDetails.txt_machinery_detail_type==="2"|| FormMachineryDetails.txt_machinery_detail_type==="3") && <>
                        <div className="col-sm-12 mb-1">
                          <label>{FormMachineryDetails.txt_machinery_detail_type==="1"?'Truck':FormMachineryDetails.txt_machinery_detail_type==="2"?'Hywa':FormMachineryDetails.txt_machinery_detail_type==="3"?"Equipment":'Machinery'} No.</label>
                          <input type="text" className="form-control" name="txt_machinery_machineryno" id="txt_machinery_machineryno" value={FormMachineryDetails.txt_machinery_machineryno} onChange={InputChange} required></input>
                        </div>
                        </> }
                        <div className="col-sm-12 mb-1">
                          <label>Model/Year</label>
                          <div class="input-group ">
                            <input
                              type="text"
                              className="form-control"
                              placeholder="Model"
                              name="txt_machinery_modelno" id="txt_machinery_modelno" value={FormMachineryDetails.txt_machinery_modelno} onChange={InputChange} required
                            ></input>
                            <input
                              type="text"
                              className="form-control"
                              placeholder="Year"
                              name="txt_machinery_modelyear" id="txt_machinery_modelyear" value={FormMachineryDetails.txt_machinery_modelyear} onChange={InputChange} required
                            ></input>
                          </div>
                        </div>
                        <div className="col-sm-12 mb-1">
                          <label>Chasis No.</label>
                          <input type="text" className="form-control" name="txt_machinery_chasisno" id="txt_machinery_chasisno" value={FormMachineryDetails.txt_machinery_chasisno} onChange={InputChange} required></input>
                        </div>
                        <div className="col-sm-12 mb-1">
                          <label>Engine No.</label>
                          <input type="text" className="form-control" name="txt_machinery_engineno" id="txt_machinery_engineno" value={FormMachineryDetails.txt_machinery_engineno} onChange={InputChange} required></input>
                        </div>
                        <div className="col-sm-12 mb-1">
                          <label>Capacity</label>
                          <input type="text" className="form-control" name="txt_machinery_capacity" id="txt_machinery_capacity" value={FormMachineryDetails.txt_machinery_capacity} onChange={InputChange} required></input>
                        </div>
                        <div className="col-sm-12 mb-1">
                          <label>Registration Certificate-RC No.</label>
                          <input type="text" className="form-control" name="txt_machinery_rcno" id="txt_machinery_rcno" value={FormMachineryDetails.txt_machinery_rcno} onChange={InputChange} required></input>
                        </div>
                        <div className="col-sm-12 mb-1">
                          <label>Description</label>
                          <textarea rows="3" type="text" className="form-control" name="txt_machinery_description" id="txt_machinery_description" value={FormMachineryDetails.txt_machinery_description} onChange={InputChange}></textarea>
                        </div>
                      </div>
                    </div>
                  </fieldset>
                </div>
              </div>
              <div className="col-sm-12">
                <div className="row">
                  <fieldset className="border pb-2">
                    <legend className="float-none w-auto legendFontsize text-primary">
                      Payment Details:
                    </legend>
                    <div className="col-sm-12">
                      <div className="row">
                      <div className="col-sm-12 mb-1">
                          <label>Purchase Date</label>
                          <input type="date" className="form-control" name="txt_machinery_purchasedate" id="txt_machinery_purchasedate" onChange={InputChange} required value={FormMachineryDetails.txt_machinery_purchasedate}></input>
                        </div>
                      <div className="col-sm-12 mb-1">
                          <label>Mode Of Payment</label>
                          <select type="text" className="form-control" name="txt_machinery_modeofpayment" id="txt_machinery_modeofpayment" value={FormMachineryDetails.txt_machinery_modeofpayment} onChange={InputChange}>
                            <option value="0">Select</option>
                            <option value="1">Full Payment</option>
                            <option value="2">EMI Payment</option>
                          </select>
                        </div>
                       
                       

                        <div className="col-sm-12 mb-1">
                          <label>Financer Bank Name</label>
                          <input type="name" className="form-control" name="txt_machinery_bankname" id="txt_machinery_bankname" onChange={InputChange} value={FormMachineryDetails.txt_machinery_bankname} ></input>
                        </div>
                        <div className="col-sm-12 mb-1">
                          <label>Installment Amount/Month</label>
                          <div class="input-group ">
                            <input
                              type="text"
                              className="form-control"
                              placeholder="Installment Amount"
                              name="txt_machinery_installmentamount" id="txt_machinery_installmentamount" onChange={InputChange}
                              value={FormMachineryDetails.txt_machinery_installmentamount}></input>
                            <input
                              type="text"
                              className="form-control"
                              placeholder="Months"
                              name="txt_machinery_installmentmonth" id="txt_machinery_installmentmonth" onChange={InputChange}
                              value={FormMachineryDetails.txt_machinery_installmentmonth} ></input>
                          </div>
                        </div>
                       
                       
                        <div className="col-sm-12 mb-1">
                          <label>Description</label>
                          <textarea rows="3" type="text" className="form-control"
                          name="txt_machinery_paymentdescription" id="txt_machinery_paymentdescription" onChange={InputChange}
                          value={FormMachineryDetails.txt_machinery_paymentdescription}></textarea>
                        </div>
                      </div>
                    </div>
                  </fieldset>
                  </div>
                  </div>
                  <div className="col-sm-12 ">
                <div className="row">
                  <fieldset className="border pb-2">
                    <legend className="float-none w-auto legendFontsize text-primary">
                      Other Details:
                    </legend>
                    <div className="col-sm-12 mb-1">
                      <div className="row">
                      
                        <div className="col-sm-12 mb-1">
                          <label>Showroom Name</label>
                          <input type="name" className="form-control"
                          name="txt_machinery_showroomcontactname" id="txt_machinery_showroomcontactname" onChange={InputChange} value={FormMachineryDetails.txt_machinery_showroomcontactname} ></input>
                        </div>
                        <div className="col-sm-12 mb-1">
                          <label>Showroom Contact No</label>
                          <input type="name" className="form-control"
                          name="txt_machinery_showroomcontactno" id="txt_machinery_showroomcontactno" onChange={InputChange}  value={FormMachineryDetails.txt_machinery_showroomcontactno} ></input>
                        </div>

                       
                       
                      
                      </div>
                    </div>
                  </fieldset>
                  </div>
                  </div>
            </div>
            <div class="modal-footer mdlFooter">
              <button
                type="button"
                class="btn btn-secondary"
                data-bs-dismiss="modal"
                ref={closeAddMachiner_RB}
              >
                Close
              </button>
              {!BtnSubmitLoader && <><button type="submit" class="btn btn-primary btnLoader" >
                Submit
              </button>
              </>
              }
              {BtnSubmitLoader && <>
                <button class="btn btn-primary btnLoader" type="button" disabled="">
                                                        <span class="spinner-border spinner-border-sm me-1" role="status" aria-hidden="true"></span>
                                                        Loading...
                                                    </button>
              </>}
            </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  </>
  )
}