import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import { padLeadingZeros } from '../../../Include/Function/padLeadingZeros'

import { AddMachinery_RB } from './AddMachinery_RB'
import { AddMachinery_RB_engineoil } from './AddMachinery_RB_engineoil'
import { AddMachinery_RB_fitness } from './AddMachinery_RB_fitness'
import { AddMachinery_RB_insurance } from './AddMachinery_RB_insurance'
import { AddMachinery_RB_permit } from './AddMachinery_RB_permit'
import { AddMachinery_RB_puc } from './AddMachinery_RB_puc'
import { AddMachinery_RB_roadtax } from './AddMachinery_RB_roadtax'

export const AddMachinery = () => {

  const [MachinertListData, setMachinertListData] = useState([]);
  const [MachineryUpdate, setMachineryUpdate] = useState(false)
  const [MachineryEditData, setMachineryEditData] = useState();
  const [MachineryEditStatus, setMachineryEditStatus] = useState(0);
  useEffect(() => {
    // return () => {
      GetMachineryListData();
    // }
  }, [MachineryUpdate])
  
  const GetMachineryListData=()=>{
    fetch(`${window.endpoint}machinery/list`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
      }})
    .then((response) => response.json())
    .then((data) => {
      if(data.status==="0"){
      console.log('Success:', data.data);
      setMachinertListData(data.data)
      }
    })
    .catch((error) => {
      console.error('Error:', error);
    });
    
  }
  const MachineryAdd = ()=>{
   console.log('Add');
   setMachineryEditStatus(0)
  }
  const MachineryEdit = (e,data)=>{
    console.log('Edit');
    setMachineryEditStatus(1)
    setMachineryEditData(data)
  }
  return (
    <div> <div className='content-page'>
      <div className="content">
        <div className="container-fluid">
          <div className="row">
            <div className="col-12">
              <div className="page-title-box">
                <h4 className="page-title">Machinery List</h4>
                <div>
                  <ol className="breadcrumb m-0">
                    <li className="breadcrumb-item">
                      <a href="javascript: void(0);">{window.projectName}</a>
                    </li>
                    <li className="breadcrumb-item">
                      <a href="javascript: void(0);">Master</a>
                    </li>
                    <li className="breadcrumb-item active">Machinery</li>
                  </ol>
                </div>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-12">
              <div className="card">
                <div className="card-body">
                  <div className="row">
                    <div className="col-lg-10 col-md-7 col-sm-6">
                      <div className="row">
                        <div className="col-sm-3 mt-1">
                        <form className="text-lg-end">
                          <input
                            type="search"
                            className="form-control"
                            id="inputPassword2"
                            placeholder="Search..."
                          />
                        </form>
                        </div>
                        <div className="col-sm-5 mt-1">
                        <button
                          type="button"
                          className="btn btn-danger waves-effect waves-light mb-sm-0"
                        >
                          <i className="mdi mdi-download" /> Download Pdf
                        </button>
                        <button
                          type="button"
                          className="btn btn-success waves-effect waves-light mb-sm-0 ms-1"
                        >
                          <i className="mdi mdi-download" /> Download1 Xlsx
                        </button>
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-2 col-md-5 col-sm-6 mt-1">
                      <button
                        type="button"
                        className="btn btn-primary waves-effect waves-light mb-sm-0  me-2 w-100 "
                        data-bs-toggle="modal"
                        data-bs-target="#Mdl_AddMachinery_RB"  onClick={(e)=>{MachineryAdd(e)}}
                      >
                        <i className="mdi mdi-plus-circle f-12" /> Machinery
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-12">
              <div className="card">
                <div className="card-body">
                  <div className="responsive-table-plugin">
                    <div className="table-rep-plugin ">
                      <div
                        className="table-responsive mb-5"
                        data-pattern="priority-columns "
                      >
                        <table
                          id="tech-companies-1"
                          className="table table-striped pb-5 table-full-width-td"
                        >
                          <thead>
                            <tr>
                            <th data-priority={6}>Action</th>  
                            <th data-priority={1}>Sr No.</th>
                            <th data-priority={1}>Machinery Id</th>   
                              <th data-priority={1}>Type/Number</th>
                              <th data-priority={3}>Model/Year</th>
                              <th data-priority={3}>Brand Name</th>
                              <th data-priority={3}>RC No.</th>
                              <th data-priority={6}>Description</th>
                              <th data-priority={6}>Fitness Due Date</th>
                              <th data-priority={6}>Permit Due Date</th>
                              <th data-priority={6}>PUc Due Date</th>
                              <th data-priority={6}>Road Tax Due Date</th>
                              <th data-priority={6}>Insurance Due Date</th>
                              <th data-priority={6}>Last engine Oil Change</th>
                              <th data-priority={6}>Owner Name</th>
                              <th data-priority={6}>Owner Contact</th>

                            </tr>
                          </thead>
                          <tbody>
                            {MachinertListData && MachinertListData.map((data,index)=>{
                              return(
                              <tr key={index}>
                                  <td>
                              <div className="dropdown float-start">
                                      <a
                                        href="#"
                                        className="dropdown-toggle arrow-none card-drop"
                                        data-bs-toggle="dropdown"
                                        aria-expanded="false"
                                      >
                                        <i className="mdi mdi-dots-vertical" />
                                      </a>
                                      <div className="dropdown-menu dropdown-menu-start">
                                        {/* item*/}
                                        <Link to={`${process.env.PUBLIC_URL}/machinery/details`} type="button" className="dropdown-item text-primary text-decoration-none">
                                        <i className="mdi mdi-view-array-outline me-1"></i>View Details
                                        </Link>
                                        <span type="button" className="dropdown-item"   data-bs-toggle="modal"
                                         data-bs-target="#Mdl_AddMachinery_RB_engineoil">
                                        <i className="mdi mdi-update me-1"></i>Update Engine Oil
                                        </span>
                                        {/* item*/}
                                        <span type="button" className="dropdown-item"   data-bs-toggle="modal"
                                         data-bs-target="#Mdl_AddMachinery_RB_fitness">
                                        <i className="mdi mdi-update me-1"></i>Update Fitness
                                        </span>
                                        {/* item*/}
                                        <a href="javascript:void(0);" className="dropdown-item" data-bs-toggle="modal"
                                         data-bs-target="#Mdl_AddMachinery_RB_puc">
                                        <i className="mdi mdi-update me-1"></i>Update PUC
                                        </a>
                                        {/* item*/}
                                        <a href="javascript:void(0);" className="dropdown-item" data-bs-toggle="modal"
                                         data-bs-target="#Mdl_AddMachinery_RB_roadtax">
                                        <i className="mdi mdi-update me-1"></i>Update Road Tax
                                        </a>
                                        {/* item*/}
                                        <a href="javascript:void(0);" className="dropdown-item" data-bs-toggle="modal"
                                         data-bs-target="#Mdl_AddMachinery_RB_permit"> 
                                        <i className="mdi mdi-update me-1"></i>Update Permit
                                        </a>

                                        <a href="javascript:void(0);" className="dropdown-item" data-bs-toggle="modal"
                                         data-bs-target="#Mdl_AddMachinery_RB_insurance"> 
                                        <i className="mdi mdi-update me-1"></i>Update Insurance
                                        </a>
                                       
                                        <span type="button" data-bs-toggle="modal"
                                        data-bs-target="#Mdl_AddMachinery_RB" onClick={(e)=>{MachineryEdit(e,data)}} className="dropdown-item text-danger">
                                        <i className="mdi mdi-book-edit-outline me-1"></i>Edit
                                        </span>

                                        <a href="javascript:void(0);" className="dropdown-item text-danger">
                                        <i className="mdi mdi-eye-off-outline me-1"></i>Deactivate
                                        </a>

                                        <a href="javascript:void(0);" className="dropdown-item text-danger">
                                        <i className="mdi mdi-delete-outline me-1"></i> Delete
                                        </a>
                                      </div>
                                    </div>

                              </td>
                              <td>{index+1}</td>
                              <td><span className='badge bg-primary'>{padLeadingZeros(data.machinery_id,4)}</span></td>
                              <td>{data.machinery_type==="1"?'TRUCK':data.machinery_type==="2"?'HYWA':data.machinery_type==="3"?'EQUIPMENT':'-'}&nbsp;/&nbsp;{data.machinery_no}</td>
                              <td>{data.machinery_model}&nbsp;/&nbsp;{data.machinery_year}</td>
                              <td>{data.machinery_brand}
                              </td>
                              <td>{data.machinery_rc_no}</td>
                              <td>{data.machinery_description}</td>
                              <td>-</td>
                              <td>-</td>
                              <td>-</td>
                              <td>-</td>
                              <td>-</td>
                              <td>-</td>
                              <td>{data.owner_name}</td>
                              <td>{data.owner_contact_no}</td>
                              
                              
                            </tr>
                              )
                            })}
                            
                          
                           
                           
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        

        <AddMachinery_RB setMachineryUpdate={setMachineryUpdate} MachineryUpdate={MachineryUpdate} MachineryEditData={MachineryEditData} MachineryEditStatus={MachineryEditStatus} time={Date().toLocaleString()}/>
        <AddMachinery_RB_fitness/>
        <AddMachinery_RB_permit/>
        <AddMachinery_RB_puc/>
        <AddMachinery_RB_roadtax/>
        <AddMachinery_RB_engineoil/>
        <AddMachinery_RB_insurance/>
      </div>
    </div>
    </div>
  )
}
