import React, { useEffect, useState } from "react";
import { AddCity_RB } from "./AddCity_RB";
export const AddCity = () => {
  const [CityListData, setCityListData] = useState();
  const [CityEditStatus, setCityEditStatus] = useState(0);
  const [CityEditData, setCityEditData] = useState();
  const [CityUpdate, setCityUpdate] = useState(false);

  useEffect(() => {
    GetCityList();
  }, [CityUpdate]);

  const GetCityList = async () => {
    fetch(`${window.endpoint}city/list`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then((response) => response.json())
      .then((data) => {
        console.log("Success:", data.status);
        if (data.status === 0 || data.status === "0") {
          // MachineryUpdate?setMachineryUpdate(false):setMachineryUpdate(true)

          setCityListData(data.data);
          console.table(data.data);
        }
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  };
  const btnEditCity = (data) => {
    setCityEditStatus(1);
    setCityEditData(data);
  };
  const btnAddCity = () => {
    setCityEditStatus(0);
  };
  return (
    <div> <div className='content-page'>
      <div className="content">
        {/* Start Content*/}
        <div className="container-fluid">
          {/* start page title */}
          <div className="row">
            <div className="col-12">
              <div className="page-title-box">
                <h4 className="page-title">City List</h4>
                <div>
                  <ol className="breadcrumb m-0">
                    <li className="breadcrumb-item">
                      <a href="javascript: void(0);">{window.projectName}</a>
                    </li>
                    <li className="breadcrumb-item">
                      <a href="javascript: void(0);">Master</a>
                    </li>
                    <li className="breadcrumb-item active">City</li>
                  </ol>
                </div>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-12">
              <div className="card">
                <div className="card-body">
                  <div className="row">
                    <div className="col-lg-10 col-md-7 col-sm-6">
                      <div className="row">
                        <div className="col-sm-3 mt-1">
                          <form className="text-lg-end">
                            <input
                              type="search"
                              className="form-control"
                              id="inputPassword2"
                              placeholder="Search..."
                            />
                          </form>
                        </div>
                        <div className="col-sm-5 mt-1">
                          <button
                            type="button"
                            className="btn btn-danger waves-effect waves-light mb-sm-0"
                          >
                            <i className="mdi mdi-download" /> Download Pdf
                          </button>
                          <button
                            type="button"
                            className="btn btn-success waves-effect waves-light mb-sm-0 ms-1"
                          >
                            <i className="mdi mdi-download" /> Download1 Xlsx
                          </button>
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-2 col-md-5 col-sm-6 mt-1">
                      <button
                        type="button"
                        className="btn btn-primary waves-effect waves-light mb-sm-0 mb-2 me-2 w-100 "
                        data-bs-toggle="modal"
                        data-bs-target="#Mdl_AddCity_RB"
                        onClick={btnAddCity}
                      >
                        <i className="mdi mdi-plus-circle" /> City
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-12">
              <div className="card">
                <div className="card-body">
                  <div className="responsive-table-plugin">
                    <div className="table-rep-plugin">
                      <div
                        className="table-responsive"
                        data-pattern="priority-columns"
                      >
                        <table
                          id="tech-companies-1"
                          className="table table-striped"
                        >
                          <thead>
                            <tr>
                              <th>Sr</th>
                              <th data-priority={1}>City Name</th>
                              <th data-priority={3}>Action</th>
                            </tr>
                          </thead>
                          <tbody>
                            {CityListData &&
                              CityListData.map((data, index) => {
                                return (
                                  <tr key={index}>
                                    <td>{index + 1}</td>
                                    <th>{data.city_name && data.city_name.toUpperCase()}</th>
                                    <td>
                                      <span
                                        className="badge bg-danger"
                                        type="button"
                                        data-bs-toggle="modal"
                                        data-bs-target="#Mdl_AddCity_RB"
                                        onClick={(e) => {
                                          btnEditCity(data);
                                        }}
                                      >
                                        {" "}
                                        Edit
                                      </span>
                                    </td>
                                  </tr>
                                );
                              })}
                          </tbody>
                        </table>
                      </div>
                      {/* end .table-responsive */}
                    </div>
                    {/* end .table-rep-plugin*/}
                  </div>
                  {/* end .responsive-table-plugin*/}
                </div>
              </div>
              {/* end card */}
            </div>
            {/* end col */}
          </div>

          {/* end page title */}
        </div>
        {/* container */}
      </div>
      <AddCity_RB
        CityUpdate={CityUpdate}
        setCityUpdate={setCityUpdate}
        CityEditStatus={CityEditStatus}
        CityEditData={CityEditData}
        Time={Date().toLocaleString()}
      />
      </div>
    </div>
  );
};
