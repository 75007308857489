import React, { useEffect, useState } from 'react'
import { AddRoute_RB } from './AddRoute_RB'
export const AddRoute = () => {
  const [RouteListData, setRouteListData] = useState();
  const [RouteEditStatus, setRouteEditStatus] = useState(0);
  const [RouteEditData, setRouteEditData] = useState();
  const [RouteUpdate, setRouteUpdate] = useState(false)

useEffect(() => {
 GetRouteList();
}, [RouteUpdate])

const GetRouteList = async ()=>{
 
 fetch(`${window.endpoint}route/list`, {
   method: 'POST',
   headers: {
     'Content-Type': 'application/json',
   }
 })
 .then((response) => response.json())
 .then((data) => {
   console.log('Success:', data.status);
   if(data.status===0 || data.status==="0"){
     // MachineryUpdate?setMachineryUpdate(false):setMachineryUpdate(true)
  
       setRouteListData(data.data);
       console.table(data.data);
       
   }
  
 })
 .catch((error) => {
   console.error('Error:', error);
  
 });
}
const btnEditRoute = (data)=>{
 setRouteEditStatus(1);
 setRouteEditData(data)
}
const btnAddRoute = () =>{
 setRouteEditStatus(0);
}
  return (
    <div>
<div className='content-page'>
      <div className="content">
        {/* Start Content*/}
        <div className="container-fluid">
          {/* start page title */}
          <div className="row">
            <div className="col-12">
              <div className="page-title-box">
                <h4 className="page-title">Route List</h4>
                <div>
                  <ol className="breadcrumb m-0">
                    <li className="breadcrumb-item">
                      <a href="javascript: void(0);">{window.projectName}</a>
                    </li>
                    <li className="breadcrumb-item">
                      <a href="javascript: void(0);">Master</a>
                    </li>
                    <li className="breadcrumb-item active">Route</li>
                  </ol>
                </div>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-12">
              <div className="card">
                <div className="card-body">
                  <div className="row">
                  <div className="col-lg-10 col-md-7 col-sm-6">
                      <div className="row">
                        <div className="col-sm-3 mt-1">
                        <form className="text-lg-end">
                          <input
                            type="search"
                            className="form-control"
                            id="inputPassword2"
                            placeholder="Search..."
                          />
                        </form>
                        </div>
                        <div className="col-sm-5 mt-1">
                        <button
                          type="button"
                          className="btn btn-danger waves-effect waves-light mb-sm-0"
                        >
                          <i className="mdi mdi-download" /> Download Pdf
                        </button>
                        <button
                          type="button"
                          className="btn btn-success waves-effect waves-light mb-sm-0 ms-1"
                        >
                          <i className="mdi mdi-download" /> Download1 Xlsx
                        </button>
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-2 col-md-5 col-sm-6 mt-1">
                     
                      <button
                        type="button"
                        className="btn btn-primary waves-effect waves-light mb-sm-0 mb-2 me-2 w-100 "
                        data-bs-toggle="modal"
                        data-bs-target="#Mdl_AddRoute_RB"
                        onClick={btnAddRoute}
                      >
                        <i className="mdi mdi-plus-circle" /> Route
                      </button>
                    </div>
                  </div>

                </div>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-12">
              <div className="card">
                <div className="card-body">
                  <div className="responsive-table-plugin">
                    <div className="table-rep-plugin">
                      <div className="table-responsive" data-pattern="priority-columns">
                      <table
                          id="tech-companies-1"
                          className="table table-striped pb-5 table-full-width-td"
                        >
                          <thead>
                            <tr>
                              <th>Sr</th>
                              <th data-priority={1}>Route Name</th>
                              <th data-priority={1}>Company</th>
                              <th data-priority={1}>From</th>
                              <th data-priority={1}>Company</th>
                              <th data-priority={1}>To</th>
                              <th data-priority={1}>Distance</th>
                              <th data-priority={1}>Fair</th>
                              <th data-priority={1}>Description</th>
                              <th data-priority={3}>Action</th>
                              
                            </tr>
                          </thead>
                          <tbody>
                            {RouteListData && RouteListData.map((data,index)=>{
                              return (
                                <tr key={index}>
                                <td>{index+1}</td>
                                <th >{data.route_name.toUpperCase()}</th>
                                <th  className="text-capitalize">{data.route_company_from_name}</th>
                                <th  className="text-capitalize">{data.route_from_name}</th>
                                <th  className="text-capitalize">{data.route_company_to_name}</th>
                                <th  className="text-capitalize">{data.route_to_name}</th>
                                <th>{data.route_distance}</th>
                                <th><i className='mdi mdi-currency-inr'></i>{data.route_fair}</th>
                                <th  className="text-capitalize">{data.route_discription}</th>
                                <td><span className="badge bg-danger" type="button" data-bs-toggle="modal"
                                 data-bs-target="#Mdl_AddRoute_RB" onClick={(e)=>{btnEditRoute(data)}}> Edit</span></td>
                              </tr>
                            
                              )
                            })}
                           
                          </tbody>
                        </table>
                      </div>
                      {/* end .table-responsive */}
                    </div>
                    {/* end .table-rep-plugin*/}
                  </div>
                  {/* end .responsive-table-plugin*/}
                </div>
              </div>
              {/* end card */}
            </div>
            {/* end col */}
          </div>

          {/* end page title */}
        </div>
        {/* container */}
      </div>
      <AddRoute_RB RouteUpdate={RouteUpdate} setRouteUpdate={setRouteUpdate} RouteEditStatus={RouteEditStatus} RouteEditData={RouteEditData} Time={Date().toLocaleString()}/>
      </div>
    </div>
  )
}
