import React, { useEffect, useState } from 'react'
import { padLeadingZeros } from '../../../Include/Function/padLeadingZeros';
import { AddTripEntry_RB } from './AddTripEntry_RB'
export const AddTripEntry = () => {
  const [TripEntryListData, setTripEntryListData] = useState();
  const [TripEntryEditStatus, setTripEntryEditStatus] = useState(0);
  const [TripEntryEditData, setTripEntryEditData] = useState();
  const [TripEntryUpdate, setTripEntryUpdate] = useState(false)

useEffect(() => {
 GetTripEntryList();
}, [TripEntryUpdate])

const GetTripEntryList = async ()=>{
 
 fetch(`${window.endpoint}tripentry/list`, {
   method: 'POST',
   headers: {
     'Content-Type': 'application/json',
   }
 })
 .then((response) => response.json())
 .then((data) => {
   console.log('Success:', data.status);
   if(data.status===0 || data.status==="0"){
     // MachineryUpdate?setMachineryUpdate(false):setMachineryUpdate(true)
  
       setTripEntryListData(data.data);
       console.table(data.data);
       
   }
  
 })
 .catch((error) => {
   console.error('Error:', error);
  
 });
}
const btnEditTripEntry = (data)=>{
 setTripEntryEditStatus(1);
 setTripEntryEditData(data)
}
const btnAddTripEntry = () =>{
 setTripEntryEditStatus(0);
}
  return (
    <div>
<div className='content-page'>
      <div className="content">
        {/* Start Content*/}
        <div className="container-fluid">
          {/* start page title */}
          <div className="row">
            <div className="col-12">
              <div className="page-title-box">
                <h4 className="page-title">TripEntry List</h4>
                <div>
                  <ol className="breadcrumb m-0">
                    <li className="breadcrumb-item">
                      <a href="javascript: void(0);">{window.projectName}</a>
                    </li>
                    <li className="breadcrumb-item">
                      <a href="javascript: void(0);">Master</a>
                    </li>
                    <li className="breadcrumb-item active">TripEntry</li>
                  </ol>
                </div>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-12">
              <div className="card">
                <div className="card-body">
                  <div className="row">
                  <div className="col-lg-10 col-md-7 col-sm-6">
                      <div className="row">
                        <div className="col-sm-3 mt-1">
                        <form className="text-lg-end">
                          <input
                            type="search"
                            className="form-control"
                            id="inputPassword2"
                            placeholder="Search..."
                          />
                        </form>
                        </div>
                        <div className="col-sm-5 mt-1">
                        <button
                          type="button"
                          className="btn btn-danger waves-effect waves-light mb-sm-0"
                        >
                          <i className="mdi mdi-download" /> Download Pdf
                        </button>
                        <button
                          type="button"
                          className="btn btn-success waves-effect waves-light mb-sm-0 ms-1"
                        >
                          <i className="mdi mdi-download" /> Download1 Xlsx
                        </button>
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-2 col-md-5 col-sm-6 mt-1">
                     
                      <button
                        type="button"
                        className="btn btn-primary waves-effect waves-light mb-sm-0 mb-2 me-2 w-100 "
                        data-bs-toggle="modal"
                        data-bs-target="#Mdl_AddTripEntry_RB"
                        onClick={btnAddTripEntry}
                      >
                        <i className="mdi mdi-plus-circle" /> TripEntry
                      </button>
                    </div>
                  </div>

                </div>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-12">
              <div className="card">
                <div className="card-body">
                  <div className="responsive-table-plugin">
                    <div className="table-rep-plugin">
                      <div className="table-responsive" data-pattern="priority-columns">
                      <table
                          id="tech-companies-1"
                          className="table table-striped pb-5 table-full-width-td"
                        >
                          <thead>
                            <tr>
                            <th>Action</th>
                              <th>Sr</th>
                              <th>Trip Id</th>
                                <th>Date</th>
                                <th>Machinery Type</th>
                                <th>Machinery</th>
                                <th>RC No.</th>
                                <th>Route</th>
                                <th>Company</th>
                                <th>From</th>
                                <th>Company</th>
                                <th>To</th>
                                <th>Distance</th>
                                <th>Item</th>
                                <th>Dispatch Weight</th>
                                <th>Recieved Weight</th>
                                <th>Driver</th>
                                <th>Challan No</th>
                                <th>Voucher No</th>
                                <th>Diesel(In Ltr)</th>
                                <th>Urea(In Ltr)</th>
                                <th>Incentive</th>
                                <th>Deal With</th>
                                <th>Route Fair</th>
                                <th>Description</th>
                              
                              
                            </tr>
                          </thead>
                          <tbody>
                            {TripEntryListData && TripEntryListData.map((data,index)=>{
                              return (
                                <tr key={index}>
                                   <td>
                                   <div className="dropdown float-start">
                                      <a
                                        href="#"
                                        className="dropdown-toggle arrow-none card-drop"
                                        data-bs-toggle="dropdown"
                                        aria-expanded="false"
                                      >
                                        <i className="mdi mdi-dots-vertical" />
                                      </a>
                                      <div className="dropdown-menu dropdown-menu-start">
                                      
                                       
                                       
                                        <span type="button" data-bs-toggle="modal"
                                        data-bs-target="#Mdl_AddTripEntry_RB" onClick={(e)=>{btnEditTripEntry(data)}} className="dropdown-item text-danger">
                                        <i className="mdi mdi-book-edit-outline me-1"></i>Edit
                                        </span>

                                        <a href="javascript:void(0);" className="dropdown-item text-danger">
                                        <i className="mdi mdi-eye-off-outline me-1"></i>Deactivate
                                        </a>

                                        <a href="javascript:void(0);" className="dropdown-item text-danger">
                                        <i className="mdi mdi-delete-outline me-1"></i> Delete
                                        </a>
                                      </div>
                                    </div></td>
                                <td>{index+1}</td>
                                <td><span className='badge bg-primary'>{padLeadingZeros(data.tripentry_id,4)}</span></td>
                                <td>{data.entry_date_convert}</td>
                                
                                <td>{data.machinery_type==="1"?'TRUCK':data.machinery_type==="2"?'HYWA':data.machinery_type==="3"?'EQUIPMENT':'-'}</td>
                                <td>{data.vehicle_machinery_id_name}</td>
                                <td>{data.machinery_rc_no}</td>
                                <td>{data.route_id_name}</td>
                                <td>{data.company_from_id_name}</td>
                                <td>{data.city_from_id_name}</td>
                                <td>{data.company_to_id_name}</td>
                                <td>{data.city_to_id_name}</td>
                                <td>{data.route_distance}</td>
                                <td>{data.product_id_name}</td>
                                <td>{data.product_dispatch_weight}</td>
                                <td>{data.product_recieved_weight}</td>
                                <td>{data.driver_employee_id_name}</td>
                                <td>{data.challan_no}</td>
                                <td>{data.voucher_no}</td>
                                <td>{data.diesel_in_ton}</td>
                                <td>{data.urea_in_ton}</td>
                                <td>{data.driver_employee_incentive}</td>
                                <td>{data.company_deal_with_id_name}</td>
                                <td>{data.route_fair}</td>
                                <td>{data.other_description}</td>
                               
                              </tr>
                            
                              )
                            })}
                           
                          </tbody>
                        </table>
                      </div>
                      {/* end .table-responsive */}
                    </div>
                    {/* end .table-rep-plugin*/}
                  </div>
                  {/* end .responsive-table-plugin*/}
                </div>
              </div>
              {/* end card */}
            </div>
            {/* end col */}
          </div>

          {/* end page title */}
        </div>
        {/* container */}
      </div>
      <AddTripEntry_RB TripEntryUpdate={TripEntryUpdate} setTripEntryUpdate={setTripEntryUpdate} TripEntryEditStatus={TripEntryEditStatus} TripEntryEditData={TripEntryEditData} Time={Date().toLocaleString()}/>
      </div>
    </div>
  )
}
