
import React, { useContext, useEffect, useRef, useState } from 'react'
import LanguageContext from '../../Include/Context/LanguageContext';
export const AddUser_RB = ({UserEditStatus,UserEditData,Time,UserUpdate,setUserUpdate}) => {
  let closeAddUser_RB = useRef();
  const {setSnackBarData} = useContext(LanguageContext) ;

  const [BtnUserLoader, setBtnUserLoader] = useState(false);
  const [RoleListOption, setRoleListOption] = useState();
  const [UserAccess, setUserAccess] = useState([]);
  const [UserAccessDivision, setUserAccessDivision] = useState(false)
  const [AddUserFormData, setAddUserFormData] = useState({
      txt_adduser_id:0,
      txt_adduser_name:'',
      txt_adduser_email:'',
      txt_adduser_contactcountry:'+91',
      txt_adduser_contactnumber:'',
      txt_adduser_password:'',
      txt_adduser_confirmpassword:'',
      txt_adduser_roletype:'',
      txt_adduser_status:0,
      txt_adduser_access:''
  });
  const AddUserFormDataClear =()=>{
    setAddUserFormData({
      txt_adduser_id:0,
      txt_adduser_name:'',
      txt_adduser_email:'',
      txt_adduser_contactcountry:'+91',
      txt_adduser_contactnumber:'',
      txt_adduser_password:'',
      txt_adduser_confirmpassword:'',
      txt_adduser_roletype:'',
      txt_adduser_status:0,
      txt_adduser_access:''
    });
    setUserAccess([]);
  }
  const AddUserFormDataUpdate =(data)=>{
    setAddUserFormData({
      txt_adduser_id:data.userId,
      txt_adduser_name:data.userName,
      txt_adduser_email:data.userEmail,
      txt_adduser_contactcountry:data.userCountry,
      txt_adduser_contactnumber:data.userContactNo,
      txt_adduser_password:data.userPassword,
      txt_adduser_confirmpassword:data.userPasswordConfirm,
      txt_adduser_roletype:data.userRoleType,
      txt_adduser_status:data.status,
      txt_adduser_access:data.data,
  });
  setUserAccess(data.data);
  }
  useEffect(() => {
    console.log('--checking status - add or edit');
      if(UserEditStatus===1){
        console.log('-- edit');
       console.log(UserEditData)
       AddUserFormDataUpdate(UserEditData)
      }else{
        console.log('-- Add');
        AddUserFormDataClear();
      }
  }, [Time]);
  const handleChange = (e) => {
    setAddUserFormData({ ...AddUserFormData, [e.target.name]: e.target.value });
  }
  const handleSubmit = (e) => {
    e.preventDefault();
    console.log(AddUserFormData);
    setBtnUserLoader(true)
    fetch(`${window.endpoint}user/add`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        txt_adduser_id:AddUserFormData.txt_adduser_id,
        txt_adduser_name:AddUserFormData.txt_adduser_name,
        txt_adduser_email:AddUserFormData.txt_adduser_email,
        txt_adduser_contactcountry:AddUserFormData.txt_adduser_contactcountry,
        txt_adduser_contactnumber:AddUserFormData.txt_adduser_contactnumber,
        txt_adduser_password:AddUserFormData.txt_adduser_password,
        txt_adduser_confirmpassword:AddUserFormData.txt_adduser_confirmpassword,
        txt_adduser_roletype:AddUserFormData.txt_adduser_roletype,
        txt_adduser_status:AddUserFormData.txt_adduser_status,
        txt_adduser_access:UserAccess,
    }),
    })
    .then((response) => response.json())
    .then((data) => {
      console.log('Success:', data.data.status);
      if(data.data.status===0 || data.data.status==="0"){
        UserUpdate?setUserUpdate(false):setUserUpdate(true)
        closeAddUser_RB.current.click();
      setSnackBarData({
        alert: "success",
        message:data.data.message,
        open: true
      })
       
   
        // closeAddMachiner_RB.current.click();
        // setSnackBarData({
        //   alert: "success",
        //   message:data.data.message,
        //   open: true
        // })
      }
      setBtnUserLoader(false)
    })
    .catch((error) => {
      console.error('Error:', error);
      setBtnUserLoader(false)
    });
  }
  useEffect(() => {
    GetRoleListOption()
  }, [])
  
  const GetRoleListOption = async ()=>{
   
    const response = await fetch( `${window.endpoint}role/list`,{
     method:"GET",
     headers:{
      "Content-Type":"application/json",
     }
    });
    let result =await response.json();
    // setRoleAccess(RoleData[0].data);
    console.log('from API rolie list--',result.data)
    if(result.status===0 || result.status==="0"){
    setRoleListOption(result.data);
    }
   }
   const handleRoleType = (e)=>{
    setAddUserFormData({ ...AddUserFormData, [e.target.name]: e.target.value });
    
    const SelRoleType = RoleListOption.find(data => data.roleId === e.target.value)
    console.log(SelRoleType)
    setUserAccess(SelRoleType.data);
    AddUserFormData.txt_adduser_access=SelRoleType.data;
    
   }
   const checkRoleAccess = (e, from) => {
    var tempRoleData = UserAccess;
    let tempData_Role = tempRoleData.map((data) => {
      if (data.id === e.target.value) {
        if (from === "MAIN") {
          let action = data.moduleAction === 0 || data.moduleAction === "0" ? "1" : "0";
          return {
            ...data,
            moduleAction: action,
            addAction: 1,
            deleteAction: 1,
            updateAction: 1,
            viewAction: 1,
            downloadAction: 1,
          };
        }
        if (from === "ADD") {
          let action = e.target.checked?'0':'1';
          // let action = data.addAction === 0 || data.addAction === "0" ? "1" : "0";
          return { ...data, addAction: action };
        }
        if (from === "VIEW") {
          let action = e.target.checked?'0':'1';

          return { ...data, viewAction: action };
        }
        if (from === "EDIT") {
          let action = e.target.checked?'0':'1';

          return { ...data, updateAction: action };
        }
        if (from === "DELETE") {
          let action = e.target.checked?'0':'1';

          return { ...data, deleteAction: action };
        }
        if (from === "DOWNLOAD") {
          let action = e.target.checked?'0':'1';

          return { ...data, downloadAction: action };
        }
      } else {
        return data;
      }
    });
    setUserAccess(tempData_Role);
  };
  const handleEditAndShowAccess = ()=>{
     if(UserAccessDivision){
      setUserAccessDivision(false);
     }else{
     setUserAccessDivision(true)
     }
  }
  return (
    <>
      {/* Right modal content */}
      <div
        id="Mdl_AddUser_RB"
        className="modal fade"
        tabIndex={-1}
        role="dialog"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-sm modal-right" style={{ maxWidth: "500px", width: "100%" }}>
          <div className="modal-content h-100">
            <h6 className="fw-semibold px-3 m-0 py-2 font-16 bg-light">
              <span className="d-block py-2">Add User</span>
            </h6>
            <div className="modal-header border-0 p-0">
              {/* <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              /> */}
            </div>
            <div className="modal-body">
              <form onSubmit={handleSubmit}>
                <div className='col-sm-12'>
                  <div className='row'>
                    <div className='col-sm-12 mb-2'>
                      <label htmlFor="txt_adduser_name">User</label>
                      <input type="text" id="txt_adduser_name" name="txt_adduser_name" value={AddUserFormData.txt_adduser_name} className="form-control" placeholder='Enter Your Name'  onChange={handleChange}  required></input>
                    </div>
                    <div className='col-sm-12 mb-2'>
                      <label htmlFor="txt_adduser_email" >Email</label>
                      <input type="email" className="form-control"  id="txt_adduser_email" name="txt_adduser_email" value={AddUserFormData.txt_adduser_email}  placeholder='Enter Your Email' onChange={handleChange}  required ></input>
                    </div>
                    <div className='col-sm-12 mb-2'>
                      <label htmlFor="txt_adduser_contactnumber"  >Contact no.</label>
                      <div className='input-group'>

                        {/* <div className='col-3'> */}
                        <select className='form-select' name='txt_adduser_contactcountry' id='txt_adduser_contactcountry' value={AddUserFormData.txt_adduser_contactcountry} onChange={handleChange} required>

                          <option value="0" >Country</option>
                          <option value="+91" selected>+91</option>
                        </select>
                        {/* </div> */}
                        {/* <div className='col-9'> */}
                        <input type="number"  className="form-control w-50" id="txt_adduser_contactnumber" name="txt_adduser_contactnumber" value={AddUserFormData.txt_adduser_contactnumber} placeholder='Enter Your Contact No.' maxLength={10} onChange={handleChange}  required  ></input>
                        {/* </div> */}
                      </div>
                    </div>
                    <div className='col-sm-12 mb-2'>
                      <label htmlFor="txt_adduser_password"  >Password </label>
                      <input type="password"  className="form-control"  id="txt_adduser_password" name="txt_adduser_password" value={AddUserFormData.txt_adduser_password} placeholder='Enter Your Password' onChange={handleChange}  required ></input>
                    </div>
                    <div className='col-sm-12 mb-2'>
                      <label htmlFor="txt_adduser_confirmpassword"  > Confirm Password </label>
                      <input type="password" className="form-control" id="txt_adduser_confirmpassword" name="txt_adduser_confirmpassword"  value={AddUserFormData.txt_adduser_confirmpassword}   placeholder='Enter Your Comfirm Password'   pattern={AddUserFormData.txt_adduser_password} onChange={handleChange}  required
                      ></input>
                    </div>
                    <div className='col-sm-12 mb-2'>
                      <label htmlFor="txt_adduser_roletype" >Role Type</label>
                      <select type="text" className="form-control"  id="txt_adduser_roletype" name="txt_adduser_roletype" placeholder='Enter Your Role.' value={AddUserFormData.txt_adduser_roletype} onChange={handleRoleType}  required >
                        <option value="">Select Role</option>
                        {RoleListOption && RoleListOption.map((data,index)=>{
                              return (
                                <option key={index} value={data.roleId}>{data.roleName.toUpperCase()} </option>
                             )
                            })}
                      </select>
                      <div className='row'>
                      <div className='col-sm-12'>
                      <span className={`badge ${UserAccessDivision?'bg-danger':' bg-primary'} float-end mt-1`} type="button" onClick={handleEditAndShowAccess}> {UserAccessDivision?'Hide & Update Access':' Show & Edit Access'}</span>
                      </div>
                      
                      </div>
                      
                    </div>
                    {UserAccessDivision && <>
                   <div className='col-sm-12 mb-2'> 
                   <hr className='m-1 '></hr>
              <label className="form-label">Role Access Setup</label>
              {UserAccess &&
                UserAccess.map((data, index) => (
                  <div key={index}>
                    <div className="form-check">
                      <input
                        className="form-check-input"
                        type="checkbox"
                        id={`txt_${data.id}`}
                        checked={
                          data.moduleAction === 0 || data.moduleAction === "0"
                            ? true
                            : false
                        }
                        value={data.id}
                        onChange={(e) => {
                          checkRoleAccess(e, "MAIN");
                        }}
                      />
                      <label
                        className="form-check-label"
                        htmlFor={`txt_${data.id}`}
                      >
                        {data.moduleName.toUpperCase()}
                      </label>
                    </div>
                    {(data.moduleAction === 0 || data.moduleAction === "0") && (
                      <div className="">
                        <div className="row">
                          <div className="col-sm-2 col-2"></div>
                          <div className="col-sm-10 col-10">
                            <div className="form-check">
                              <input
                                className="form-check-input"
                                type="checkbox"
                                value={data.id}
                                id={`txt_Add_${data.id}`}
                                checked={
                                  data.addAction === 0 || data.addAction === "0"
                                    ? true
                                    : false
                                }
                                onChange={(e) => {
                                  checkRoleAccess(e, "ADD");
                                }}
                              />
                              <label
                                className="form-check-label"
                                htmlFor={`txt_Add_${data.id}`}
                              >
                                Add
                              </label>
                            </div>
                          </div>
                          <div className="col-sm-2 col-2"></div>
                          <div className="col-sm-10 col-10">
                            <div className="form-check">
                              <input
                                className="form-check-input"
                                type="checkbox"
                                checked={
                                  data.viewAction === 0 || data.viewAction === "0"
                                    ? true
                                    : false
                                }
                                value={data.id}
                                id={`txt_View_${data.id}`}
                                onChange={(e) => {
                                  checkRoleAccess(e, "VIEW");
                                }}
                              />
                              <label
                                className="form-check-label"
                                htmlFor={`txt_View_${data.id}`}
                              >
                                View
                              </label>
                            </div>
                          </div>
                          <div className="col-sm-2 col-2"></div>
                          <div className="col-sm-10 col-10">
                            <div className="form-check">
                              <input
                                className="form-check-input"
                                type="checkbox"
                                checked={
                                  data.updateAction === 0 ||
                                  data.updateAction === "0"
                                    ? true
                                    : false
                                }
                                id={`txt_Edit_${data.id}`}
                                value={data.id}
                                onChange={(e) => {
                                  checkRoleAccess(e, "EDIT");
                                }}
                              />
                              <label
                                className="form-check-label"
                                htmlFor={`txt_Edit_${data.id}`}
                              >
                                Edit
                              </label>
                            </div>
                          </div>
                          <div className="col-sm-2 col-2"></div>
                          <div className="col-sm-10 col-10">
                            <div className="form-check">
                              <input
                                className="form-check-input"
                                type="checkbox"
                                checked={
                                  data.deleteAction === 0 ||
                                  data.deleteAction === "0"
                                    ? true
                                    : false
                                }
                                id={`txt_Delete_${data.id}`}
                                value={data.id}
                                onChange={(e) => {
                                  checkRoleAccess(e, "DELETE");
                                }}
                              />
                              <label
                                className="form-check-label"
                                htmlFor={`txt_Delete_${data.id}`}
                              >
                                Delete
                              </label>
                            </div>
                          </div>
                          <div className="col-sm-2 col-2"></div>
                          <div className="col-sm-10 col-10">
                            <div className="form-check">
                              <input
                                className="form-check-input"
                                type="checkbox"
                                checked={
                                  data.downloadAction === 0 ||
                                  data.downloadAction === "0"
                                    ? true
                                    : false
                                }
                                id={`txt_Download_${data.id}`}
                                value={data.id}
                                onChange={(e) => {
                                  checkRoleAccess(e, "DOWNLOAD");
                                }}
                              />
                              <label
                                className="form-check-label"
                                htmlFor={`txt_Download_${data.id}`}
                              >
                                Download
                              </label>
                            </div>
                          </div>
                        </div>
                      </div>
                    )}
                    {/* <hr></hr> */}
                  </div>
                ))}
                </div>
                </>}
                  </div>
                </div>

                <div className="modal-footer mdlFooter">
                  <button type="button" className="btn btn-secondary" data-bs-dismiss="modal" ref={closeAddUser_RB}>Close</button>
                  {!BtnUserLoader && <button type="submit" className="btn btn-primary btnLoader">Submit</button>}
                {BtnUserLoader && <>
                <button class="btn btn-primary btnLoader" type="button" disabled="">
                                                        <span class="spinner-border spinner-border-sm me-1" role="status" aria-hidden="true"></span>
                                                        Loading...
                                                    </button>
              </>}
                  </div>
              </form>
            </div>
          </div>
          {/* /.modal-content */}
        </div>
        {/* /.modal-dialog */}
      </div>
      {/* /.modal */}
    </>
  )
}

