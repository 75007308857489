import React, { useContext, useEffect, useState } from "react";
import { AddRole_RB } from "./Rightbar/AddRole_RB";

export const AddRole = () => {
   const [RoleListData, setRoleListData] = useState();
   const [RoleEditStatus, setRoleEditStatus] = useState(0);
   const [RoleEditData, setRoleEditData] = useState();
   const [RoleUpdate, setRoleUpdate] = useState(false)

 useEffect(() => {
  // GetRoleList();
  GetAttendenceList();
 }, [RoleUpdate])
 
 const GetRoleList = async ()=>{
  
  const response = await fetch(`${window.endpoint}role/list`,{
   method:"GET",
   headers:{
    "Content-Type":"application/json",
   }
  });
  let result =await response.json();
  // setRoleAccess(RoleData[0].data);
  console.log('from API rolie list--',result.data)
  if(result.status===0 || result.status==="0"){
  setRoleListData(result.data);
  }
 }
 const btnEditRole = (data)=>{
  setRoleEditStatus(1);
  setRoleEditData(data)
 }
 const btnAddRole = () =>{
  setRoleEditStatus(0);
 }

 const GetAttendenceList = async ()=>{
  
  const response = await fetch(`http://localhost/fromMsaccess/Api_AttendanceLogs.php`,{
   method:"GET",
   headers:{
    "Content-Type":"application/json",
   }
  });
  let result =await response.json();
  // setRoleAccess(RoleData[0].data);
  console.log('from API rolie list--',result.data)
  
 }
  return (
    <div> <div className='content-page'>
      <div className="content">
        <div className="container-fluid">
          <div className="row">
            <div className="col-12">
              <div className="page-title-box">
                <h4 className="page-title">Role List</h4>
                <div>
                  <ol className="breadcrumb m-0">
                    <li className="breadcrumb-item">
                      <a href="javascript: void(0);">{window.projectName}</a>
                    </li>
                    <li className="breadcrumb-item">
                      <a href="javascript: void(0);">User Management</a>
                    </li>
                    <li className="breadcrumb-item active">Role</li>
                  </ol>
                </div>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-12">
              <div className="card">
                <div className="card-body">
                  <div className="row">
                  <div className="col-lg-10 col-md-7 col-sm-6">
                      <div className="row">
                        <div className="col-sm-3 mt-1">
                        <form className="text-lg-end">
                          <input
                            type="search"
                            className="form-control"
                            id="inputPassword2"
                            placeholder="Search..."
                          />
                        </form>
                        </div>
                        <div className="col-sm-5 mt-1">
                        <button
                          type="button"
                          className="btn btn-danger waves-effect waves-light mb-sm-0"
                        >
                          <i className="mdi mdi-download" /> Download Pdf
                        </button>
                        <button
                          type="button"
                          className="btn btn-success waves-effect waves-light mb-sm-0 ms-1"
                        >
                          <i className="mdi mdi-download" /> Download1 Xlsx
                        </button>
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-2 col-md-5 col-sm-6 mt-1">
                      <button
                        type="button"
                        className="btn btn-primary waves-effect waves-light mb-sm-0 mb-2 me-2 w-100 "
                        data-bs-toggle="modal"
                        data-bs-target="#Mdl_AddRole_RB"
                        onClick={btnAddRole}
                      >
                        <i className="mdi mdi-plus" /> Add Role
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-12">
              <div className="card">
                <div className="card-body">
                  <div className="responsive-table-plugin">
                    <div className="table-rep-plugin">
                      <div
                        className="table-responsive"
                        data-pattern="priority-columns"
                      >
                        <table
                          id="tech-companies-1"
                          className="table table-striped"
                        >
                          <thead>
                            <tr>
                              <th>Sr</th>
                              <th data-priority={1}>Role Name</th>
                              <th data-priority={3}>Action</th>
                              
                            </tr>
                          </thead>
                          <tbody>
                            {RoleListData && RoleListData.map((data,index)=>{
                              return (
                                <tr key={index}>
                                  <td>{index+1}</td>
                                <th>{data.roleName.toUpperCase()}</th>
                                <td><span className="badge bg-danger" type="button" data-bs-toggle="modal"
                        data-bs-target="#Mdl_AddRole_RB" onClick={(e)=>{btnEditRole(data)}}> Edit</span></td>
                              </tr>
                            
                              )
                            })}
                           
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <AddRole_RB RoleUpdate={RoleUpdate} setRoleUpdate={setRoleUpdate} RoleEditStatus={RoleEditStatus} RoleEditData={RoleEditData} Time={Date().toLocaleString()}/>
      </div>
      </div>
    </div>
  );
};
