import React, { useEffect, useState } from 'react'
import { AddUser_RB } from './Rightbar/AddUser_RB'
export const AddUser = () => {
  const [UserListData, setUserListData] = useState();
  const [UserEditData, setUserEditData] = useState();
  const [UserEditStatus, setUserEditStatus] = useState(0);
  const [UserUpdate, setUserUpdate] = useState(false)

  useEffect(() => {
    GetRoleList();
   }, [UserUpdate])
  const GetRoleList = async ()=>{
  
    const response = await fetch(`${window.endpoint}user/list`,{
     method:"GET",
     headers:{
      "Content-Type":"application/json",
     }
    });
    let result =await response.json();
    // setRoleAccess(RoleData[0].data);
    console.log('from API User list--',result.data)
    if(result.status===0 || result.status==="0"){
    setUserListData(result.data);

    }
   }
   const btnEditUser = (data)=>{
    setUserEditStatus(1);
    console.log('add user from - ',data);
    setUserEditData(data)
   }
   const btnAddUser = () =>{
    setUserEditStatus(0);

   }
  return (
    <div>
 <div className='content-page'>
      <div className="content">
        {/* Start Content*/}
        <div className="container-fluid">
          {/* start page title */}
          <div className="row">
            <div className="col-12">
              <div className="page-title-box">
                <h4 className="page-title">User List</h4>
                <div>
                  <ol className="breadcrumb m-0">
                    <li className="breadcrumb-item">
                      <a href="javascript: void(0);">{window.projectName}</a>
                    </li>
                    <li className="breadcrumb-item">
                      <a href="javascript: void(0);">User Management</a>
                    </li>
                    <li className="breadcrumb-item active">User</li>
                  </ol>
                </div>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-12">
              <div className="card">
                <div className="card-body">
                  <div className="row">
                  <div className="col-lg-10 col-md-7 col-sm-6">
                      <div className="row">
                        <div className="col-sm-3 mt-1">
                        <form className="text-lg-end">
                          <input
                            type="search"
                            className="form-control"
                            id="inputPassword2"
                            placeholder="Search..."
                          />
                        </form>
                        </div>
                        <div className="col-sm-5 mt-1">
                        <button
                          type="button"
                          className="btn btn-danger waves-effect waves-light mb-sm-0"
                        >
                          <i className="mdi mdi-download" /> Download Pdf
                        </button>
                        <button
                          type="button"
                          className="btn btn-success waves-effect waves-light mb-sm-0 ms-1"
                        >
                          <i className="mdi mdi-download" /> Download1 Xlsx
                        </button>
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-2 col-md-5 col-sm-6 mt-1">
                     
                      <button
                        type="button"
                        className="btn btn-primary waves-effect waves-light mb-sm-0 mb-2 me-2 w-100 "
                        data-bs-toggle="modal"
                        data-bs-target="#Mdl_AddUser_RB"
                        onClick={btnAddUser}
                      >
                        <i className="mdi mdi-plus" /> Add User
                      </button>
                    </div>
                  </div>

                </div>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-12">
              <div className="card">
                <div className="card-body">
                  <div className="responsive-table-plugin">
                    <div className="table-rep-plugin">
                      <div className="table-responsive" data-pattern="priority-columns">
                      <table
                          id="tech-companies-1"
                          className="table table-striped"
                        >
                          <thead>
                            <tr>
                              <th>Sr</th>
                              <th data-priority={1}>Name</th>
                              <th data-priority={1}>Email</th>
                              <th data-priority={1}>Contact No.</th>
                              <th data-priority={1}>Role Type</th>
                             
                              <th data-priority={3}>Action</th>
                              
                            </tr>
                          </thead>
                          <tbody>
                            {UserListData && UserListData.map((data,index)=>{
                              return (
                                <tr key={index}>
                                  <td>{index+1}</td>
                                <th>{data.userName.toUpperCase()}</th>
                                
                                <th>{data.userEmail}</th>
                                <th>{data.userContactNo}</th>
                                <th>{data.userRoleTypeName}</th>
                                <td><span className="badge bg-danger" type="button" data-bs-toggle="modal"
                                data-bs-target="#Mdl_AddUser_RB" onClick={(e)=>{btnEditUser(data)}}> Edit</span></td>
                              </tr>
                            
                              )
                            })}
                           
                          </tbody>
                        </table>
                      </div>{" "}
                      {/* end .table-responsive */}
                    </div>{" "}
                    {/* end .table-rep-plugin*/}
                  </div>{" "}
                  {/* end .responsive-table-plugin*/}
                </div>
              </div>{" "}
              {/* end card */}
            </div>{" "}
            {/* end col */}
          </div>

          {/* end page title */}
        </div>{" "}
        {/* container */}
      </div>
      

      <AddUser_RB UserUpdate={UserUpdate} setUserUpdate={setUserUpdate}  UserEditStatus={UserEditStatus} UserEditData={UserEditData} Time={Date().toLocaleString()}/>
   </div>
    </div>
  )
}
