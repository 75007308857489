import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'

export const LogIn = () =>{
const [LoginForm, setLoginForm] = useState({
    txt_login_email:"",
    txt_login_password:""
})
const handleChange=(e)=>{
    setLoginForm({...LoginForm, [e.target.name]:e.target.value})
}
const handleSubmit=(e)=>{
    e.preventDefault();
    console.log(LoginForm);
}

useEffect(() => {
    // document.querySelector(".button-menu-mobile").onclick = function() {
        const element = document.querySelector(".customeBody");
        // if(element.getAttribute("data-sidebar-size")==="default"){
          element.setAttribute("data-sidebar-size", "default");
        // }else{
        //   element.setAttribute("data-sidebar-size", "default");
        // }
    //   } 

 
}, [])

    return (
        <div>
            <div className="account-pages mt-5 mb-5">
                <div className="container">
                    <div className="row justify-content-center">
                        <div className="col-md-8 col-lg-6 col-xl-4">
                            <div className="card shadow-none">
                                <div className="card-body p-3">
                                    <div className="text-center w-100 m-auto bg-black">
                                        <div className="auth-logo pt-2 pb-2">
                                            <a href="javascript:void()" className="logo logo-dark text-center">
                                                {/* <span className="logo-lg"> */}
                                                    {/* <img src={`${process.env.PUBLIC_URL}/assets/kk.jpeg`} alt="" height={250} /> */}
                                                {/* </span> */}
                                            </a>
                                            {/* <a href="index.html" className="logo logo-light text-center">
                                                <span>
                                                <span className="logo-lg">
                                                    <img src="assets/kk.jpeg" alt="" height={250} />
                                                </span>
                                                </span>
                                            </a> */}
                                        </div>
                                     
                                    </div>
                                    <form onSubmit={handleSubmit}>
                                    <p className="text-muted mb-4 mt-3 text-center">
                                            Enter your email address and password to access admin panel.
                                        </p>
                                        <div className="mb-3">
                                            <label htmlFor="txt_login_email" className="form-label">
                                                Email address
                                            </label>
                                            <input
                                                className="form-control"
                                                type="email"
                                                name='txt_login_email'
                                                id="txt_login_email"
                                                required=""
                                                onChange={handleChange}
                                                placeholder="Enter your email"
                                            />
                                        </div>
                                        <div className="mb-3">
                                            <label htmlFor="txt_login_password" className="form-label">
                                                Password
                                            </label>
                                            <div className="input-group input-group-merge">
                                                <input
                                                    type="password"
                                                    id="txt_login_password"
                                                    name="txt_login_password"
                                                    className="form-control"
                                                    onChange={handleChange}
                                                    placeholder="Enter your password"
                                                />
                                                <div className="input-group-text" data-password="false">
                                                    <span className="password-eye" />
                                                </div>
                                            </div>
                                        </div>
                                        <div className="mb-3">
                                            <div className="form-check">
                                                <input
                                                    type="checkbox"
                                                    className="form-check-input"
                                                    id="checkbox-signin"
                                                    defaultChecked=""
                                                />
                                                <label className="form-check-label" htmlFor="checkbox-signin">
                                                    Remember me
                                                </label>
                                            </div>
                                        </div>
                                        <div className="text-center d-grid">
                                            <Link to={`${process.env.PUBLIC_URL}/dashboard`}>
                                            <button className="btn btn-primary" type="submit">

                                                Log In
                                            </button>
                                            </Link>
                                        </div>
                                    </form>
                                    <div className="text-center">
                                        <h5 className="mt-3 text-muted">Forgot password?</h5>
                                    </div>
                                </div>
                            </div>
                           

                        </div>

                    </div>

                </div>

            </div>

        </div>
    )
}
