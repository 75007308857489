import React, { useContext, useEffect, useRef, useState } from 'react'
import Select from 'react-select'
import LanguageContext from '../../../Include/Context/LanguageContext';

export const AddCompany_RB = ({CompanyUpdate,setCompanyUpdate,CompanyEditStatus,CompanyEditData,Time}) => {
  let closeAddCompany_RB = useRef();
  const [BtnCompanyLoader, setBtnCompanyLoader] = useState(false);
  const {setSnackBarData} = useContext(LanguageContext) ;
  const [CityOptionData, setCityOptionData] = useState([])

  const [AddCompanyFormData, setAddCompanyFormData] = useState({
      txt_addcompany_id:'0',
      txt_addcompany_name:"",
      txt_addcompany_ownername:"",
      txt_addcompany_contactno:"",
      txt_addcompany_contactno_alt:"",
      txt_addcompany_companycity:"",
      txt_addcompany_address:"",
      txt_addcompany_gstno:"",
      txt_addcompany_status:'0'
  });
  const AddCompanyFormDataClear = ()=>{
    setAddCompanyFormData({
      txt_addcompany_id:'0',
      txt_addcompany_name:"",
      txt_addcompany_ownername:"",
      txt_addcompany_contactno:"",
      txt_addcompany_contactno_alt:"",
      txt_addcompany_companycity:"",
      txt_addcompany_address:"",
      txt_addcompany_gstno:"",
      txt_addcompany_status:'0'
    })
  }
  const AddCompanyFormDataUpdate = (data)=>{
    setAddCompanyFormData({
      txt_addcompany_id:data.company_id,
      txt_addcompany_name:data.company_name,
      txt_addcompany_ownername:data.company_owner_name,
      txt_addcompany_contactno:data.company_contact_no,
      txt_addcompany_contactno_alt:data.company_contact_no_alt,
      txt_addcompany_companycity:data.company_city,
      txt_addcompany_address:data.company_address,
      txt_addcompany_gstno:data.company_gstno,
      txt_addcompany_status:data.status
    })
  }
  useEffect(() => {
    // return () => {
      console.log('--------------')
      if(CompanyEditStatus===0){
        AddCompanyFormDataClear();
      }
      if(CompanyEditStatus===1){
       console.log(CompanyEditData)
       AddCompanyFormDataUpdate(CompanyEditData)
      }
  }, [Time]);
  const InputChange = (e)=>{
    setAddCompanyFormData({...AddCompanyFormData,[e.target.name]:e.target.value})
    }
  const frmAddCompany=(e)=>{
    e.preventDefault();
    setBtnCompanyLoader(true)
    
    fetch(`${window.endpoint}company/add`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(AddCompanyFormData),
    })
    .then((response) => response.json())
    .then((data) => {
      console.log('Success:', data.data.status);
      if(data.data.status===0 || data.data.status==="0"){
        CompanyUpdate?setCompanyUpdate(false):setCompanyUpdate(true)
        closeAddCompany_RB.current.click();
        setSnackBarData({
          alert: "success",
          message:data.data.message,
          open: true
        })
      }
      setBtnCompanyLoader(false)
    })
    .catch((error) => {
      console.error('Error:', error);
      setBtnCompanyLoader(false)
    });
    
  }
  useEffect(() => {
    GetCompanyList();
   }, [])
   
   const GetCompanyList = async ()=>{
    
    fetch(`${window.endpoint}city/list`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      }
    })
    .then((response) => response.json())
    .then((data) => {
      // console.log('Success:', data.status);
      if(data.status===0 || data.status==="0"){
        // console.log('------',data.data);
         var i=1;
         let CityOptionData =[{
            label: 'Select City',
            value: ''
           }];
          data.data.map((CityData)=>{
            let cityOption = {
               label: CityData.city_name,
               value: CityData.city_id
              }
            CityOptionData.push(cityOption)
            console.log(i)
            i++;
          })
          console.log(CityOptionData);
          setCityOptionData(CityOptionData)
      }
     
    })
    .catch((error) => {
      console.error('Error:', error);
     
    });
   }
   const handleCompanyCity=(e)=>{
    setAddCompanyFormData({...AddCompanyFormData,txt_addcompany_companycity:e.value})
  }
    return (
        <>
          {/* Right modal content */}
          <div
            id="Mdl_AddCompany_RB"
            className="modal fade"
            tabIndex={-1}
            role="dialog"
            aria-hidden="true"
          >
            <div
              className="modal-dialog modal-md modal-right"
              style={{ maxWidth: "500px", width: "100%" }}
            >
              <div className="modal-content  h-100">
                <h6 className="fw-semibold px-3 m-0 py-2 font-16 bg-light">
                  <span className="d-block py-2">ADD COMPANY</span>
                </h6>
                <div className="modal-header p-0 border-0">
                  <button
                    type="button"
                    className="btn-close"
                    data-bs-dismiss="modal"
                    aria-label="Close"
                  />
                </div>
                <form id="frm_AddCompany" onSubmit={(e)=>{frmAddCompany(e)}}>
                  <div className="modal-body">
                    <div className='col-sm-12'>
                      <div className='row'>
                        <div className='col-sm-12'>
                        <label>Company Name</label>
                    <input
                      type="text"
                      name="txt_addcompany_name"
                      id="txt_addcompany_name"
                      value={AddCompanyFormData.txt_addcompany_name}
                      className="form-control mb-2"
                      placeholder="Enter Company Name"
                      onChange={InputChange} 
                      required
                    />
                        </div>
                        <div className='col-sm-12'>
                        <label>Owner Name</label>
                    <input
                      type="text"
                      name="txt_addcompany_ownername"
                      id="txt_addcompany_ownername"
                      value={AddCompanyFormData.txt_addcompany_ownername}
                      className="form-control mb-2"
                      placeholder="Enter Company Name"
                      onChange={InputChange} 
                      required
                    />
                        </div>
                        <div className='col-sm-12'>
                        <label>Contact No.</label>
                    <input
                      type="text"
                      name="txt_addcompany_contactno"
                      id="txt_addcompany_contactno"
                      value={AddCompanyFormData.txt_addcompany_contactno}
                      className="form-control mb-2"
                      placeholder="Enter Contact No."
                      onChange={InputChange} 
                      required
                    />
                        </div>
                        <div className='col-sm-12'>
                        <label>Alt Contact No.</label>
                    <input
                      type="text"
                      name="txt_addcompany_contactno_alt"
                      id="txt_addcompany_contactno_alt"
                      value={AddCompanyFormData.txt_addcompany_contactno_alt}
                      className="form-control mb-2"
                      placeholder="Enter Contact No. Alternate"
                      onChange={InputChange} 
                      
                    />
                        </div>
                        <div className='col-sm-12 mb-2'>
                        <label>City</label>
                   
                      <Select options={CityOptionData} className="text-capitalize"  name="txt_addcompany_companycity"
                        id="txt_addcompany_companycity"   placeholder="Select City"   onChange={(e) => {
                          handleCompanyCity(e); 
                        }}
                        value = {
                          CityOptionData &&  CityOptionData.filter(option => 
                              option.value === AddCompanyFormData.txt_addcompany_companycity)
                          }
                          required/>
                        </div>
                        <div className='col-sm-12'>
                        <label>Address</label>
                    <textarea
                      type="text"
                      name="txt_addcompany_address"
                      id="txt_addcompany_address"
                      value={AddCompanyFormData.txt_addcompany_address}
                      className="form-control mb-2"
                      placeholder="Enter Address"
                      onChange={InputChange} 
                      required
                      rows={3}
                    />
                        </div>

                        <div className='col-sm-12'>
                        <label>GST No.</label>
                    <input
                      type="text"
                      name="txt_addcompany_gstno"
                      id="txt_addcompany_gstno"
                      value={AddCompanyFormData.txt_addcompany_gstno}
                      className="form-control mb-2"
                      placeholder="Enter GST. No"
                      onChange={InputChange} 
                      
                    />
                        </div>
                      </div>
                    </div>
                
                
                    
                  
    
                    <div className="modal-footer mdlFooter">
                      <button
                        type="button"
                        className="btn btn-secondary"
                        data-bs-dismiss="modal"
                        ref={closeAddCompany_RB}
                      >
                        Close
                      </button>
                      {!BtnCompanyLoader && <><button type="submit" className="btn btn-primary btnLoader" >
                Submit
              </button>
              </>
              }
              {BtnCompanyLoader && <>
                <button className="btn btn-primary btnLoader" type="button" disabled="">
                                                        <span className="spinner-border spinner-border-sm me-1" role="status" aria-hidden="true"></span>
                                                        Loading...
                                                    </button>
              </>}
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </>
      );
}
