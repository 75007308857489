import React from 'react'

export const AddMachinery_Details = () => {
    const loop = [1,2,3,4,5,6,7,8,9]

  return (
    <div>
      <div className='content-page'>
    <div className="content">
      <div className="container-fluid">
    <div className="row">
            <div className="col-12">
              <div className="page-title-box">
                <h4 className="page-title">Machinery Detail</h4>
                <div>
                  <ol className="breadcrumb m-0">
                    <li className="breadcrumb-item">
                      <a href="javascript: void(0);">{window.projectName}</a>
                    </li>
                    <li className="breadcrumb-item">
                      <a href="javascript: void(0);">Master</a>
                    </li>
                    <li className="breadcrumb-item active">Machinery/Detail</li>
                  </ol>
                </div>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-12">
              <div className="card">
                <div className="card-body">
                  <div className="row">
                    <div className="col-lg-10 col-md-7 col-sm-6">
                      <div className="row">
                        <div className="col-sm-3">
                        <form className="text-lg-end">
                          <input
                            type="search"
                            className="form-control"
                            id="inputPassword2"
                            placeholder="Search..."
                          />
                        </form>
                        </div>
                        <div className="col-sm-5">
                      
                        
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-2 col-md-5 col-sm-6">
                    
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
<div className="col-xl-12">
  <div className="card">
    <div className="card-body">
      {/* <h4 className="header-title mb-4">Tabs Bordered Justified</h4> */}
      <ul className="nav nav-pills navtab-bg nav-justified">
        <li className="nav-item ">
          <a
            href="#MachineryDetail"
            data-bs-toggle="tab"
            aria-expanded="false"
            className="nav-link active "
          >
            Detail
          </a>
        </li>
        <li className="nav-item">
          <a
            href="#EngineOilDetails"
            data-bs-toggle="tab"
            aria-expanded="true"
            className="nav-link"
          >
            Engine Oil
          </a>
        </li>
        <li className="nav-item">
          <a
            href="#FitnessDetails"
            data-bs-toggle="tab"
            aria-expanded="true"
            className="nav-link"
          >
            Fitness
          </a>
        </li>
        <li className="nav-item">
          <a
            href="#PUCDetails"
            data-bs-toggle="tab"
            aria-expanded="false"
            className="nav-link"
          >
            PUC
          </a>
        </li>
        <li className="nav-item">
          <a
            href="#RoadTaxDetails"
            data-bs-toggle="tab"
            aria-expanded="false"
            className="nav-link"
          >
            Road Tax
          </a>
        </li>
        <li className="nav-item">
          <a
            href="#PermitDetails"
            data-bs-toggle="tab"
            aria-expanded="false"
            className="nav-link"
          >
            Permit
          </a>
        </li>
        <li className="nav-item">
          <a
            href="#InsuranceDetails"
            data-bs-toggle="tab"
            aria-expanded="false"
            className="nav-link"
          >
            Insurance
          </a>
        </li>
      </ul>
      <div className="tab-content">
        <div className="tab-pane active" id="MachineryDetail">
        <div className="row ms-1 me-1">
              <div className="col-sm-12">
                <div className="row">
                  <fieldset className="border  pb-2">
                    <legend className="float-none w-auto legendFontsize text-primary">
                      Owner Details:
                    </legend>
                    <div className="col-sm-12">
                      <div className="row">
                        <div className="col-sm-12">
                          <label>Name:</label>
                          <div class="input-group ">
                            <input type="text" className="form-control"></input>
                          </div>
                        </div>
                        <div className="col-sm-12">
                          <label>Contact No.:</label>
                          <input type="text" className="form-control"></input>
                        </div>
                        <div className="col-sm-12">
                          <label>Address:</label>
                          <input type="text" className="form-control"></input>
                        </div>
                      </div>
                    </div>
                  </fieldset>
                </div>
              </div>
              <div className="col-sm-12">
                <div className="row">
                  <fieldset className="border pb-2">
                    <legend className="float-none w-auto legendFontsize text-primary">
                      Machinery Details:
                    </legend>
                    <div className="col-sm-12">
                      <div className="row">
                      <div className="col-sm-12">
                          <label>Brand Name</label>
                          <input type="text" className="form-control"></input>
                        </div>
                        <div className="col-sm-12">
                          <label>Type </label>
                          <select type="text" className="form-select" name="txt_machinery_detail_type" id="txt_machinery_detail_type"  >
                             <option value="0">Select</option>
                             <option value="1">Truck</option>
                             <option value="2">Hywa</option>
                             <option value="3">Equipment</option>
                          </select>
                        </div>
                     
                        <div className="col-sm-12">
                          <label>Machinery No.</label>
                          <input type="text" className="form-control"></input>
                        </div>
                       
                        <div className="col-sm-12">
                          <label>Model/Year</label>
                          <div class="input-group ">
                            <input
                              type="text"
                              className="form-control"
                              placeholder="Model"
                            ></input>
                            <input
                              type="text"
                              className="form-control"
                              placeholder="Year"
                            ></input>
                          </div>
                        </div>
                      
                        <div className="col-sm-12">
                          <label>Capacity</label>
                          <input type="text" className="form-control"></input>
                        </div>
                        <div className="col-sm-12">
                          <label>Description</label>
                          <textarea rows="3" type="text" className="form-control"></textarea>
                        </div>
                      </div>
                    </div>
                  </fieldset>
                  
                  
                 
                 
                 
                 
                 
                 
                
                  
                  <div className="col-sm-12">
                    <label>Installment</label>
                    <input type="text" className="form-control"></input>
                  </div>
                  <div className="col-sm-12">
                    <label>Installment</label>
                    <input type="text" className="form-control"></input>
                  </div>
                  <div className="col-sm-12">
                    <label>Engine Oil Used</label>
                    <input type="text" className="form-control"></input>
                  </div>
                  <div className="col-sm-12">
                    <label>Financer</label>
                    <input type="text" className="form-control"></input>
                  </div>
                  <div className="col-sm-12">
                    <label>Installment</label>
                    <input type="text" className="form-control"></input>
                  </div>
                </div>
              </div>
            </div>
        </div>
        <div className="tab-pane " id="EngineOilDetails">
        <div className="row">
            <div className="col-12">
             
                  <div className="responsive-table-plugin">
                    <div className="table-rep-plugin ">
                      <div
                        className="table-responsive mb-5"
                        data-pattern="priority-columns "
                      >
                        <table
                          id="tech-companies-1"
                          className="table table-striped pb-5"
                        >
                          <thead>
                            <tr>
                           
                              <th>Oil Brand</th>
                              <th data-priority={1}>Change Date</th>
                              <th data-priority={3}>Kilometers</th>
                              <th data-priority={1}>Description</th>
                          
                              
                            </tr>
                          </thead>
                          <tbody>
                            {loop.map((data)=>{
                              return(
<tr>

                              <th>
                               Castrol
                              </th>
                              <td>12/03/2022</td>
                              <td>10,212</td>
                              <td>-</td>
                              
                            </tr>
                              )
                            })}
                            
                          
                           
                           
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
               
            </div>
          </div>
        </div>
        <div className="tab-pane" id="FitnessDetails">
        <div className="row">
            <div className="col-12">
             
                  <div className="responsive-table-plugin">
                    <div className="table-rep-plugin ">
                      <div
                        className="table-responsive mb-5"
                        data-pattern="priority-columns "
                      >
                        <table
                          id="tech-companies-1"
                          className="table table-striped pb-5"
                        >
                          <thead>
                            <tr>
                           
                              <th>Valid From</th>
                              <th data-priority={1}>Valid To</th>
                              <th data-priority={3}>File</th>
                              <th data-priority={1}>Description</th>
                             
                            </tr>
                          </thead>
                          <tbody>
                            {loop.map((data)=>{
                              return(
<tr>

<th>
                               11/08/2021
                              </th>
                              <td> 10/08/2022</td>
                              <td>View Image</td>
                              <td>-</td>
                            </tr>
                              )
                            })}
                            
                          
                           
                           
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                </div>
             
          </div>
        </div>
        <div className="tab-pane" id="PUCDetails">
        <div className="row">
            <div className="col-12">
             
                  <div className="responsive-table-plugin">
                    <div className="table-rep-plugin ">
                      <div
                        className="table-responsive mb-5"
                        data-pattern="priority-columns "
                      >
                        <table
                          id="tech-companies-1"
                          className="table table-striped pb-5"
                        >
                          <thead>
                            <tr>
                           
                            <th>Valid From</th>
                              <th data-priority={1}>Valid To</th>
                              <th data-priority={3}>File</th>
                              <th data-priority={1}>Description</th>
                              
                            </tr>
                          </thead>
                          <tbody>
                            {loop.map((data)=>{
                              return(
<tr>

<th>
                               15/08/2021
                              </th>
                              <td> 14/08/2022</td>
                              <td>View Image</td>
                              <td>-</td>
                             
                              
                            </tr>
                              )
                            })}
                            
                          
                           
                           
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
              
            </div>
          </div>
        </div>
        <div className="tab-pane" id="RoadTaxDetails">
        <div className="row">
            <div className="col-12">
           
                  <div className="responsive-table-plugin">
                    <div className="table-rep-plugin ">
                      <div
                        className="table-responsive mb-5"
                        data-pattern="priority-columns "
                      >
                        <table
                          id="tech-companies-1"
                          className="table table-striped pb-5"
                        >
                          <thead>
                            <tr>
                           
                            <th>Valid From</th>
                              <th data-priority={1}>Valid To</th>
                              <th data-priority={3}>File</th>
                              <th data-priority={1}>Description</th>
                              
                            </tr>
                          </thead>
                          <tbody>
                            {loop.map((data)=>{
                              return(
<tr>

<th>
                               12/08/2021
                              </th>
                              <td> 12/08/2022</td>
                              <td>View Image</td>
                              <td>-</td>
                             
                            </tr>
                              )
                            })}
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="tab-pane" id="PermitDetails">
        <div className="row">
            <div className="col-12">
           
                  <div className="responsive-table-plugin">
                    <div className="table-rep-plugin ">
                      <div
                        className="table-responsive mb-5"
                        data-pattern="priority-columns "
                      >
                        <table
                          id="tech-companies-1"
                          className="table table-striped pb-5"
                        >
                          <thead>
                            <tr>
                            
                            <th>Valid From</th>
                              <th data-priority={1}>Valid To</th>
                              <th data-priority={3}>File</th>
                              <th data-priority={1}>Description</th>
                              
                            </tr>
                          </thead>
                          <tbody>
                            {loop.map((data)=>{
                              return(
<tr>

                              <th>
                               12/07/2021
                              </th>
                              <td> 12/07/2022</td>
                              <td>View Image</td>
                              <td>-</td>
                              
                              
                            </tr>
                              )
                            })}
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="tab-pane" id="InsuranceDetails">
        <div className="row">
            <div className="col-12">
           
                  <div className="responsive-table-plugin">
                    <div className="table-rep-plugin ">
                      <div
                        className="table-responsive mb-5"
                        data-pattern="priority-columns "
                      >
                        <table
                          id="tech-companies-1"
                          className="table table-striped pb-5"
                        >
                          <thead>
                            <tr>
                            
                            <th>Valid From</th>
                              <th data-priority={1}>Valid To</th>
                              <th data-priority={3}>File</th>
                              <th data-priority={1}>Description</th>
                              
                            </tr>
                          </thead>
                          <tbody>
                            {loop.map((data)=>{
                              return(
<tr>

                              <th>
                               12/07/2021
                              </th>
                              <td> 12/07/2022</td>
                              <td>View Image</td>
                              <td>-</td>
                              
                              
                            </tr>
                              )
                            })}
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
      </div>
    </div>
  </div>

</div>
    </div>
    </div></div></div>
  )
}
