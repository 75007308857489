import React from 'react'

export const AddAverageBankBalanceData = () => {
  const LoanTypeInfoDataDays = [1,2,3,4,5,6,7,8,9,10,11,12,13,14,15,16,17,18,19,20,21,22,23,24,25,26,27,29,30,31];
  return (
    <>
    
   
      <button type="button" className="btn btn-success" data-tip="Click For Details"  data-bs-toggle="modal" data-bs-target="#loan-amount-details">Set ABB</button>
       <div id="loan-amount-details" className="modal fade" tabIndex="-1" role="dialog" aria-hidden="true">
        <div className="modal-dialog" style={{maxWidth:"80%",margin:"auto"}}>
          <div className="modal-content">
          <h6 className="fw-semibold px-3 m-0 py-2 font-16 bg-light">
              <span className="d-block py-2">Average Bank Balance</span>
            </h6>
          <div className="modal-header p-0 border-0">
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              />
            </div>
            <div className="modal-body p-2" style={{overflow:'overlay'}}>
              
              {/* <input type="file" onChange={(e)=>handleUpload(e)} ></input> */}
              {/* <button onClick={onDownload}> Export excel </button> */}
             <table className="table table-sm table-bordered">
  <thead>
    <tr>
    <th>Sr</th>
   {LoanTypeInfoDataDays.map((data,index)=>{
    <th>{data}</th>
   })}
   
    </tr>
  </thead>

  <tbody>
 
    

  </tbody>
             </table>
             
            </div>
            <div className="modal-footer mdlFooter">
                  <button
                    type="button"
                    className="btn btn-secondary"
                    data-bs-dismiss="modal"
                  
                  >
                    Close
                  </button>
                    
                      <button type="button" class="btn btn-primary btnLoader"  data-bs-dismiss="modal">
                        Submit/Save
                      </button>
                    
                  
                  
                </div>
          </div>
        </div>
      </div>
    
    </>
  )
}
