import './App.css';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom'
import { Navbar } from './Include/Navbar';
import { Footer } from './Include/Footer';
import { LeftSidebar } from './Include/LeftSidebar';
import { AddUser } from './General/AddUser';
import { AddRole } from './General/AddRole';
import { Dashboard } from './General/Dashboard';
import { AddEmployee } from './General/AddEmployee';
import { AddMachinery } from './General/Rightbar/Machinery/AddMachinery';
// import SnackBarState from './Include/Context/SnackBarState';
// import { createContext, useState } from 'react';
import LanguageContext from './Include/Context/LanguageContext';
import { useEffect, useState } from 'react';
import CustomizedSnackbars from './Include/CustomizedSnackbars';
import { AddMachinery_Details } from './General/Rightbar/Machinery/AddMachinery_Details';
import { LogIn } from './Auth/LogIn';
import { AddProduct } from './General/Rightbar/Product/AddProduct';
import { AddCity } from './General/Rightbar/City/AddCity';
import { AddRoute } from './General/Rightbar/Route/AddRoute';
import { AddTripEntry } from './General/Rightbar/Transaction/AddTripEntry';
import { AddCompany } from './General/Rightbar/Company/AddCompany';
import { Login2 } from './Auth/Login2';
import {  AddEquipmentEntry } from './General/Rightbar/Transaction/AddEquipmentEntry';
import { Logout } from './Auth/Logout';
import { AddAverageBankBalanceData } from './AverageBankBalanceData';

function App() {
  window.name="Ketan";
  const [SnackBarData, setSnackBarData] = useState({
    open:false,
    message:"",
    alert:""
  });
  const [ProUserName, setProUserName] = useState('Admin')
  const SnackBarStatus =  { SnackBarData,setSnackBarData,ProUserName,setProUserName};
    console.log(window.location.pathname);
  return (
    <>
    <LanguageContext.Provider  value={SnackBarStatus}>
    <Router>      
    <div id="wrapper">
      
     
        <Routes  basename={window.location.pathname}>
        <Route path={`${process.env.PUBLIC_URL}/login2`} element={<Login2/>}></Route>  
        <Route path={`${process.env.PUBLIC_URL}`} element={<LogIn/>}></Route>  
        <Route path={`${process.env.PUBLIC_URL}/login`} element={<LogIn/>}></Route>
        <Route path={`${process.env.PUBLIC_URL}/logout`} element={<Logout/>}></Route>
        <Route path={`${process.env.PUBLIC_URL}/roles`} element={<><Navbar/><LeftSidebar/><AddRole/></>}></Route>
        <Route path={`${process.env.PUBLIC_URL}/users`} element={<><Navbar/><LeftSidebar/><AddUser/></>}></Route>
        <Route path={`${process.env.PUBLIC_URL}/dashboard`} element={<><Navbar/><LeftSidebar/><Dashboard/></>}></Route>
        <Route path={`${process.env.PUBLIC_URL}/employee`} element={<><Navbar/><LeftSidebar/><AddEmployee/></>}></Route>
        <Route path={`${process.env.PUBLIC_URL}/machinery`} element={<><Navbar/><LeftSidebar/><AddMachinery/></>}></Route>
        <Route path={`${process.env.PUBLIC_URL}/machinery/details`} element={<><Navbar/><LeftSidebar/><AddMachinery_Details/></>}></Route>
        <Route path={`${process.env.PUBLIC_URL}/product`} element={<><Navbar/><LeftSidebar/><AddProduct/></>}></Route>
        <Route path={`${process.env.PUBLIC_URL}/city`} element={<><Navbar/><LeftSidebar/><AddCity/></>}></Route>
        <Route path={`${process.env.PUBLIC_URL}/route`} element={<><Navbar/><LeftSidebar/><AddRoute/></>}></Route>
        <Route path={`${process.env.PUBLIC_URL}/company`} element={<><Navbar/><LeftSidebar/><AddCompany/></>}></Route>
        <Route path={`${process.env.PUBLIC_URL}/trips-entry`} element={<><Navbar/><LeftSidebar/><AddTripEntry/></>}></Route>
        <Route path={`${process.env.PUBLIC_URL}/equipment-entry`} element={<><Navbar/><LeftSidebar/><AddEquipmentEntry/></>}></Route>
        <Route path={`${process.env.PUBLIC_URL}/add-abb`} element={<><AddAverageBankBalanceData/></>}></Route>
        {/* <Route path="/" element={<AddTransport/>}></Route> */}
        </Routes>
      {/* <Footer/> */}
      <CustomizedSnackbars SnackBarStatus={SnackBarData}/>
        </div>
     
    </Router>
    </LanguageContext.Provider>
    </>
  );
}

export default App;
