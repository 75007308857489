import React, { useContext, useEffect, useRef, useState } from 'react'
import Select from 'react-select'

import LanguageContext from '../../../Include/Context/LanguageContext';
export const AddRoute_RB = ({RouteUpdate,setRouteUpdate,RouteEditStatus,RouteEditData,Time}) => {
  let closeAddRoute_RB = useRef();
  const [BtnRouteLoader, setBtnRouteLoader] = useState(false);
  const {setSnackBarData} = useContext(LanguageContext) ;
  const [RouteCityOptionData, setRouteCityOptionData] = useState([]);
  const [RouteCompanyListData, setRouteCompanyListData] = useState()
 
  const [AddRouteFormData, setAddRouteFormData] = useState({
    txt_addroute_id:'0',
    txt_addroute_name:"",
    txt_addroute_company_from:"",
    txt_addroute_city_from:"",
    txt_addroute_city_from_id:"",
    txt_addroute_company_to:"",
    txt_addroute_city_to:"",
    txt_addroute_city_to_id:"",
    txt_addroute_distance:"",
    txt_addroute_fair:"",
    txt_addroute_description:"",
    txt_addroute_status:'0'
  });
  const AddRouteFormDataClear = ()=>{
    setAddRouteFormData({
    txt_addroute_id:'0',
    txt_addroute_name:"",
    txt_addroute_company_from:"",
    txt_addroute_city_from:"",
    txt_addroute_city_from_id:"",
    txt_addroute_company_to:"",
    txt_addroute_city_to:"",
    txt_addroute_city_to_id:"",
    txt_addroute_distance:"",
    txt_addroute_fair:"",
    txt_addroute_description:"",
    txt_addroute_status:'0'
    })
  }
  const AddRouteFormDataUpdate = (data)=>{
    console.log(data.route_company_to);
    setAddRouteFormData({
      txt_addroute_id:data.route_id,
      txt_addroute_name:data.route_name,
      txt_addroute_company_from:data.route_company_from,
      txt_addroute_city_from:data.route_from_name,
      txt_addroute_city_from_id:data.route_from,
      txt_addroute_company_to:data.route_company_to,
      txt_addroute_city_to:data.route_to_name,
      txt_addroute_city_to_id:data.route_to,
      txt_addroute_distance:data.route_distance,
      txt_addroute_fair:data.route_fair,
      txt_addroute_description:data.route_discription,
      txt_addroute_status:data.status
    })
  }
  useEffect(() => {
    // return () => {
      if(RouteEditStatus===0){
        AddRouteFormDataClear();
   
      }
      if(RouteEditStatus===1){
       console.log(RouteEditData)
       AddRouteFormDataUpdate(RouteEditData)
      
      }

  }, [Time]);
  const InputChange = (e)=>{ 
    setAddRouteFormData({...AddRouteFormData,[e.target.name]:e.target.value})
    }
    const handleRouteCompanyFrom=(e)=>{
      console.log(e);
      setAddRouteFormData({...AddRouteFormData,txt_addroute_company_from:e.value,txt_addroute_city_from:e.cityName,txt_addroute_city_from_id:e.cityId})
    }
    const handleRouteCompanyTo=(e)=>{
      setAddRouteFormData({...AddRouteFormData,txt_addroute_company_to:e.value,txt_addroute_city_to:e.cityName,txt_addroute_city_to_id:e.cityId})
     
    }
    useEffect(() => {
      GetRouteCompanyList();
     }, [])
     
     const GetRouteCompanyList = async ()=>{
      
      fetch(`${window.endpoint}company/list`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        }
      })
      .then((response) => response.json())
      .then((data) => {
        // console.log('Success:', data.status);
        if(data.status===0 || data.status==="0"){
          if(data.status===0 || data.status==="0"){
                  // console.log('------',data.data);
                  
                   let RouteCompanyOptionData = [{
                    label: "Select Company",
                    value: "",
                    cityName: "",
                    cityId: "0"
                   }];
                    data.data.forEach((CompanyData)=>{
                      let companyOption = {
                         label: CompanyData.company_name+'-'+CompanyData.company_city_name,
                         value: CompanyData.company_id,
                         cityName: CompanyData.company_city_name,
                         cityId: CompanyData.company_city
                        }
                        RouteCompanyOptionData.push(companyOption)
                     
                    })
                   
                    setRouteCompanyListData(RouteCompanyOptionData)
                }
        
        }
       
      })
      .catch((error) => {
        console.error('Error:', error);
       
      });
     }
    //  const GetRouteCityList = async ()=>{
      
    //   fetch(`${window.endpoint}city/list`, {
    //     method: 'POST',
    //     headers: {
    //       'Content-Type': 'application/json',
    //     }
    //   })
    //   .then((response) => response.json())
    //   .then((data) => {
    //     // console.log('Success:', data.status);
    //     if(data.status===0 || data.status==="0"){
    //       // console.log('------',data.data);
    //        var i=1;
    //        let CityOptionData = [];
    //         data.data.map((CityData)=>{
    //           let cityOption = {
    //              label: CityData.city_name,
    //              value: CityData.city_id
    //             }
    //           CityOptionData.push(cityOption)
    //           console.log(i)
    //           i++;
    //         })
    //         // console.log(CityOptionData);
    //         setRouteCityOptionData(CityOptionData)
    //     }
       
    //   })
    //   .catch((error) => {
    //     console.error('Error:', error);
       
    //   });
    //  }
  const frmAddRoute=(e)=>{
    e.preventDefault();
    setBtnRouteLoader(true)
    
    fetch(`${window.endpoint}route/add`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(AddRouteFormData),
    })
    .then((response) => response.json())
    .then((data) => {
      console.log('Success:', data.data.status);
      if(data.data.status===0 || data.data.status==="0"){
        RouteUpdate?setRouteUpdate(false):setRouteUpdate(true)
        closeAddRoute_RB.current.click();
        setSnackBarData({
          alert: "success",
          message:data.data.message,
          open: true
        })
      }
      setBtnRouteLoader(false)
    })
    .catch((error) => {
      console.error('Error:', error);
      setBtnRouteLoader(false)
    });
    
  }
 

    return (
        <>
          {/* Right modal content */}
          <div
            id="Mdl_AddRoute_RB"
            className="modal fade"
            tabIndex={-1}
            role="dialog"
            aria-hidden="true"
          >
            <div
              className="modal-dialog modal-md modal-right"
              style={{ maxWidth: "500px", width: "100%" }}
            >
              <div className="modal-content  h-100">
                <h6 className="fw-semibold px-3 m-0 py-2 font-16 bg-light">
                  <span className="d-block py-2">ADD ROUTE</span>
                </h6>
                <div className="modal-header p-0 border-0">
                  <button
                    type="button"
                    className="btn-close"
                    data-bs-dismiss="modal"
                    aria-label="Close"
                  />
                </div>
               
                  <div className="modal-body">
                  <form id="frm_AddRoute" onSubmit={(e)=>{frmAddRoute(e)}}>
                    <div className='col-sm-12'>
                      <div className='row'>
                        <div className='col-sm-12 mb-2'>
                        <label>Route Name</label>
                    <input
                      type="text"
                      name="txt_addroute_name"
                      id="txt_addroute_name"
                      value={AddRouteFormData.txt_addroute_name}
                      className="form-control "
                      placeholder="Enter Route Name"
                      onChange={InputChange} 
                      required
                    />
                        </div>
                        <div className='col-sm-12 mb-2'>
                          <div className='row'>
                          <div className='col-sm-8  col-12'>
                        <label>Company From</label>
                        <Select options={RouteCompanyListData} className="text-capitalize"  name="txt_addroute_company_from"
                        id="txt_addroute_company_from"   placeholder="Select Route From"   onChange={(e) => {
                        handleRouteCompanyFrom(e);
                        }}
                        value = {
                          RouteCompanyListData && RouteCompanyListData.filter(option => 
                              option.value === AddRouteFormData.txt_addroute_company_from)
                          }
                          />
                   
                        </div>
                        <div className='col-sm-4 col-12 '>
                        <label>Route</label>
                        <input
                      type="text"
                      name="txt_addroute_city_from"
                      id="txt_addroute_city_from"
                      value={AddRouteFormData.txt_addroute_city_from}
                      className="form-control "
                      required
                      readOnly
                    />
                        </div>
                          </div>
                        </div>

                        <div className='col-sm-12 mb-2'>
                          <div className='row'>
                          <div className='col-sm-8  col-12'>
                        <label>Company To</label>
                        <Select options={RouteCompanyListData} className="text-capitalize"  name="txt_addroute_company_to"
                        id="txt_addroute_company_to"   placeholder="Select Route From"   onChange={(e) => {
                        handleRouteCompanyTo(e);
                        }}
                        value = {
                          RouteCompanyListData && RouteCompanyListData.filter(option => 
                              option.value === AddRouteFormData.txt_addroute_company_to)
                          }
                          />
                        </div>
                        <div className='col-sm-4 col-12 '>
                        <label>Route To</label>
                       
                        <input
                      type="text"
                      name="txt_addroute_city_to"
                      id="txt_addroute_city_to"
                      value={AddRouteFormData.txt_addroute_city_to}
                      className="form-control "
                      required
                      readOnly
                       />
                        </div>
                          </div>
                        </div>
                        
                        <div className='col-sm-12 mb-2'>
                          <div className='row'>
                          <div className='col-sm-6 col-xs-6 col-6'>
                        <label>Route Distance</label>
                    <input
                      type="tel"
                      name="txt_addroute_distance"
                      id="txt_addroute_distance"
                      value={AddRouteFormData.txt_addroute_distance}
                      className="form-control"
                      placeholder="Enter Route Distance"
                      onChange={InputChange} 
                      required
                    >
                    </input>
                        </div>
                        <div className='col-sm-6 col-xs-6 col-6'>
                        <label>Route Fair</label>
                    <input
                      type="tel"
                      name="txt_addroute_fair"
                      id="txt_addroute_fair"
                      value={AddRouteFormData.txt_addroute_fair}
                      className="form-control "
                      placeholder="Enter Route Fair"
                      onChange={InputChange} 
                      required
                    />
                        </div>

                          </div>
                        </div>
                        <div className='col-sm-12'>
                          <label>Description</label>
                          <textarea
                      type="text"
                      name="txt_addroute_description"
                      id="txt_addroute_description"
                      value={AddRouteFormData.txt_addroute_description}
                      className="form-control "
                      onChange={InputChange} 
                      required
                      rows={3}
                    ></textarea>
                        </div>
                      </div>
                    </div>
                   
                
                    
                  
    
                    <div className="modal-footer mdlFooter">
                      <button
                        type="button"
                        className="btn btn-secondary"
                        data-bs-dismiss="modal"
                        ref={closeAddRoute_RB}
                      >
                        Close
                      </button>
                      {!BtnRouteLoader && <><button type="submit" className="btn btn-primary btnLoader" >
                Submit
              </button>
              </>
              }
              {BtnRouteLoader && <>
                <button className="btn btn-primary btnLoader" type="button" disabled="">
                                                        <span className="spinner-border spinner-border-sm me-1" role="status" aria-hidden="true"></span>
                                                        Loading...
                                                    </button>
              </>}
                    </div>
                    </form>
                  </div>
                
              </div>
            </div>
          </div>
        </>
      );
}
