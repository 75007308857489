import React, { useContext, useEffect, useRef, useState } from "react";
import Select from "react-select";

import LanguageContext from "../../../Include/Context/LanguageContext";
import { padLeadingZeros } from "../../../Include/Function/padLeadingZeros";
export const AddEquipmentEntry_RB = ({
  EquipmentEntryUpdate,
  setEquipmentEntryUpdate,
  EquipmentEntryEditStatus,
  EquipmentEntryEditData,
  Time,
}) => {
 
  let closeAddEquipmentEntry_RB = useRef();
  const [BtnEquipmentEntryLoader, setBtnEquipmentEntryLoader] = useState(false);
  const { setSnackBarData } = useContext(LanguageContext);
  const [EquipmentEntryRouteListData, setEquipmentEntryRouteListData] = useState([]);
  const [CurrentTime, setCurrentTime] = useState('2022-08-20T01:23')
  const [EndDate, setEndDate] = useState('2022-08-24T19:56')
  const [AddEquipmentEntryFormData, setAddEquipmentEntryFormData] = useState({
    txt_addequipmententry_id: "0",
    txt_addequipmententry_date: "",
    txt_addequipmententry_company:"",
    txt_addequipmententry_machinerytype:"3",
    txt_addequipmententry_shift:"0",
    txt_addequipmententry_starttime:"",   
    txt_addequipmententry_endtime:"",
    txt_addequipmententry_workfor:"0",
    txt_addequipmententry_workforcompanyStatus1:0,
    txt_addequipmententry_workforcompany1:"",
    txt_addequipmententry_productofcompany1:"",
    txt_addequipmententry_rackloadingbox1:"",
    txt_addequipmententry_rackloadingboxQty1:"",
    txt_addequipmententry_truckloadingbox1:"",
    txt_addequipmententry_truckloadingboxQty1:"",
    txt_addequipmententry_dozing1:"",
    txt_addequipmententry_workforcompanyStatus2:0,
    txt_addequipmententry_workforcompany2:"",
    txt_addequipmententry_productofcompany2:"",
    txt_addequipmententry_rackloadingbox2:"",
    txt_addequipmententry_rackloadingboxQty2:"",
    txt_addequipmententry_truckloadingbox2:"",
    txt_addequipmententry_truckloadingboxQty2:"",
    txt_addequipmententry_dozing2:"",
    txt_addequipmententry_workforcompanyStatus3:0,
    txt_addequipmententry_workforcompany3:"",
    txt_addequipmententry_productofcompany3:"",
    txt_addequipmententry_rackloadingbox3:"",
    txt_addequipmententry_rackloadingboxQty3:"",
    txt_addequipmententry_truckloadingbox3:"",
    txt_addequipmententry_truckloadingboxQty3:"",
    txt_addequipmententry_dozing3:"",
    txt_addequipmententry_route: "",
    txt_addequipmententry_company_from: "",
    txt_addequipmententry_company_from_id: "",
    txt_addequipmententry_city_from: "",
    txt_addequipmententry_city_from_id: "",
    txt_addequipmententry_company_to: "",
    txt_addequipmententry_company_to_id: "",
    txt_addequipmententry_city_to: "",
    txt_addequipmententry_city_to_id: "",
    txt_addequipmententry_distance: "",
    txt_addequipmententry_fair: "",
    txt_addequipmententry_routedealwith: "",
    txt_addequipmententry_productitem: "",
    txt_addequipmententry_weight_dispatch:"",
    txt_addequipmententry_weight_recieved:"",
    txt_addequipmententry_vehicle:"",
    txt_addequipmententry_driver:"",
    txt_addequipmententry_challan_no:"",
    txt_addequipmententry_voucher_no:"",
    txt_addequipmententry_diesel:"",
    txt_addequipmententry_urea:"",
    txt_addequipmententry_incentive_amount:"",
    txt_addequipmententry_description:"",
    txt_addequipmententry_status: "0",
  });
  const AddEquipmentEntryFormDataClear = () => {
    setAddEquipmentEntryFormData({
      txt_addequipmententry_id: "0",
    txt_addequipmententry_date: "",
    txt_addequipmententry_machinerytype:"3",
    txt_addequipmententry_route: "",
    txt_addequipmententry_company_from: "",
    txt_addequipmententry_company_from_id: "",
    txt_addequipmententry_city_from: "",
    txt_addequipmententry_city_from_id: "",
    txt_addequipmententry_company_to: "",
    txt_addequipmententry_company_to_id: "",
    txt_addequipmententry_city_to: "",
    txt_addequipmententry_city_to_id: "",
    txt_addequipmententry_distance: "",
    txt_addequipmententry_fair: "",
    txt_addequipmententry_routedealwith: "",
    txt_addequipmententry_productitem: "",
    txt_addequipmententry_weight_dispatch:"",
    txt_addequipmententry_weight_recieved:"",
    txt_addequipmententry_vehicle:"",
    txt_addequipmententry_driver:"",
    txt_addequipmententry_challan_no:"",
    txt_addequipmententry_voucher_no:"",
    txt_addequipmententry_diesel:"",
    txt_addequipmententry_urea:"",
    txt_addequipmententry_incentive_amount:"",
    txt_addequipmententry_description:"",
    txt_addequipmententry_status: "0",
    });
  };
  const AddEquipmentEntryFormDataUpdate = (data) => {
    setAddEquipmentEntryFormData({
      txt_addequipmententry_id:data.tripentry_id,
      txt_addequipmententry_date: data.trip_date,
      
      txt_addequipmententry_machinerytype:data.machinery_type,
      txt_addequipmententry_route: data.route_id,
      txt_addequipmententry_company_from: data.company_from_id_name,
      txt_addequipmententry_company_from_id: data.company_from_id,
      txt_addequipmententry_city_from: data.city_from_id_name,
      txt_addequipmententry_city_from_id: data.city_from_id,
      txt_addequipmententry_company_to: data.company_to_id_name,
      txt_addequipmententry_company_to_id: data.company_to_id,
      txt_addequipmententry_city_to:data.city_to_id_name,
      txt_addequipmententry_city_to_id:data.city_to_id,
      txt_addequipmententry_distance: data.route_distance,
      txt_addequipmententry_fair: data.route_fair,
      txt_addequipmententry_routedealwith: data.company_deal_with_id,
      txt_addequipmententry_productitem: data.product_id,
      txt_addequipmententry_weight_dispatch:data.product_dispatch_weight,
      txt_addequipmententry_weight_recieved:data.product_recieved_weight,
      txt_addequipmententry_vehicle:data.vehicle_machinery_id,
      txt_addequipmententry_driver:data.driver_employee_id,
      txt_addequipmententry_challan_no:data.challan_no,
      txt_addequipmententry_voucher_no:data.voucher_no,
      txt_addequipmententry_diesel:data.diesel_in_ton,
      txt_addequipmententry_urea:data.urea_in_ton,
      txt_addequipmententry_incentive_amount:data.driver_employee_incentive,
      txt_addequipmententry_description:data.other_description,
      txt_addequipmententry_status: data.status,
    });
  };
  useEffect(() => {
   
    if (EquipmentEntryEditStatus === 0) {
      AddEquipmentEntryFormDataClear();
    }
    if (EquipmentEntryEditStatus === 1) {
      console.log(EquipmentEntryEditData);
      AddEquipmentEntryFormDataUpdate(EquipmentEntryEditData);
    }
  }, [Time]);
  const InputChange = (e) => {
    console.log(e.target.value)
    setAddEquipmentEntryFormData({
      ...AddEquipmentEntryFormData,
      [e.target.name]: e.target.value,
    });
  };
  const InputChangeWorkFor = (e)=>{
    let TotalWorkFor = e.target.value;
    console.log(TotalWorkFor);
    if(TotalWorkFor===0 ||TotalWorkFor==="0"){
      console.log('set 1')
      setAddEquipmentEntryFormData({...AddEquipmentEntryFormData,
        txt_addequipmententry_workforcompanyStatus1:1,
        txt_addequipmententry_workforcompany1:"",
        txt_addequipmententry_rackloadingbox1:"",
        txt_addequipmententry_rackloadingboxQty1:"",
        txt_addequipmententry_truckloadingbox1:"",
        txt_addequipmententry_truckloadingboxQty1:"",
        txt_addequipmententry_workforcompanyStatus2:1,
        txt_addequipmententry_workforcompany2:"",
        txt_addequipmententry_rackloadingbox2:"",
        txt_addequipmententry_rackloadingboxQty2:"",
        txt_addequipmententry_truckloadingbox2:"",
        txt_addequipmententry_truckloadingboxQty2:"",
        txt_addequipmententry_workforcompanyStatus3:1,
        txt_addequipmententry_workforcompany3:"",
        txt_addequipmententry_rackloadingbox3:"",
        txt_addequipmententry_rackloadingboxQty3:"",
        txt_addequipmententry_truckloadingbox3:"",
        txt_addequipmententry_truckloadingboxQty3:"",
        txt_addequipmententry_workfor:TotalWorkFor
        })
    }
    if(TotalWorkFor===1 ||TotalWorkFor==="1"){
      console.log('set 1')
      setAddEquipmentEntryFormData({...AddEquipmentEntryFormData,
        txt_addequipmententry_workforcompanyStatus1:0,
        txt_addequipmententry_workforcompany1:"",
        txt_addequipmententry_rackloadingbox1:"",
        txt_addequipmententry_rackloadingboxQty1:"",
        txt_addequipmententry_truckloadingbox1:"",
        txt_addequipmententry_truckloadingboxQty1:"",
        txt_addequipmententry_workforcompanyStatus2:1,
        txt_addequipmententry_workforcompany2:"",
        txt_addequipmententry_rackloadingbox2:"",
        txt_addequipmententry_rackloadingboxQty2:"",
        txt_addequipmententry_truckloadingbox2:"",
        txt_addequipmententry_truckloadingboxQty2:"",
        txt_addequipmententry_workforcompanyStatus3:1,
        txt_addequipmententry_workforcompany3:"",
        txt_addequipmententry_rackloadingbox3:"",
        txt_addequipmententry_rackloadingboxQty3:"",
        txt_addequipmententry_truckloadingbox3:"",
        txt_addequipmententry_truckloadingboxQty3:"",
        txt_addequipmententry_workfor:TotalWorkFor
        })
    }
    if(TotalWorkFor===2 || TotalWorkFor==="2"){
      setAddEquipmentEntryFormData({...AddEquipmentEntryFormData,
        txt_addequipmententry_workforcompanyStatus1:0,
        txt_addequipmententry_workforcompany1:"",
        txt_addequipmententry_rackloadingbox1:"",
        txt_addequipmententry_rackloadingboxQty1:"",
        txt_addequipmententry_truckloadingbox1:"",
        txt_addequipmententry_truckloadingboxQty1:"",
        txt_addequipmententry_workforcompanyStatus2:0,
        txt_addequipmententry_workforcompany2:"",
        txt_addequipmententry_rackloadingbox2:"",
        txt_addequipmententry_rackloadingboxQty2:"",
        txt_addequipmententry_truckloadingbox2:"",
        txt_addequipmententry_truckloadingboxQty2:"",
        txt_addequipmententry_workforcompanyStatus3:1,
        txt_addequipmententry_workforcompany3:"",
        txt_addequipmententry_rackloadingbox3:"",
        txt_addequipmententry_rackloadingboxQty3:"",
        txt_addequipmententry_truckloadingbox3:"",
        txt_addequipmententry_truckloadingboxQty3:"",
        txt_addequipmententry_workfor:TotalWorkFor
        })
    }
    if(TotalWorkFor===3 || TotalWorkFor==="3"){
      setAddEquipmentEntryFormData({...AddEquipmentEntryFormData,
        txt_addequipmententry_workforcompanyStatus1:0,
        txt_addequipmententry_workforcompany1:"",
        txt_addequipmententry_rackloadingbox1:"",
        txt_addequipmententry_rackloadingboxQty1:"",
        txt_addequipmententry_truckloadingbox1:"",
        txt_addequipmententry_truckloadingboxQty1:"",
        txt_addequipmententry_workforcompanyStatus2:0,
        txt_addequipmententry_workforcompany2:"",
        txt_addequipmententry_rackloadingbox2:"",
        txt_addequipmententry_rackloadingboxQty2:"",
        txt_addequipmententry_truckloadingbox2:"",
        txt_addequipmententry_truckloadingboxQty2:"",
        txt_addequipmententry_workforcompanyStatus3:0,
        txt_addequipmententry_workforcompany3:"",
        txt_addequipmententry_rackloadingbox3:"",
        txt_addequipmententry_rackloadingboxQty3:"",
        txt_addequipmententry_truckloadingbox3:"",
        txt_addequipmententry_truckloadingboxQty3:"",
        txt_addequipmententry_workfor:TotalWorkFor
        })
    }
   
  }
  const InputChangeShift = (e) => {
    setAddEquipmentEntryFormData({
      ...AddEquipmentEntryFormData,
      [e.target.name]: e.target.value,
    });
    if(AddEquipmentEntryFormData.txt_addequipmententry_starttime){
      AddEquipmentEntryFormData.txt_addequipmententry_shift=e.target.value;
      setShiftWiseEndTime(AddEquipmentEntryFormData.txt_addequipmententry_starttime)
    }
  };
  const InputChangeDateTime = (e)=>{
    setShiftWiseEndTime(e.target.value)
  }
  const setShiftWiseEndTime=(e)=>{
    var today = new Date(e);
    today.setHours(today.getHours() +  parseInt(AddEquipmentEntryFormData.txt_addequipmententry_shift));
    var genEndTime =today.getFullYear()+'-'+`${padLeadingZeros(today.getMonth()+1,2)}`+'-'+padLeadingZeros(today.getDate(),2)+'T'+`${padLeadingZeros(today.getHours(),2)}` + ':' + padLeadingZeros(today.getMinutes(),2);
    setAddEquipmentEntryFormData({
      ...AddEquipmentEntryFormData,
      txt_addequipmententry_starttime: e,txt_addequipmententry_endtime: genEndTime,
    });
  }

 
  const handleEquipmentEntryRoute = (e) => {
    console.log(e);
    setAddEquipmentEntryFormData({
      ...AddEquipmentEntryFormData,
      txt_addequipmententry_route: e.value,
      txt_addequipmententry_company_from: e.route_company_from_name,
      txt_addequipmententry_company_from_id:e.route_company_from,
      txt_addequipmententry_city_from: e.route_from_name,
      txt_addequipmententry_city_from_id: e.route_from,
      txt_addequipmententry_company_to: e.route_company_to_name,
      txt_addequipmententry_company_to_id:e.route_company_to,
      txt_addequipmententry_city_to: e.route_to_name,
      txt_addequipmententry_city_to_id: e.route_to,
      txt_addequipmententry_distance: e.route_distance,
      txt_addequipmententry_fair: e.route_fair
    });
  };
  const handleEquipmentEntryDealWith = (e) => {
   console.log(e);
    setAddEquipmentEntryFormData({
      ...AddEquipmentEntryFormData,
      txt_addequipmententry_routedealwith:e.value
     
    });
  }
  const handleEquipmentEntryItem = (e) => {
    console.log(e);
    setAddEquipmentEntryFormData({
      ...AddEquipmentEntryFormData,
      txt_addequipmententry_productitem:e.value
     
    });
  }
  const handleEquipmentEntryVehicle = (e) => {
    console.log(e);
    setAddEquipmentEntryFormData({
      ...AddEquipmentEntryFormData,
      txt_addequipmententry_vehicle:e.value
     
    });
  }
  const handleEquipmentEntryDriver = (e) => {
    console.log(e);
    setAddEquipmentEntryFormData({
      ...AddEquipmentEntryFormData,
      txt_addequipmententry_driver: e.value
     
    });
  }

  useEffect(() => {
    GetEquipmentEntryRouteList();
  }, []);

  const GetEquipmentEntryRouteList = async () => {
    fetch(`${window.endpoint}route/list`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then((response) => response.json())
      .then((data) => {
        console.log("Success:", data.data);
        if (data.status === 0 || data.status === "0") {
          if (data.status === 0 || data.status === "0") {
            // console.log('------',data.data);
            var i = 1;
            let RouteCompanyOptionData = [
              {
                label: "Select Route",
                value: "",
                route_name:"",
                route_company_from: "",
                route_company_from_name: "",
                route_from: "",
                route_from_name: "",
                route_company_to: "",
                route_company_to_name: "",
                route_to: "",
                route_to_name: "",
              },
            ];
            data.data.forEach((RouteData) => {
              let companyOption = {
                label:
                  RouteData.route_name +
                  "(" +
                  RouteData.route_from_name +
                  "-" +
                  RouteData.route_to_name +
                  ")",
                value: RouteData.route_id,
                route_name:RouteData.route_name,
                route_company_from: RouteData.route_company_from,
                route_company_from_name: RouteData.route_company_from_name,
                route_from: RouteData.route_from,
                route_from_name: RouteData.route_from_name,
                route_company_to: RouteData.route_company_to,
                route_company_to_name: RouteData.route_company_to_name,
                route_to: RouteData.route_to,
                route_to_name: RouteData.route_to_name,
                route_distance:RouteData.route_distance,
                route_fair:RouteData.route_fair
              };
              RouteCompanyOptionData.push(companyOption);
              console.log(i);
              i++;
            });
            // console.log(CityOptionData);
            setEquipmentEntryRouteListData(RouteCompanyOptionData);
          }
        }
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  };
  const frmAddEquipmentEntry = (e) => {
    e.preventDefault();
    setBtnEquipmentEntryLoader(true);

    fetch(`${window.endpoint}tripentry/add`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(AddEquipmentEntryFormData),
    })
      .then((response) => response.json())
      .then((data) => {
        console.log("Success:", data.data.status);
        if (data.data.status === 0 || data.data.status === "0") {
          EquipmentEntryUpdate
            ? setEquipmentEntryUpdate(false)
            : setEquipmentEntryUpdate(true);
          closeAddEquipmentEntry_RB.current.click();
          setSnackBarData({
            alert: "success",
            message: data.data.message,
            open: true,
          });
        }
        setBtnEquipmentEntryLoader(false);
      })
      .catch((error) => {
        console.error("Error:", error);
        setBtnEquipmentEntryLoader(false);
      });
  };
  useEffect(() => {
    GetEquipmentEntryCompanyList();
    GetEquipmentEntryProductList();
    GetEquipmentEntryEmployeeList();
    GetEquipmentEntryMachineryList();
  }, []);
  const [EquipmentEntryCompanyListData, setEquipmentEntryCompanyListData] = useState();
  const [EquipmentEntryProductListData, setEquipmentEntryProductListData] = useState();
  const [EquipmentEntryEmployeeListData, setEquipmentEntryEmployeeListData] = useState();
  const [EquipmentEntryMachineryListData, setEquipmentEntryMachineryListData] = useState();
  const GetEquipmentEntryCompanyList = async () => {
    fetch(`${window.endpoint}company/list`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then((response) => response.json())
      .then((data) => {
        console.log("Success:", data.status);
        if (data.status === 0 || data.status === "0") {
          let EquipmentEntryCompanyOptionData = [{
            label: "Select Company",
            value: "",
            cityName: "",
            cityId: "0"
           }];
            data.data.forEach((CompanyData)=>{
              let companyOption = {
                 label: CompanyData.company_name+'-'+CompanyData.company_city_name,
                 value: CompanyData.company_id,
                 cityName: CompanyData.company_city_name,
                 cityId: CompanyData.company_city
                }
                EquipmentEntryCompanyOptionData.push(companyOption)
             
            })
           
            setEquipmentEntryCompanyListData(EquipmentEntryCompanyOptionData)
       
        }
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  };
  const GetEquipmentEntryProductList = async () => {
    fetch(`${window.endpoint}product/list`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then((response) => response.json())
      .then((data) => {
        console.log("Success:", data.status);
        if (data.status === 0 || data.status === "0") {
          let EquipmentEntryProductOptionData = [{
            label: "Select Product",
            value: "",
          
           }];
            data.data.forEach((ProductData)=>{
               let ProductOptionData = {
                 label: ProductData.product_name,
                 value: ProductData.product_id,
                
                }
                EquipmentEntryProductOptionData.push(ProductOptionData)
             
            })
            setEquipmentEntryProductListData(EquipmentEntryProductOptionData)
        
        }
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  };
  const GetEquipmentEntryEmployeeList = async () => {
    fetch(`${window.endpoint}employee/list`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then((response) => response.json())
      .then((data) => {
        console.log("Success:", data.status);
        if (data.status === 0 || data.status === "0") {
          let EquipmentEntryEmployeeOptionData = [{
            label: "Select Driver",
            value: "",
          
           }];
            data.data.forEach((EmployeeData)=>{
             let emplType =  EmployeeData.employee_position==="1"?'Truck Driver':EmployeeData.employee_position==="2"?'Hywa Driver':EmployeeData.employee_position==="3"?'Machine Operator':EmployeeData.employee_position==="4"?'Helper':EmployeeData.employee_position==="5"?'Mechanic':EmployeeData.employee_position==="6"?'Super Wiser':EmployeeData.employee_position==="7"?'Manager':EmployeeData.employee_position==="8"?'Other':'-';
               let EmployeeOptionData = {
                 label: EmployeeData.employee_name+'-'+emplType+'/'+EmployeeData.employee_contactno,
                 value: EmployeeData.employee_id,
                
                }
                EquipmentEntryEmployeeOptionData.push(EmployeeOptionData)
             
            })
            setEquipmentEntryEmployeeListData(EquipmentEntryEmployeeOptionData)
        }
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  };
  const GetEquipmentEntryMachineryList = async () => {
    fetch(`${window.endpoint}machinery/list`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then((response) => response.json())
      .then((data) => {
        console.log("Success:", data.status);
        if (data.status === 0 || data.status === "0") {
          
          let EquipmentEntryMachineryOptionData = [{
            label: "Select Operator",
            value: "",
          
           }];
            data.data.forEach((MachineryData)=>{
              let MachineryType = MachineryData.machinery_type==="1"?'TRUCK':MachineryData.machinery_type==="2"?'HYWA':MachineryData.machinery_type==="3"?'EQUIPMENT':'-';
               let MachineryOptionData = {
                 label: MachineryData.machinery_brand+'-'+MachineryData.machinery_rc_no+'/'+MachineryType,
                 value: MachineryData.machinery_id,
                
                }
                EquipmentEntryMachineryOptionData.push(MachineryOptionData)
             
            })
            setEquipmentEntryMachineryListData(EquipmentEntryMachineryOptionData)
        
        }
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  };
  return (
    <>
      {/* Right modal content */}
      <div
        id="Mdl_AddEquipmentEntry_RB"
        className="modal fade"
        tabIndex={-1}
        role="dialog"
        aria-hidden="true"
      >
        <div
          className="modal-dialog modal-md modal-right"
          style={{ maxWidth: "500px", width: "100%" }}
        >
          <div className="modal-content  h-100">
            <h6 className="fw-semibold px-3 m-0 py-2 font-16 bg-light">
              <span className="d-block py-2">ADD EQUIPMENT TRIPS</span>
            </h6>
            <div className="modal-header p-0 border-0">
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              />
            </div>

            <div className="modal-body">
              <form
                id="frm_AddEquipmentEntry"
                onSubmit={(e) => {
                  frmAddEquipmentEntry(e);
                }}
              >
                <div className="row ms-1 me-1">
                <div className="col-sm-12">
                  <div className="row">
                  <fieldset className="border  pb-2">
                    <legend className="float-none w-auto legendFontsize text-primary">
                      Trip Details:
                    </legend>
                    <div className="col-sm-12">
                      <div className="row">
                    <div className="col-sm-12 mb-1">
                      <label>Trip Date</label>
                      <input
                        type="date"
                        name="txt_addequipmententry_date"
                        id="txt_addequipmententry_date"
                        value={AddEquipmentEntryFormData.txt_addequipmententry_date}
                        className="form-control "
                        placeholder="Enter EquipmentEntry Date"
                        onChange={InputChange}
                        required
                      />
                    </div>
                    <div className="col-sm-12 mb-1">
                      <label>Company</label>
                      <Select
                          //  options={EquipmentEntryMachineryListData}
                           className="text-capitalize"
                           name="txt_addequipmententry_company"
                           id="txt_addequipmententry_company"
                           placeholder="Select Company"
                          //  onChange={(e) => {
                          //   handleEquipmentEntryVehicle(e);
                          // }}
                          // value={
                          //   EquipmentEntryMachineryListData &&
                          //   EquipmentEntryMachineryListData.filter(
                          //     (option) =>
                          //       option.value ===
                          //       AddEquipmentEntryFormData.txt_addequipmententry_vehicle
                          //   )
                          // }
                         />
                    
                    </div>
                    <div className="col-sm-12 col-12 mb-1">
                          <label className="mb-1">Machinery Type</label>
                          <div className="row">
                            <div className="col-sm-4 d-none">
                            <div className="form-check ">
                          <input className="form-check-input" type="radio" name="txt_addequipmententry_machinerytype" id="txt_addequipmententry_machinerytype1" value="1"  onChange={InputChange} checked={(AddEquipmentEntryFormData.txt_addequipmententry_machinerytype==="1" || AddEquipmentEntryFormData.txt_addequipmententry_machinerytype===1)?true:false}/>
                          <label className="form-check-label" for="txt_addequipmententry_machinerytype1">Truck</label>
                      </div>
                            </div>
                            <div className="col-sm-4  d-none">
                            <div className="form-check  form-check-success">
                          <input className="form-check-input" type="radio" name="txt_addequipmententry_machinerytype" id="txt_addequipmententry_machinerytype2" value="2"  onChange={InputChange} checked={(AddEquipmentEntryFormData.txt_addequipmententry_machinerytype==="2" || AddEquipmentEntryFormData.txt_addequipmententry_machinerytype===2)?true:false}/>
                          <label className="form-check-label" for="txt_addequipmententry_machinerytype2">Hywa</label>
                      </div>
                            </div>
                            <div className="col-sm-4">
                            <div className="form-check  form-check-warning">
                          <input className="form-check-input" type="radio" name="txt_addequipmententry_machinerytype" id="txt_addequipmententry_machinerytype3" value="3"  onChange={InputChange} checked={(AddEquipmentEntryFormData.txt_addequipmententry_machinerytype==="3" || AddEquipmentEntryFormData.txt_addequipmententry_machinerytype===3)?true:false}/>
                          <label className="form-check-label" for="txt_addequipmententry_machinerytype3">Equipment</label>
                      </div>
                            </div>
                          </div>
                    

                     
                     
                        </div>
                    </div>
                    <div className="col-sm-12 mb-1">
                      <label>Vehicle</label>
                      <Select
                           options={EquipmentEntryMachineryListData}
                           className="text-capitalize"
                           name="txt_addequipmententry_vehicle"
                           id="txt_addequipmententry_vehicle"
                           placeholder="Select Vechicle"
                           onChange={(e) => {
                            handleEquipmentEntryVehicle(e);
                          }}
                          value={
                            EquipmentEntryMachineryListData &&
                            EquipmentEntryMachineryListData.filter(
                              (option) =>
                                option.value ===
                                AddEquipmentEntryFormData.txt_addequipmententry_vehicle
                            )
                          }
                         />
                    
                    </div>
                    <div className="col-sm-12 mb-1">
                      <label>Operator</label>
                      <Select
                            options={EquipmentEntryEmployeeListData}
                           className="text-capitalize"
                           name="txt_addequipmententry_driver"
                           id="txt_addequipmententry_driver"
                           placeholder="Select Operator"
                           onChange={(e) => {
                            handleEquipmentEntryDriver(e);
                          }}
                          value={
                            EquipmentEntryEmployeeListData &&
                            EquipmentEntryEmployeeListData.filter(
                              (option) =>
                                option.value ===
                                AddEquipmentEntryFormData.txt_addequipmententry_driver
                            )
                          }
                         />
                     
                    </div> 
                    <div className="col-sm-12 mb-1">
                      <label>Shift</label>
                      <select
                           
                           className="form-select"
                           name="txt_addequipmententry_shift"
                           id="txt_addequipmententry_shift"
                           placeholder="Select Shift"
                           onChange={InputChangeShift}
                           value={AddEquipmentEntryFormData.txt_addequipmententry_shift}
                         >
                          <option value="0">Select Shift</option>
                          <option value="8">Shift A (8 Hours)</option>
                          <option value="12">Shift B (12 Hours)</option>
                          <option value="24">Shift C (24 Hours)</option>
                         </select>
                     
                    </div> 
                    <div className="col-sm-12">
                      <div className="row">
                      <div className="col-sm-6 col-xs-6 col-6 mb-1">
                          <label>Start Time</label>
                          <input
                            type="datetime-local"
                            name="txt_addequipmententry_starttime"
                            id="txt_addequipmententry_starttime"
                            value={AddEquipmentEntryFormData.txt_addequipmententry_starttime}
                            className="form-control "
                            placeholder="Enter Start Time"
                            onChange={InputChangeDateTime}
                            required
                          
                          />
                        </div>
                        <div className="col-sm-6 col-xs-6 col-6 mb-1">
                          <label>End Time</label>
                          <input
                            type="datetime-local"
                            name="txt_addequipmententry_endtime"
                            id="txt_addequipmententry_endtime"
                            value={AddEquipmentEntryFormData.txt_addequipmententry_endtime}
                            className="form-control "
                            placeholder="Enter End Time"
                            onChange={InputChange}
                            required
                          
                          />
                        </div>
                      </div>
                    </div>
                    <div className="col-sm-12">
                      <div className="row">
                      <div className="col-sm-6 col-xs-6 col-6 mb-1">
                          <label>Opening Reading</label>
                          <input
                            type="tel"
                            name="txt_addequipmententry_openingreading"
                            id="txt_addequipmententry_openingreading"
                            value={AddEquipmentEntryFormData.txt_addequipmententry_openingreading}
                            className="form-control "
                            placeholder="Enter Opening Reading"
                            onChange={InputChange}
                            required
                          
                          />
                        </div>
                        <div className="col-sm-6 col-xs-6 col-6 mb-1">
                          <label>Closing Reading</label>
                          <input
                            type="tel"
                            name="txt_addequipmententry_closingreading"
                            id="txt_addequipmententry_closingreading"
                            value={AddEquipmentEntryFormData.txt_addequipmententry_closingreading}
                            className="form-control "
                            placeholder="Enter Closinng Reading"
                            onChange={InputChange}
                            required
                          
                          />
                        </div>
                      </div>
                    </div>
                    <div className="col-sm-12 mb-1">
                      <label>Shifting Work For</label>
                      <select
                           
                           className="form-select"
                           name="txt_addequipmententry_workfor"
                           id="txt_addequipmententry_workfor"
                           placeholder="Select Shift"
                           onChange={InputChangeWorkFor}
                           value={AddEquipmentEntryFormData.txt_addequipmententry_workfor}
                         >
                          <option value="0">Select Shift</option>
                          <option value="1">1 Company</option>
                          <option value="2">2 Company</option>
                          <option value="3">3 Company</option>
                      
                          
                         </select>
                     
                    </div>
                    <div className="col-sm-12">
                      {AddEquipmentEntryFormData.txt_addequipmententry_workforcompanyStatus1===0 && <div className="row">
                      <hr></hr>
                   <h6><b>Work Detail 1:</b></h6>
                    <div className="col-sm-12 col-12 mb-1">
                          <label>Work For Company Name</label>
                          <Select
                            options={EquipmentEntryCompanyListData}
                            className="text-capitalize"
                            name="txt_addequipmententry_workforcompany1"
                            id="txt_addequipmententry_workforcompany1"
                            placeholder="Select Company"
                            // onChange={(e) => {
                            //   handleEquipmentEntryDealWith(e);
                            // }}
                            // value={
                            //   EquipmentEntryCompanyListData &&
                            //   EquipmentEntryCompanyListData.filter(
                            //     (option) =>
                            //       option.value ===
                            //       AddEquipmentEntryFormData.txt_addequipmententry_routedealwith
                            //   )
                            // }
                          />
                        </div>
                        <div className="col-sm-12 col-12 mb-1">
                          <label>Product</label>
                          <Select
                          
                            className="text-capitalize"
                            name="txt_addequipmententry_productofcompany1"
                            id="txt_addequipmententry_productofcompany1"
                            placeholder="Select Product"
                            // onChange={(e) => {
                            //   handleEquipmentEntryDealWith(e);
                            // }}
                            // value={
                            //   EquipmentEntryCompanyListData &&
                            //   EquipmentEntryCompanyListData.filter(
                            //     (option) =>
                            //       option.value ===
                            //       AddEquipmentEntryFormData.txt_addequipmententry_routedealwith
                            //   )
                            // }
                          />
                        </div>
                    <div className="col-sm-12">
                      <div className="row">
                      <div className="col-sm-6 col-xs-6 col-6 mb-1">
                          <label>Rack Loading Box</label>
                          <input
                            type="tel"
                            name="txt_addequipmententry_rackloadingbox1"
                            id="txt_addequipmententry_rackloadingbox1"
                            value={AddEquipmentEntryFormData.txt_addequipmententry_rackloadingbox1}
                            className="form-control "
                            placeholder="Enter Rack Loading"
                            onChange={InputChange}
                            required
                          
                          />
                        </div>
                        <div className="col-sm-6 col-xs-6 col-6 mb-1">
                          <label>Quanitity</label>
                          <input
                            type="tel"
                            name="txt_addequipmententry_rackloadingboxQty1"
                            id="txt_addequipmententry_rackloadingboxQty1"
                            value={AddEquipmentEntryFormData.txt_addequipmententry_rackloadingboxQty1}
                            className="form-control "
                            placeholder="Enter Quanitity"
                            onChange={InputChange}
                            required
                          
                          />
                        </div>
                      </div>
                    </div>
                    <div className="col-sm-12">
                      <div className="row">
                      <div className="col-sm-6 col-xs-6 col-6 mb-1">
                          <label>Truck Loading Box</label>
                          <input
                            type="tel"
                            name="txt_addequipmententry_truckloadingbox1"
                            id="txt_addequipmententry_truckloadingbox1"
                            value={AddEquipmentEntryFormData.txt_addequipmententry_truckloadingbox1}
                            className="form-control "
                            placeholder="Enter Truck Loading"
                            onChange={InputChange}
                            required
                          
                          />
                        </div>
                        <div className="col-sm-6 col-xs-6 col-6 mb-1">
                          <label>Quanitity</label>
                          <input
                            type="tel"
                            name="txt_addequipmententry_rackloadingboxQty1"
                            id="txt_addequipmententry_rackloadingboxQty1"
                            value={AddEquipmentEntryFormData.txt_addequipmententry_rackloadingboxQty1}
                            className="form-control "
                            placeholder="Enter Quanitity"
                            onChange={InputChange}
                            required
                          
                          />
                        </div>
                      </div>
                    </div>
                    <div className="col-sm-12 col-12 mb-1">
                      <label>Dozing</label>
                      <input
                        type="text"
                        name="txt_addequipmententry_dozing1"
                        id="txt_addequipmententry_dozing1"
                        value={
                          AddEquipmentEntryFormData.txt_addequipmententry_dozing1
                        }
                        className="form-control "
                        onChange={InputChange}
                        
                      />
                    </div>
                      </div>}
                      {AddEquipmentEntryFormData.txt_addequipmententry_workforcompanyStatus2===0 && <div className="row">
                      <hr></hr>
                   <h6><b>Work Detail 2:</b></h6>
                    <div className="col-sm-12 col-12 mb-1">
                          <label>Work For Company Name</label>
                          <Select
                            options={EquipmentEntryCompanyListData}
                            className="text-capitalize"
                            name="txt_addequipmententry_workforcompany2"
                            id="txt_addequipmententry_workforcompany2"
                            placeholder="Select Company"
                            // onChange={(e) => {
                            //   handleEquipmentEntryDealWith(e);
                            // }}
                            // value={
                            //   EquipmentEntryCompanyListData &&
                            //   EquipmentEntryCompanyListData.filter(
                            //     (option) =>
                            //       option.value ===
                            //       AddEquipmentEntryFormData.txt_addequipmententry_routedealwith
                            //   )
                            // }
                          />
                        </div>
                        <div className="col-sm-12 col-12 mb-1">
                          <label>Product</label>
                          <Select
                          
                            className="text-capitalize"
                            name="txt_addequipmententry_productofcompany2"
                            id="txt_addequipmententry_productofcompany2"
                            placeholder="Select Product"
                            // onChange={(e) => {
                            //   handleEquipmentEntryDealWith(e);
                            // }}
                            // value={
                            //   EquipmentEntryCompanyListData &&
                            //   EquipmentEntryCompanyListData.filter(
                            //     (option) =>
                            //       option.value ===
                            //       AddEquipmentEntryFormData.txt_addequipmententry_routedealwith
                            //   )
                            // }
                          />
                        </div>
                    <div className="col-sm-12">
                      <div className="row">
                      <div className="col-sm-6 col-xs-6 col-6 mb-1">
                          <label>Rack Loading Box</label>
                          <input
                            type="tel"
                            name="txt_addequipmententry_rackloadingbox2"
                            id="txt_addequipmententry_rackloadingbox2"
                            value={AddEquipmentEntryFormData.txt_addequipmententry_rackloadingbox2}
                            className="form-control "
                            placeholder="Enter Rack Loading"
                            onChange={InputChange}
                            required
                          
                          />
                        </div>
                        <div className="col-sm-6 col-xs-6 col-6 mb-1">
                          <label>Quanitity</label>
                          <input
                            type="tel"
                            name="txt_addequipmententry_rackloadingboxQty2"
                            id="txt_addequipmententry_rackloadingboxQty2"
                            value={AddEquipmentEntryFormData.txt_addequipmententry_rackloadingboxQty2}
                            className="form-control "
                            placeholder="Enter Quanitity"
                            onChange={InputChange}
                            required
                          
                          />
                        </div>
                      </div>
                    </div>
                    <div className="col-sm-12">
                      <div className="row">
                      <div className="col-sm-6 col-xs-6 col-6 mb-1">
                          <label>Truck Loading Box</label>
                          <input
                            type="tel"
                            name="txt_addequipmententry_truckloadingbox2"
                            id="txt_addequipmententry_truckloadingbox2"
                            value={AddEquipmentEntryFormData.txt_addequipmententry_truckloadingbox2}
                            className="form-control "
                            placeholder="Enter Truck Loading"
                            onChange={InputChange}
                            required
                          
                          />
                        </div>
                        <div className="col-sm-6 col-xs-6 col-6 mb-1">
                          <label>Quanitity</label>
                          <input
                            type="tel"
                            name="txt_addequipmententry_rackloadingboxQty2"
                            id="txt_addequipmententry_rackloadingboxQty2"
                            value={AddEquipmentEntryFormData.txt_addequipmententry_rackloadingboxQty2}
                            className="form-control "
                            placeholder="Enter Quanitity"
                            onChange={InputChange}
                            required
                          
                          />
                        </div>
                      </div>
                    </div>
                    <div className="col-sm-12 col-12 mb-1">
                      <label>Dozing</label>
                      <input
                        type="text"
                        name="txt_addequipmententry_dozing2"
                        id="txt_addequipmententry_dozing2"
                        value={
                          AddEquipmentEntryFormData.txt_addequipmententry_dozing2
                        }
                        className="form-control "
                        onChange={InputChange}
                        
                      />
                    </div>
                      </div>}
                      {AddEquipmentEntryFormData.txt_addequipmententry_workforcompanyStatus3===0 && <div className="row">
                      <hr></hr>
                   <h6><b>Work Detail 3:</b></h6>
                    <div className="col-sm-12 col-12 mb-1">
                          <label>Work For Company Name</label>
                          <Select
                            options={EquipmentEntryCompanyListData}
                            className="text-capitalize"
                            name="txt_addequipmententry_workforcompany3"
                            id="txt_addequipmententry_workforcompany3"
                            placeholder="Select Company"
                            // onChange={(e) => {
                            //   handleEquipmentEntryDealWith(e);
                            // }}
                            // value={
                            //   EquipmentEntryCompanyListData &&
                            //   EquipmentEntryCompanyListData.filter(
                            //     (option) =>
                            //       option.value ===
                            //       AddEquipmentEntryFormData.txt_addequipmententry_routedealwith
                            //   )
                            // }
                          />
                        </div>
                        <div className="col-sm-12 col-12 mb-1">
                          <label>Product</label>
                          <Select
                          
                            className="text-capitalize"
                            name="txt_addequipmententry_productofcompany3"
                            id="txt_addequipmententry_productofcompany3"
                            placeholder="Select Product"
                            // onChange={(e) => {
                            //   handleEquipmentEntryDealWith(e);
                            // }}
                            // value={
                            //   EquipmentEntryCompanyListData &&
                            //   EquipmentEntryCompanyListData.filter(
                            //     (option) =>
                            //       option.value ===
                            //       AddEquipmentEntryFormData.txt_addequipmententry_routedealwith
                            //   )
                            // }
                          />
                        </div>
                    <div className="col-sm-12">
                      <div className="row">
                      <div className="col-sm-6 col-xs-6 col-6 mb-1">
                          <label>Rack Loading Box</label>
                          <input
                            type="tel"
                            name="txt_addequipmententry_rackloadingbox3"
                            id="txt_addequipmententry_rackloadingbox3"
                            value={AddEquipmentEntryFormData.txt_addequipmententry_rackloadingbox3}
                            className="form-control "
                            placeholder="Enter Rack Loading"
                            onChange={InputChange}
                            required
                          
                          />
                        </div>
                        <div className="col-sm-6 col-xs-6 col-6 mb-1">
                          <label>Quanitity</label>
                          <input
                            type="tel"
                            name="txt_addequipmententry_rackloadingboxQty3"
                            id="txt_addequipmententry_rackloadingboxQty3"
                            value={AddEquipmentEntryFormData.txt_addequipmententry_rackloadingboxQty3}
                            className="form-control "
                            placeholder="Enter Quanitity"
                            onChange={InputChange}
                            required
                          
                          />
                        </div>
                      </div>
                    </div>
                    <div className="col-sm-12">
                      <div className="row">
                      <div className="col-sm-6 col-xs-6 col-6 mb-1">
                          <label>Truck Loading Box</label>
                          <input
                            type="tel"
                            name="txt_addequipmententry_truckloadingbox3"
                            id="txt_addequipmententry_truckloadingbox3"
                            value={AddEquipmentEntryFormData.txt_addequipmententry_truckloadingbox3}
                            className="form-control "
                            placeholder="Enter Truck Loading"
                            onChange={InputChange}
                            required
                          
                          />
                        </div>
                        <div className="col-sm-6 col-xs-6 col-6 mb-1">
                          <label>Quanitity</label>
                          <input
                            type="tel"
                            name="txt_addequipmententry_rackloadingboxQty3"
                            id="txt_addequipmententry_rackloadingboxQty3"
                            value={AddEquipmentEntryFormData.txt_addequipmententry_rackloadingboxQty3}
                            className="form-control "
                            placeholder="Enter Quanitity"
                            onChange={InputChange}
                            required
                          
                          />
                        </div>
                      </div>
                    </div>
                    <div className="col-sm-12 col-12 mb-1">
                      <label>Dozing</label>
                      <input
                        type="text"
                        name="txt_addequipmententry_dozing3"
                        id="txt_addequipmententry_dozing3"
                        value={
                          AddEquipmentEntryFormData.txt_addequipmententry_dozing3
                        }
                        className="form-control "
                        onChange={InputChange}
                        
                      />
                    </div>
                      </div>}
                    </div>
                  
                    </div>
                    </fieldset>
                
                    <fieldset className="border  pb-2">
                    <legend className="float-none w-auto legendFontsize text-primary">
                    Transaction Details:
                    </legend>
                   
                   
                    <div className="col-sm-12">
                      <div className="row">
                        
                       
                    <div className="col-sm-12 mb-1">
                      <div className="row">
                        <div className="col-sm-6  col-6">
                          <label>Challan No</label>
                          <input
                            type="text"
                            name="txt_addequipmententry_challan_no"
                            id="txt_addequipmententry_challan_no"
                            value={
                              AddEquipmentEntryFormData.txt_addequipmententry_challan_no
                            }
                            className="form-control "
                            onChange={InputChange}
                            
                          />
                        </div>
                        <div className="col-sm-6 col-6 ">
                          <label>Voucher No.</label>
                          <input
                            type="text"
                            name="txt_addequipmententry_voucher_no"
                            id="txt_addequipmententry_voucher_no"
                            value={
                              AddEquipmentEntryFormData.txt_addequipmententry_voucher_no
                            }
                            className="form-control "
                            onChange={InputChange}
                            
                          />
                        </div>
                      </div>
                    </div>
                    <div className="col-sm-12 mb-1">
                      <div className="row">
                        <div className="col-sm-6  col-6">
                          <label>Diesel</label>
                          <div className="input-group">
                          <input
                            type="text"
                            name="txt_addequipmententry_diesel"
                            id="txt_addequipmententry_diesel"
                            value={AddEquipmentEntryFormData.txt_addequipmententry_diesel}
                            className="form-control "
                            onChange={InputChange}
                            
                          />
                         <div class="input-group-text">Ltr's</div>
                          </div>
                        </div>
                        <div className="col-sm-6 col-6 ">
                          <label>Urea</label>
                          <div className="input-group">
                          <input
                            type="text"
                            name="txt_addequipmententry_urea"
                            id="txt_addequipmententry_urea"
                            value={
                              AddEquipmentEntryFormData.txt_addequipmententry_urea
                            }
                            className="form-control "
                            onChange={InputChange}
                            
                          />
                           <div class="input-group-text">Ltr's</div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-sm-12 col-12 mb-1">
                      <label>Incentive Amount</label>
                      <input
                        type="text"
                        name="txt_addequipmententry_incentive_amount"
                        id="txt_addequipmententry_incentive_amount"
                        value={
                          AddEquipmentEntryFormData.txt_addequipmententry_incentive_amount
                        }
                        className="form-control "
                        onChange={InputChange}
                        
                      />
                    </div>
                      </div>
                    </div>
                   
                    </fieldset>
                

                  
                    <fieldset className="border  pb-2">
                    <legend className="float-none w-auto legendFontsize text-primary">
                      Other Details:
                    </legend>

                    <div className="col-sm-12">
                      <label>Description</label>
                      <textarea
                        type="text"
                        name="txt_addequipmententry_description"
                        id="txt_addequipmententry_description"
                        value={
                          AddEquipmentEntryFormData.txt_addequipmententry_description
                        }
                        className="form-control "
                        onChange={InputChange}
                        
                        rows={3}
                      ></textarea>
                    </div>
                    </fieldset>
                  </div>
                </div>
                </div>
                <div className="modal-footer mdlFooter">
                  <button
                    type="button"
                    className="btn btn-secondary"
                    data-bs-dismiss="modal"
                    ref={closeAddEquipmentEntry_RB}
                  >
                    Close
                  </button>
                  {!BtnEquipmentEntryLoader && (
                    <>
                      <button
                        type="submit"
                        className="btn btn-primary btnLoader"
                      >
                        Submit
                      </button>
                    </>
                  )}
                  {BtnEquipmentEntryLoader && (
                    <>
                      <button
                        className="btn btn-primary btnLoader"
                        type="button"
                        disabled=""
                      >
                        <span
                          className="spinner-border spinner-border-sm me-1"
                          role="status"
                          aria-hidden="true"
                        ></span>
                        Loading...
                      </button>
                    </>
                  )}
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
