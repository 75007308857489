import React, { useContext, useEffect, useRef, useState } from 'react'
import LanguageContext from '../../../Include/Context/LanguageContext';

export const AddEmployee_RB = ({setEmployeeUpdate,EmployeeUpdate,EmployeeEditData,EmployeeEditStatus,time}) => {
  let closeAddEmployee_RB = useRef();
  const [BtnEmpSubmitLoader, setBtnEmpSubmitLoader] = useState(false);
  const {setSnackBarData} = useContext(LanguageContext) ;
  
  const [FormAddEmployee, setFormAddEmployee] = useState({
    txt_employee_id:"0",
    txt_employee_position:"",
    txt_employee_name:"",
    txt_employee_contactno:"",
    txt_employee_contactno_alt:"",
    txt_employee_gender:"0",
    txt_employee_dob:"",
    txt_employee_email:"",
    txt_employee_maritalstatus:"0",
    txt_employee_city:"",
    txt_employee_state:"",
    txt_employee_residenceaddress:"",
    txt_employee_permanentaddress:"",
    txt_employee_aadhaarno:"",
    txt_employee_panno:"",
    txt_employee_drivinglicenseno:"",
    txt_employee_pfno:"",
    txt_employee_esicno:"",
    txt_employee_fathername:"",
    txt_employee_mothername:"",
    txt_employee_familycontactno:"",
    txt_employee_familycontactno_alt:"",
    txt_employee_familyaddress:"",
    txt_employee_emer_contactname:"",
    txt_employee_emer_contactno:"",
    txt_employee_emer_contactno_alt:"",
    txt_employee_emer_contactrelation:"",
    txt_employee_othernote:"",
    txt_employee_status:"0",
  });
  const FormEmployeeDetailsClear = ()=>{
   setFormAddEmployee({ txt_employee_id:"0",
   txt_employee_position:"",
   txt_employee_name:"",
   txt_employee_contactno:"",
   txt_employee_contactno_alt:"",
   txt_employee_gender:"0",
   txt_employee_dob:"",
   txt_employee_email:"",
   txt_employee_maritalstatus:"0",
   txt_employee_city:"",
   txt_employee_state:"",
   txt_employee_residenceaddress:"",
   txt_employee_permanentaddress:"",
   txt_employee_aadhaarno:"",
   txt_employee_panno:"",
   txt_employee_drivinglicenseno:"",
   txt_employee_pfno:"",
   txt_employee_esicno:"",
   txt_employee_fathername:"",
   txt_employee_mothername:"",
   txt_employee_familycontactno:"",
   txt_employee_familycontactno_alt:"",
   txt_employee_familyaddress:"",
   txt_employee_emer_contactname:"",
   txt_employee_emer_contactno:"",
   txt_employee_emer_contactno_alt:"",
   txt_employee_emer_contactrelation:"",
   txt_employee_othernote:"",
   txt_employee_status:"0"})
  }
  const FormEmployeeDetailsUpdate = (data)=>{
    console.log(data,'from Employee Edit')

    setFormAddEmployee({ 
      txt_employee_id:data.employee_id,
    txt_employee_position:data.employee_position,
    txt_employee_name:data.employee_name,
    txt_employee_contactno:data.employee_contactno,
    txt_employee_contactno_alt:data.employee_contactno_alt,
    txt_employee_gender:data.employee_gender,
    txt_employee_dob:data.employee_dob,
    txt_employee_email:data.employee_email,
    txt_employee_maritalstatus:data.employee_maritalstatus,
    txt_employee_city:data.employee_city,
    txt_employee_state:data.employee_state,
    txt_employee_residenceaddress:data.employee_residence_address,
    txt_employee_permanentaddress:data.employee_permanent_address,
    txt_employee_aadhaarno:data.employee_aadhar_card_no,
    txt_employee_panno:data.employee_pancard_no,
    txt_employee_drivinglicenseno:data.employee_driving_license_no,
    txt_employee_pfno:data.employee_pfno,
    txt_employee_esicno:data.employee_esicno,
    txt_employee_fathername:data.employee_fathername,
    txt_employee_mothername:data.employee_mothername,
    txt_employee_familycontactno:data.employee_family_contactno,
    txt_employee_familycontactno_alt:data.employee_family_contactno_alt,
    txt_employee_familyaddress:data.employee_family_residence_address,
    txt_employee_emer_contactname:data.employee_emer_name,
    txt_employee_emer_contactno:data.employee_emer_contactno,
    txt_employee_emer_contactno_alt:data.employee_emer_contactno_alt,
    txt_employee_emer_contactrelation:data.employee_emer_relationship,
    txt_employee_othernote:data.employee_other_note,
    txt_employee_status:data.status,
  })
   }
  const InputChange = (e)=>{
    setFormAddEmployee({...FormAddEmployee,[e.target.name]:e.target.value})
    }
    useEffect(() => {
      if(EmployeeEditStatus===0){
        FormEmployeeDetailsClear();
      }else{
        FormEmployeeDetailsUpdate(EmployeeEditData)
      }
      }, [time])
  const frmAddEmployee=(e)=>{
    e.preventDefault();
    setBtnEmpSubmitLoader(true)
    
    fetch(`${window.endpoint}employee/add`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(FormAddEmployee),
    })
    .then((response) => response.json())
    .then((data) => {
      console.log('Success:', data.data.status);
      if(data.data.status===0 || data.data.status==="0"){
        EmployeeUpdate?setEmployeeUpdate(false):setEmployeeUpdate(true)
        closeAddEmployee_RB.current.click();
        setSnackBarData({
          alert: "success",
          message:data.data.message,
          open: true
        })
      }
      setBtnEmpSubmitLoader(false)
    })
    .catch((error) => {
      setBtnEmpSubmitLoader(false)
      console.error('Error:', error);
    });
    
  }
  return (
    <>
      {/* Right modal content */}
      <div
        id="Mdl_AddEmployee_RB"
        className="modal fade"
        tabIndex={-1}
        role="dialog"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-md modal-right" style={{maxWidth:"500px",width:"100%"}}>

          <div className="modal-content h-100 ">
           
            <div className="modal-header border-0 p-0 w-100">
            <h6 class="fw-semibold px-3 m-0 py-2 font-16 bg-light w-100">
              <span class="d-block py-2">Add Employee</span>
            </h6>
              {/* <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              /> */}
            </div>
            <div className="modal-body">
              <form onSubmit={(e)=>{frmAddEmployee(e)}}>
                <div className='row ms-1 me-1'>
                <div className='col-sm-12'>
                <div className='row'>
                <fieldset className="border  pb-2">
                    <legend className="float-none w-auto legendFontsize text-primary">
                      Position Details:
                    </legend>
                    <div className="col-sm-12">
                      
                        <label>Position</label>
                        <select type="text" className="form-select" name="txt_employee_position" id="txt_employee_position" value={FormAddEmployee.txt_employee_position} onChange={InputChange} required>
                        <option value="">Select title</option>
                          <option  value="1">Truck Driver</option>
                          <option  value="2">Hywa Driver</option>
                          <option  value="3">Machine Operator</option>
                          <option  value="4">Helper</option>
                          <option  value="5">Mechanic</option>
                          <option  value="6">Super Wiser</option>
                          <option  value="7">Manager</option>
                          <option  value="8">Other</option>
                        </select>
                  
                    </div>
                  </fieldset>
                <fieldset className="border  pb-2">
                    <legend className="float-none w-auto legendFontsize text-primary">
                      Personal Details:
                    </legend>
                    <div className="col-sm-12">
                      <div className="row">
                        <div className="col-sm-12 mb-1">
                          <label>Name:</label>
                            <input type="text" className="form-control" name="txt_employee_name" id="txt_employee_name" value={FormAddEmployee.txt_employee_name}  onChange={InputChange}  required></input>
                        </div>
                        <div className="col-sm-12 mb-1">
                          <div className='row'>
                            <div className='col-sm-6'>
                            <label>Contact No:</label>
                          <input type="text" className="form-control" name="txt_employee_contactno" id="txt_employee_contactno" value={FormAddEmployee.txt_employee_contactno}    onChange={InputChange} required></input>
                            </div>
                            <div className='col-sm-6'>
                            <label>Alternate Contact No:</label>
                          <input type="text" className="form-control" name="txt_employee_contactno_alt" id="txt_employee_contactno_alt" value={FormAddEmployee.txt_employee_contactno_alt}  onChange={InputChange}></input>
                              </div>
                          </div>
                        
                        </div>
                        <div className="col-sm-12 mb-1">
                          <div className='row'>
                            <div className='col-sm-6'>
                            <label>Gender</label>
                          <select  className="form-select" name="txt_employee_gender" id="txt_employee_gender" value={FormAddEmployee.txt_employee_gender}  onChange={InputChange} required>
                            <option value="0">Select</option>
                            <option value="1">Male</option>
                            <option value="2">Female</option>
                          </select>
                            </div>
                            <div className='col-sm-6'>
                            <label>Date of birth:</label>
                          <input type="date" className="form-control" name="txt_employee_dob" id="txt_employee_dob" value={FormAddEmployee.txt_employee_dob}  onChange={InputChange} ></input>
                              </div>
                          </div>
                        
                        </div>
                       
                        <div className="col-sm-12 mb-1">
                          <label>Email Id:</label>
                          <input type="text" className="form-control" name="txt_employee_email" id="txt_employee_email" value={FormAddEmployee.txt_employee_email}  onChange={InputChange} ></input>
                        </div>
                        
                        <div className="col-sm-12 mb-1">
                          <label>Marital Status:</label>
                          <select type="text" className="form-control" name="txt_employee_maritalstatus" id="txt_employee_maritalstatus" value={FormAddEmployee.txt_employee_maritalstatus}  onChange={InputChange}>
                            <option value="0">Select</option>
                            <option value="1">Single</option>
                            <option value="2">Married</option>
                           
                          </select>
                        </div>
                        
                        
                      </div>
                    </div>
                  </fieldset>
                  <fieldset className="border  pb-2">
                    <legend className="float-none w-auto legendFontsize text-primary">
                      Residence Details:
                    </legend>
                    <div className="col-sm-12">
                      <div className="row">
                        <div className="col-sm-12 mb-1">
                          <div className='row'>
                            <div className='col-sm-6'>
                            <label>City:</label>
                            <input type="text" className="form-control" name="txt_employee_city" id="txt_employee_city" value={FormAddEmployee.txt_employee_city}  onChange={InputChange}></input>
                            </div>
                            <div className='col-sm-6'>
                            <label>State:</label>
                          <input type="text" className="form-control" name="txt_employee_state" id="txt_employee_state" value={FormAddEmployee.txt_employee_state}  onChange={InputChange}></input>
                              </div>
                          </div>
                        
                        </div>
                       
                        <div className="col-sm-12 mb-1">
                          <label>Residence Address</label>
                          <textarea rows={3}  className="form-control" name="txt_employee_residenceaddress"  id="txt_employee_residenceaddress" value={FormAddEmployee.txt_employee_residenceaddress}  onChange={InputChange}></textarea>
                        </div>
                        <div className="col-sm-12 mb-1">
                          <label>Permanent Address</label>
                          <textarea rows={3}  className="form-control" name="txt_employee_permanentaddress"  id="txt_employee_permanentaddress" value={FormAddEmployee.txt_employee_permanentaddress}  onChange={InputChange}></textarea>
                        </div>
                        
                        
                      </div>
                    </div>
                  </fieldset>
                  <fieldset className="border  pb-2">
                    <legend className="float-none w-auto legendFontsize text-primary">
                      Personal Document Details:
                    </legend>
                    <div className="col-sm-12">
                      <div className="row">
                        <div className="col-sm-12 mb-1">
                          <label>Aadhaar Card No:</label>
                            <input type="text" className="form-control" name="txt_employee_aadhaarno" id="txt_employee_aadhaarno" value={FormAddEmployee.txt_employee_aadhaarno}  onChange={InputChange} ></input>
                        </div>
                        <div className="col-sm-12 mb-1">
                          <label>Pan Card No:</label>
                            <input type="text" className="form-control" name="txt_employee_panno" id="txt_employee_panno" value={FormAddEmployee.txt_employee_panno}  onChange={InputChange}></input>
                        </div>
                        <div className="col-sm-12 mb-1">
                          <label>Driving License No:</label>
                            <input type="text" className="form-control" name="txt_employee_drivinglicenseno" id="txt_employee_drivinglicenseno" value={FormAddEmployee.txt_employee_drivinglicenseno}  onChange={InputChange}></input>
                        </div>
                        
                        <div className="col-sm-12 mb-1">
                          <label>PF(Provident Fund) No:</label>
                            <input type="text" className="form-control" name="txt_employee_pfno" id="txt_employee_pfno" value={FormAddEmployee.txt_employee_pfno}  onChange={InputChange}></input>
                        </div>
                        <div className="col-sm-12 mb-1">
                          <label>ESI Code No:</label>
                            <input type="text" className="form-control" name="txt_employee_esicno" id="txt_employee_esicno" value={FormAddEmployee.txt_employee_esicno}  onChange={InputChange}></input>
                        </div>
                      
                       
                      </div>
                    </div>
                  </fieldset>
                  <fieldset className="border  pb-2">
                    <legend className="float-none w-auto legendFontsize text-primary">
                      Family Details:
                    </legend>
                    <div className="col-sm-12">
                      <div className="row">
                        <div className="col-sm-12 mb-1">
                          <label>Father Name:</label>
                            <input type="text" className="form-control" name="txt_employee_fathername" id="txt_employee_fathername"  value={FormAddEmployee.txt_employee_fathername}  onChange={InputChange}></input>
                        </div>
                        <div className="col-sm-12 mb-1">
                          <label>Mother Name:</label>
                            <input type="text" className="form-control" name="txt_employee_mothername" id="txt_employee_mothername"  value={FormAddEmployee.txt_employee_mothername}  onChange={InputChange}></input>
                        </div>
                        <div className="col-sm-12 mb-1">
                          <div className='row'>
                            <div className='col-sm-6'>
                            <label>Contact No:</label>
                          <input type="text" className="form-control" name="txt_employee_familycontactno" id="txt_employee_familycontactno"  value={FormAddEmployee.txt_employee_familycontactno}  onChange={InputChange}></input>
                            </div>
                            <div className='col-sm-6'>
                            <label>Alternate Contact No:</label>
                          <input type="text" className="form-control" name="txt_employee_familycontactno_alt" id="txt_employee_familycontactno_alt"  value={FormAddEmployee.txt_employee_familycontactno_alt}  onChange={InputChange}></input>
                              </div>
                          </div>
                        
                        </div>
                        <div className="col-sm-12 mb-1">
                        <label>Residence Address</label>
                          <textarea rows={3} className="form-control" name="txt_employee_familyaddress" id="txt_employee_familyaddress" value={FormAddEmployee.txt_employee_familyaddress}  onChange={InputChange}></textarea>
                        </div>
                      </div>
                    </div>
                  </fieldset>
                  <fieldset className="border  pb-2">
                    <legend className="float-none w-auto legendFontsize text-danger">
                      Emergency Contact Details:
                    </legend>
                    <div className="col-sm-12">
                      <div className="row">
                        <div className="col-sm-12 mb-1">
                          <label>Name:</label>
                            <input type="text" className="form-control" name="txt_employee_emer_contactname" id="txt_employee_emer_contactname" value={FormAddEmployee.txt_employee_emer_contactname}  onChange={InputChange}></input>
                        </div>
                        <div className="col-sm-12 mb-1">
                          <div className='row'>
                            <div className='col-sm-6'>
                            <label>Contact No:</label>
                          <input type="text" className="form-control" name="txt_employee_emer_contactno" id="txt_employee_emer_contactno" value={FormAddEmployee.txt_employee_emer_contactno}  onChange={InputChange}></input>
                            </div>
                            <div className='col-sm-6'>
                            <label>Alternate Contact No:</label>
                          <input type="text" className="form-control" name="txt_employee_emer_contactno_alt" id="txt_employee_emer_contactno_alt" value={FormAddEmployee.txt_employee_emer_contactno_alt}  onChange={InputChange}></input>
                              </div>
                          </div>
                        
                        </div>
                        <div className="col-sm-12 mb-1">
                        <label>Relation</label>
                        <input type="text" className="form-control" name="txt_employee_emer_contactrelation" id="txt_employee_emer_contactrelation" value={FormAddEmployee.txt_employee_emer_contactrelation}  onChange={InputChange}></input>
                        </div>
                      </div>
                    </div>
                  </fieldset>
                  <fieldset className="border  pb-2">
                    <legend className="float-none w-auto legendFontsize text-danger">
                      Other Details:
                    </legend>
                    <div className="col-sm-12">
                      <div className="row">
                        <div className="col-sm-12 mb-1">
                          <label>Note:</label>
                            <textarea rows={3} className="form-control" name="txt_employee_othernote" id="txt_employee_othernote" value={FormAddEmployee.txt_employee_othernote}  onChange={InputChange}></textarea>
                        </div>
                      </div>
                    </div>
                  </fieldset>
                  </div>
                  </div>
                </div>
            
              <div class="modal-footer mdlFooter">
                <button type="button" class="btn btn-secondary" data-bs-dismiss="modal" ref={closeAddEmployee_RB}>Close</button>
                
                {!BtnEmpSubmitLoader && <><button type="submit" class="btn btn-primary btnLoader" >
                Submit
              </button>
              </>
              }
              {BtnEmpSubmitLoader && <>
                <button class="btn btn-primary btnLoader" type="button" disabled="">
                                                        <span class="spinner-border spinner-border-sm me-1" role="status" aria-hidden="true"></span>
                                                        Loading...
                                                    </button>
              </>}
                
                
                </div>
              </form>
            </div>
            
          </div>
          {/* /.modal-content */}
        </div>
        {/* /.modal-dialog */}
      </div>
      {/* /.modal */}
    </>

  )
}