import React from 'react'
import { useEffect } from 'react'
import { Loader } from '../Include/Loader'
import { Link, useNavigate,useLocation  } from 'react-router-dom'

export const Logout = () => {
  let redirect = useNavigate()
  let location = useLocation();

   useEffect(() => {
    localStorage.removeItem('Smsc_UserAccess')
     setTimeout(() => {
      redirect(`${process.env.PUBLIC_URL}/login`);
     }, 3000);

   }, [location])
   
  return (
    <div>
         <div className='centerCss'>
        <Loader/>
        <h6>Signing Out...</h6>
        </div>
    </div>
  )
}
