import React, { useEffect, useState } from "react";
import { AddCompany_RB } from "./AddCompany_RB";
export const AddCompany = () => {
  const [CompanyListData, setCompanyListData] = useState();
  const [CompanyEditStatus, setCompanyEditStatus] = useState(0);
  const [CompanyEditData, setCompanyEditData] = useState();
  const [CompanyUpdate, setCompanyUpdate] = useState(false);
  useEffect(() => {
    GetCompanyList();
  }, [CompanyUpdate]);
  const GetCompanyList = async () => {
    fetch(`${window.endpoint}company/list`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then((response) => response.json())
      .then((data) => {
        console.log("Success:", data.status);
        if (data.status === 0 || data.status === "0") {
          setCompanyListData(data.data);
          console.log(data.data);
        }
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  };
  const btnEditCompany = (data) => {
    setCompanyEditStatus(1);
    setCompanyEditData(data);
  };
  const btnAddCompany = () => {
    setCompanyEditStatus(0);
  };
  return (
    <div> <div className='content-page'>
      <div className="content">
        {/* Start Content*/}
        <div className="container-fluid">
          {/* start page title */}
          <div className="row">
            <div className="col-12">
              <div className="page-title-box">
                <h4 className="page-title">Company List</h4>
                <div>
                  <ol className="breadcrumb m-0">
                    <li className="breadcrumb-item">
                      <a href="javascript: void(0);">{window.projectName}</a>
                    </li>
                    <li className="breadcrumb-item">
                      <a href="javascript: void(0);">Master</a>
                    </li>
                    <li className="breadcrumb-item active">Company</li>
                  </ol>
                </div>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-12">
              <div className="card">
                <div className="card-body">
                  <div className="row">
                    <div className="col-lg-10 col-md-7 col-sm-6">
                      <div className="row">
                        <div className="col-sm-3 mt-1">
                          <form className="text-lg-end">
                            <input
                              type="search"
                              className="form-control"
                              id="inputPassword2"
                              placeholder="Search..."
                            />
                          </form>
                        </div>
                        <div className="col-sm-5 mt-1">
                          <button
                            type="button"
                            className="btn btn-danger waves-effect waves-light mb-sm-0"
                          >
                            <i className="mdi mdi-download" /> Download Pdf
                          </button>
                          <button
                            type="button"
                            className="btn btn-success waves-effect waves-light mb-sm-0 ms-1"
                          >
                            <i className="mdi mdi-download" /> Download1 Xlsx
                          </button>
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-2 col-md-5 col-sm-6 mt-1">
                      <button
                        type="button"
                        className="btn btn-primary waves-effect waves-light mb-sm-0 mb-2 me-2 w-100 "
                        data-bs-toggle="modal"
                        data-bs-target="#Mdl_AddCompany_RB"
                        onClick={btnAddCompany}
                      >
                        <i className="mdi mdi-plus-circle" /> Company
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-12">
              <div className="card">
                <div className="card-body">
                  <div className="responsive-table-plugin">
                    <div className="table-rep-plugin">
                      <div
                        className="table-responsive"
                        data-pattern="priority-columns"
                      >
                        <table
                          id="tech-companies-1"
                          className="table table-striped table-full-width-td"
                        >
                          <thead>
                            <tr>
                              <th>Sr</th>
                              <th data-priority={1}>Company Name</th>
                              <th data-priority={1}>Owner Name</th>
                              <th data-priority={1}>Contact No</th>
                              <th data-priority={1}>Contact No Alternative </th>
                              <th data-priority={1}>City</th>
                              <th data-priority={1}>GST No.</th>
                              <th data-priority={1}>Address</th>
                              <th data-priority={3}>Action</th>
                            </tr>
                          </thead>
                          <tbody>
                            {CompanyListData &&
                              CompanyListData.map((data, index) => {
                                return (
                                  <tr key={index}>
                                    <td>{index + 1}</td>
                                    <th>{data.company_name.toUpperCase()}</th>
                                    <th>{data.company_owner_name}</th>
                                    <th>{data.company_contact_no}</th>
                                    <th>{data.company_contact_no_aly}</th>
                                    <th>{data.company_gstno}</th>
                                    <th>{data.company_city_name}</th>
                                    <th>{data.company_address}</th>
                                    <td>
                                      <span
                                        className="badge bg-danger"
                                        type="button"
                                        data-bs-toggle="modal"
                                        data-bs-target="#Mdl_AddCompany_RB"
                                        onClick={(e) => {
                                          btnEditCompany(data);
                                        }}
                                      >
                                        {" "}
                                        Edit
                                      </span>
                                    </td>
                                  </tr>
                                );
                              })}
                          </tbody>
                        </table>
                      </div>
                      {/* end .table-responsive */}
                    </div>
                    {/* end .table-rep-plugin*/}
                  </div>
                  {/* end .responsive-table-plugin*/}
                </div>
              </div>
              {/* end card */}
            </div>
            {/* end col */}
          </div>

          {/* end page title */}
        </div>
        {/* container */}
      </div>
      <AddCompany_RB
        CompanyUpdate={CompanyUpdate}
        setCompanyUpdate={setCompanyUpdate}
        CompanyEditStatus={CompanyEditStatus}
        CompanyEditData={CompanyEditData}
        Time={Date().toLocaleString()}
      />
      </div>
    </div>
  );
};
