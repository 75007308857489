import React, { useContext, useEffect, useRef, useState } from "react";
import Select from "react-select";
import LanguageContext from "../../../Include/Context/LanguageContext";
export const AddTripEntry_RB = ({
  TripEntryUpdate,
  setTripEntryUpdate,
  TripEntryEditStatus,
  TripEntryEditData,
  Time,
}) => {
  let closeAddTripEntry_RB = useRef();
  const [BtnTripEntryLoader, setBtnTripEntryLoader] = useState(false);
  const { setSnackBarData } = useContext(LanguageContext);
  const [TripEntryRouteListData, setTripEntryRouteListData] = useState([]);
  const [AddTripEntryFormData, setAddTripEntryFormData] = useState({
    txt_addtripEntry_id: "0",
    txt_addtripEntry_date: "",
    txt_addtripEntry_machinerytype:"",
    txt_addtripEntry_route: "",
    txt_addtripEntry_company_from: "",
    txt_addtripEntry_company_from_id: "",
    txt_addtripEntry_city_from: "",
    txt_addtripEntry_city_from_id: "",
    txt_addtripEntry_company_to: "",
    txt_addtripEntry_company_to_id: "",
    txt_addtripEntry_city_to: "",
    txt_addtripEntry_city_to_id: "",
    txt_addtripEntry_distance: "",
    txt_addtripEntry_fair: "",
    txt_addtripEntry_routedealwith: "",
    txt_addtripEntry_productitem: "",
    txt_addtripEntry_weight_dispatch:"",
    txt_addtripEntry_weight_recieved:"",
    txt_addtripEntry_vehicle:"",
    txt_addtripEntry_driver:"",
    txt_addtripEntry_challan_no:"",
    txt_addtripEntry_voucher_no:"",
    txt_addtripEntry_diesel:"",
    txt_addtripEntry_urea:"",
    txt_addtripEntry_incentive_amount:"",
    txt_addtripEntry_description:"",
    txt_addtripEntry_status: "0",
  });
  const AddTripEntryFormDataClear = () => {
    setAddTripEntryFormData({
      txt_addtripEntry_id: "0",
    txt_addtripEntry_date: "",
    txt_addtripEntry_machinerytype:"",
    txt_addtripEntry_route: "",
    txt_addtripEntry_company_from: "",
    txt_addtripEntry_company_from_id: "",
    txt_addtripEntry_city_from: "",
    txt_addtripEntry_city_from_id: "",
    txt_addtripEntry_company_to: "",
    txt_addtripEntry_company_to_id: "",
    txt_addtripEntry_city_to: "",
    txt_addtripEntry_city_to_id: "",
    txt_addtripEntry_distance: "",
    txt_addtripEntry_fair: "",
    txt_addtripEntry_routedealwith: "",
    txt_addtripEntry_productitem: "",
    txt_addtripEntry_weight_dispatch:"",
    txt_addtripEntry_weight_recieved:"",
    txt_addtripEntry_vehicle:"",
    txt_addtripEntry_driver:"",
    txt_addtripEntry_challan_no:"",
    txt_addtripEntry_voucher_no:"",
    txt_addtripEntry_diesel:"",
    txt_addtripEntry_urea:"",
    txt_addtripEntry_incentive_amount:"",
    txt_addtripEntry_description:"",
    txt_addtripEntry_status: "0",
    });
  };
  const AddTripEntryFormDataUpdate = (data) => {
    setAddTripEntryFormData({
      txt_addtripEntry_id:data.tripentry_id,
      txt_addtripEntry_date: data.trip_date,
      txt_addtripEntry_machinerytype:data.machinery_type,
      txt_addtripEntry_route: data.route_id,
      txt_addtripEntry_company_from: data.company_from_id_name,
      txt_addtripEntry_company_from_id: data.company_from_id,
      txt_addtripEntry_city_from: data.city_from_id_name,
      txt_addtripEntry_city_from_id: data.city_from_id,
      txt_addtripEntry_company_to: data.company_to_id_name,
      txt_addtripEntry_company_to_id: data.company_to_id,
      txt_addtripEntry_city_to:data.city_to_id_name,
      txt_addtripEntry_city_to_id:data.city_to_id,
      txt_addtripEntry_distance: data.route_distance,
      txt_addtripEntry_fair: data.route_fair,
      txt_addtripEntry_routedealwith: data.company_deal_with_id,
      txt_addtripEntry_productitem: data.product_id,
      txt_addtripEntry_weight_dispatch:data.product_dispatch_weight,
      txt_addtripEntry_weight_recieved:data.product_recieved_weight,
      txt_addtripEntry_vehicle:data.vehicle_machinery_id,
      txt_addtripEntry_driver:data.driver_employee_id,
      txt_addtripEntry_challan_no:data.challan_no,
      txt_addtripEntry_voucher_no:data.voucher_no,
      txt_addtripEntry_diesel:data.diesel_in_ton,
      txt_addtripEntry_urea:data.urea_in_ton,
      txt_addtripEntry_incentive_amount:data.driver_employee_incentive,
      txt_addtripEntry_description:data.other_description,
      txt_addtripEntry_status: data.status,
    });
  };
  useEffect(() => {
    // return () => {
    if (TripEntryEditStatus === 0) {
      AddTripEntryFormDataClear();
    }
    if (TripEntryEditStatus === 1) {
      console.log(TripEntryEditData);
      AddTripEntryFormDataUpdate(TripEntryEditData);
    }
  }, [Time]);
  const InputChange = (e) => {
    console.log(e.target.value)
    setAddTripEntryFormData({
      ...AddTripEntryFormData,
      [e.target.name]: e.target.value,
    });
  };
  const handleTripEntryRoute = (e) => {
    console.log(e);
    setAddTripEntryFormData({
      ...AddTripEntryFormData,
      txt_addtripEntry_route: e.value,
      txt_addtripEntry_company_from: e.route_company_from_name,
      txt_addtripEntry_company_from_id:e.route_company_from,
      txt_addtripEntry_city_from: e.route_from_name,
      txt_addtripEntry_city_from_id: e.route_from,
      txt_addtripEntry_company_to: e.route_company_to_name,
      txt_addtripEntry_company_to_id:e.route_company_to,
      txt_addtripEntry_city_to: e.route_to_name,
      txt_addtripEntry_city_to_id: e.route_to,
      txt_addtripEntry_distance: e.route_distance,
      txt_addtripEntry_fair: e.route_fair
    });
  };
  const handleTripEntryDealWith = (e) => {
   console.log(e);
    setAddTripEntryFormData({
      ...AddTripEntryFormData,
      txt_addtripEntry_routedealwith:e.value
     
    });
  }
  const handleTripEntryItem = (e) => {
    console.log(e);
    setAddTripEntryFormData({
      ...AddTripEntryFormData,
      txt_addtripEntry_productitem:e.value
     
    });
  }
  const handleTripEntryVehicle = (e) => {
    console.log(e);
    setAddTripEntryFormData({
      ...AddTripEntryFormData,
      txt_addtripEntry_vehicle:e.value
     
    });
  }
  const handleTripEntryDriver = (e) => {
    console.log(e);
    setAddTripEntryFormData({
      ...AddTripEntryFormData,
      txt_addtripEntry_driver: e.value
     
    });
  }

  useEffect(() => {
    GetTripEntryRouteList();
  }, []);

  const GetTripEntryRouteList = async () => {
    fetch(`${window.endpoint}route/list`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then((response) => response.json())
      .then((data) => {
        console.log("Success:", data.data);
        if (data.status === 0 || data.status === "0") {
          if (data.status === 0 || data.status === "0") {
            // console.log('------',data.data);
            var i = 1;
            let RouteCompanyOptionData = [
              {
                label: "Select Route",
                value: "",
                route_name:"",
                route_company_from: "",
                route_company_from_name: "",
                route_from: "",
                route_from_name: "",
                route_company_to: "",
                route_company_to_name: "",
                route_to: "",
                route_to_name: "",
              },
            ];
            data.data.forEach((RouteData) => {
              let companyOption = {
                label:
                  RouteData.route_name +
                  "(" +
                  RouteData.route_from_name +
                  "-" +
                  RouteData.route_to_name +
                  ")",
                value: RouteData.route_id,
                route_name:RouteData.route_name,
                route_company_from: RouteData.route_company_from,
                route_company_from_name: RouteData.route_company_from_name,
                route_from: RouteData.route_from,
                route_from_name: RouteData.route_from_name,
                route_company_to: RouteData.route_company_to,
                route_company_to_name: RouteData.route_company_to_name,
                route_to: RouteData.route_to,
                route_to_name: RouteData.route_to_name,
                route_distance:RouteData.route_distance,
                route_fair:RouteData.route_fair
              };
              RouteCompanyOptionData.push(companyOption);
              console.log(i);
              i++;
            });
            // console.log(CityOptionData);
            setTripEntryRouteListData(RouteCompanyOptionData);
          }
        }
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  };
  const frmAddTripEntry = (e) => {
    e.preventDefault();
    setBtnTripEntryLoader(true);

    fetch(`${window.endpoint}tripentry/add`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(AddTripEntryFormData),
    })
      .then((response) => response.json())
      .then((data) => {
        console.log("Success:", data.data.status);
        if (data.data.status === 0 || data.data.status === "0") {
          TripEntryUpdate
            ? setTripEntryUpdate(false)
            : setTripEntryUpdate(true);
          closeAddTripEntry_RB.current.click();
          setSnackBarData({
            alert: "success",
            message: data.data.message,
            open: true,
          });
        }
        setBtnTripEntryLoader(false);
      })
      .catch((error) => {
        console.error("Error:", error);
        setBtnTripEntryLoader(false);
      });
  };
  useEffect(() => {
    GetTripEntryCompanyList();
    GetTripEntryProductList();
    GetTripEntryEmployeeList();
    GetTripEntryMachineryList();
  }, []);
  const [TripEntryCompanyListData, setTripEntryCompanyListData] = useState();
  const [TripEntryProductListData, setTripEntryProductListData] = useState();
  const [TripEntryEmployeeListData, setTripEntryEmployeeListData] = useState();
  const [TripEntryMachineryListData, setTripEntryMachineryListData] = useState();
  const GetTripEntryCompanyList = async () => {
    fetch(`${window.endpoint}company/list`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then((response) => response.json())
      .then((data) => {
        console.log("Success:", data.status);
        if (data.status === 0 || data.status === "0") {
          let TripEntryCompanyOptionData = [{
            label: "Select Company",
            value: "",
            cityName: "",
            cityId: "0"
           }];
            data.data.forEach((CompanyData)=>{
              let companyOption = {
                 label: CompanyData.company_name+'-'+CompanyData.company_city_name,
                 value: CompanyData.company_id,
                 cityName: CompanyData.company_city_name,
                 cityId: CompanyData.company_city
                }
                TripEntryCompanyOptionData.push(companyOption)
             
            })
           
            setTripEntryCompanyListData(TripEntryCompanyOptionData)
       
        }
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  };
  const GetTripEntryProductList = async () => {
    fetch(`${window.endpoint}product/list`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then((response) => response.json())
      .then((data) => {
        console.log("Success:", data.status);
        if (data.status === 0 || data.status === "0") {
          let TripEntryProductOptionData = [{
            label: "Select Product",
            value: "",
          
           }];
            data.data.forEach((ProductData)=>{
               let ProductOptionData = {
                 label: ProductData.product_name,
                 value: ProductData.product_id,
                
                }
                TripEntryProductOptionData.push(ProductOptionData)
             
            })
            setTripEntryProductListData(TripEntryProductOptionData)
        
        }
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  };
  const GetTripEntryEmployeeList = async () => {
    fetch(`${window.endpoint}employee/list`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then((response) => response.json())
      .then((data) => {
        console.log("Success:", data.status);
        if (data.status === 0 || data.status === "0") {
          let TripEntryEmployeeOptionData = [{
            label: "Select Driver",
            value: "",
          
           }];
            data.data.forEach((EmployeeData)=>{
             let emplType =  EmployeeData.employee_position==="1"?'Truck Driver':EmployeeData.employee_position==="2"?'Hywa Driver':EmployeeData.employee_position==="3"?'Machine Operator':EmployeeData.employee_position==="4"?'Helper':EmployeeData.employee_position==="5"?'Mechanic':EmployeeData.employee_position==="6"?'Super Wiser':EmployeeData.employee_position==="7"?'Manager':EmployeeData.employee_position==="8"?'Other':'-';
               let EmployeeOptionData = {
                 label: EmployeeData.employee_name+'-'+emplType+'/'+EmployeeData.employee_contactno,
                 value: EmployeeData.employee_id,
                
                }
                TripEntryEmployeeOptionData.push(EmployeeOptionData)
             
            })
            setTripEntryEmployeeListData(TripEntryEmployeeOptionData)
        }
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  };
  const GetTripEntryMachineryList = async () => {
    fetch(`${window.endpoint}machinery/list`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then((response) => response.json())
      .then((data) => {
        console.log("Success:", data.status);
        if (data.status === 0 || data.status === "0") {
          
          let TripEntryMachineryOptionData = [{
            label: "Select Vehicle",
            value: "",
          
           }];
            data.data.forEach((MachineryData)=>{
              let MachineryType = MachineryData.machinery_type==="1"?'TRUCK':MachineryData.machinery_type==="2"?'HYWA':MachineryData.machinery_type==="3"?'EQUIPMENT':'-';
               let MachineryOptionData = {
                 label: MachineryData.machinery_brand+'-'+MachineryData.machinery_rc_no+'/'+MachineryType,
                 value: MachineryData.machinery_id,
                
                }
                TripEntryMachineryOptionData.push(MachineryOptionData)
             
            })
            setTripEntryMachineryListData(TripEntryMachineryOptionData)
        
        }
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  };
  return (
    <>
      {/* Right modal content */}
      <div
        id="Mdl_AddTripEntry_RB"
        className="modal fade"
        tabIndex={-1}
        role="dialog"
        aria-hidden="true"
      >
        <div
          className="modal-dialog modal-md modal-right"
          style={{ maxWidth: "500px", width: "100%" }}
        >
          <div className="modal-content  h-100">
            <h6 className="fw-semibold px-3 m-0 py-2 font-16 bg-light">
              <span className="d-block py-2">ADD TRIPS</span>
            </h6>
            <div className="modal-header p-0 border-0">
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              />
            </div>

            <div className="modal-body">
              <form
                id="frm_AddTripEntry"
                onSubmit={(e) => {
                  frmAddTripEntry(e);
                }}
              >
                <div className="row ms-1 me-1">
                <div className="col-sm-12">
                  <div className="row">
                  <fieldset className="border  pb-2">
                    <legend className="float-none w-auto legendFontsize text-primary">
                      Trip Details:
                    </legend>
                    <div className="col-sm-12">
                      <div className="row">
                    <div className="col-sm-12 mb-1">
                      <label>Trip Date</label>
                      <input
                        type="date"
                        name="txt_addtripEntry_date"
                        id="txt_addtripEntry_date"
                        value={AddTripEntryFormData.txt_addtripEntry_date}
                        className="form-control "
                        placeholder="Enter TripEntry Date"
                        onChange={InputChange}
                        required
                      />
                    </div>
                    <div className="col-sm-12 col-12 mb-1">
                          <label className="mb-1">Machinery Type</label>
                          <div className="row">
                            <div className="col-sm-4">
                            <div className="form-check ">
                          <input className="form-check-input" type="radio" name="txt_addtripEntry_machinerytype" id="txt_addtripEntry_machinerytype1" value="1"  onChange={InputChange} checked={(AddTripEntryFormData.txt_addtripEntry_machinerytype==="1" || AddTripEntryFormData.txt_addtripEntry_machinerytype===1)?true:false}/>
                          <label className="form-check-label" for="txt_addtripEntry_machinerytype1">Truck</label>
                      </div>
                            </div>
                            <div className="col-sm-4">
                            <div className="form-check  form-check-success">
                          <input className="form-check-input" type="radio" name="txt_addtripEntry_machinerytype" id="txt_addtripEntry_machinerytype2" value="2"  onChange={InputChange} checked={(AddTripEntryFormData.txt_addtripEntry_machinerytype==="2" || AddTripEntryFormData.txt_addtripEntry_machinerytype===2)?true:false}/>
                          <label className="form-check-label" for="txt_addtripEntry_machinerytype2">Hywa</label>
                      </div>
                            </div>
                            <div className="col-sm-4">
                            <div className="form-check  form-check-warning">
                          <input className="form-check-input" type="radio" name="txt_addtripEntry_machinerytype" id="txt_addtripEntry_machinerytype3" value="3"  onChange={InputChange} checked={(AddTripEntryFormData.txt_addtripEntry_machinerytype==="3" || AddTripEntryFormData.txt_addtripEntry_machinerytype===3)?true:false}/>
                          <label className="form-check-label" for="txt_addtripEntry_machinerytype3">Equipment</label>
                      </div>
                            </div>
                          </div>
                    

                     
                     
                        </div>
                    </div>
                    <div className="col-sm-12 mb-1">
                      <label>Vehicle</label>
                      <Select
                           options={TripEntryMachineryListData}
                           className="text-capitalize"
                           name="txt_addtripEntry_vehicle"
                           id="txt_addtripEntry_vehicle"
                           placeholder="Select Vechicle"
                           onChange={(e) => {
                            handleTripEntryVehicle(e);
                          }}
                          value={
                            TripEntryMachineryListData &&
                            TripEntryMachineryListData.filter(
                              (option) =>
                                option.value ===
                                AddTripEntryFormData.txt_addtripEntry_vehicle
                            )
                          }
                         />
                    
                    </div>
                    <div className="col-sm-12 mb-1">
                      <label>Driver</label>
                      <Select
                            options={TripEntryEmployeeListData}
                           className="text-capitalize"
                           name="txt_addtripEntry_driver"
                           id="txt_addtripEntry_driver"
                           placeholder="Select Driver"
                           onChange={(e) => {
                            handleTripEntryDriver(e);
                          }}
                          value={
                            TripEntryEmployeeListData &&
                            TripEntryEmployeeListData.filter(
                              (option) =>
                                option.value ===
                                AddTripEntryFormData.txt_addtripEntry_driver
                            )
                          }
                         />
                     
                    </div> 
                    </div>
                    </fieldset>
                  <fieldset className="border  pb-2">
                    <legend className="float-none w-auto legendFontsize text-primary">
                      Route Details:
                    </legend>
                    <div className="col-sm-12">
                      <div className="row">
                    
                    <div className="col-sm-12 mb-1">
                   
                      <div className="row">
                        <div className="col-sm-12 col-12 mb-1">
                          <label>Route</label>
                          <Select
                            options={TripEntryRouteListData}
                            className="text-capitalize"
                            name="txt_addtripEntry_route"
                            id="txt_addtripEntry_route"
                            placeholder="Select Route Name"
                            onChange={(e) => {
                              handleTripEntryRoute(e);
                            }}
                            value={
                              TripEntryRouteListData &&
                              TripEntryRouteListData.filter(
                                (option) =>
                                  option.value ===
                                  AddTripEntryFormData.txt_addtripEntry_route
                              )
                            }
                          />
                        </div>
                        <div className="col-sm-8  col-12">
                          <label>Company From</label>
                          <input
                            type="text"
                            name="txt_addtripEntry_company_from"
                            id="txt_addtripEntry_company_from"
                            value={
                              AddTripEntryFormData.txt_addtripEntry_company_from
                            }
                            className="form-control "
                            required
                            readOnly
                          />
                        </div>
                        <div className="col-sm-4 col-12 ">
                          <label>Route</label>
                          <input
                            type="text"
                            name="txt_addtripEntry_city_from"
                            id="txt_addtripEntry_city_from"
                            value={
                              AddTripEntryFormData.txt_addtripEntry_city_from
                            }
                            className="form-control "
                            required
                            readOnly
                          />
                        </div>
                      </div>
                    </div>
                    <div className="col-sm-12 mb-1">
                      <div className="row">
                        <div className="col-sm-8  col-12">
                          <label>Company To</label>
                          <input
                            type="text"
                            name="txt_addtripEntry_company_to"
                            id="txt_addtripEntry_company_to"
                            value={
                              AddTripEntryFormData.txt_addtripEntry_company_to
                            }
                            className="form-control "
                            required
                            readOnly
                          />
                        </div>
                        <div className="col-sm-4 col-12 ">
                          <label>Route To</label>
                          <input
                            type="text"
                            name="txt_addtripEntry_city_to"
                            id="txt_addtripEntry_city_to"
                            value={
                              AddTripEntryFormData.txt_addtripEntry_city_to
                            }
                            className="form-control "
                            required
                            readOnly
                          />
                        </div>
                      </div>
                    </div>
                    

                    <div className="col-sm-12 mb-1">
                      <div className="row">
                        <div className="col-sm-12 col-xs-12 col-12 mb-1">
                          <label>Route Distance</label>
                          <input
                            type="text"
                            name="txt_addtripEntry_distance"
                            id="txt_addtripEntry_distance"
                            value={
                              AddTripEntryFormData.txt_addtripEntry_distance
                            }
                            className="form-control"
                            required
                            readOnly
                          ></input>
                        </div>
                        <div className="col-sm-12 col-xs-12 col-12 mb-1">
                          <label>Route Fair</label>
                          <input
                            type="tel"
                            name="txt_addtripEntry_fair"
                            id="txt_addtripEntry_fair"
                            value={AddTripEntryFormData.txt_addtripEntry_fair}
                            className="form-control "
                            placeholder="Enter Route Fair"
                            onChange={InputChange}
                            required
                          
                          />
                        </div>
                        <div className="col-sm-12 col-12 mb-1">
                          <label>Deal With</label>
                          <Select
                            options={TripEntryCompanyListData}
                            className="text-capitalize"
                            name="txt_addtripEntry_routedealwith"
                            id="txt_addtripEntry_routedealwith"
                            placeholder="Select Company"
                            onChange={(e) => {
                              handleTripEntryDealWith(e);
                            }}
                            value={
                              TripEntryCompanyListData &&
                              TripEntryCompanyListData.filter(
                                (option) =>
                                  option.value ===
                                  AddTripEntryFormData.txt_addtripEntry_routedealwith
                              )
                            }
                          />
                        </div>
                      </div>
                    </div>
                    </div>
                    </div>
                    </fieldset>
                    <fieldset className="border  pb-2">
                    <legend className="float-none w-auto legendFontsize text-primary">
                      Product Details:
                    </legend>
                   
                   
                    <div className="col-sm-12">
                      <div className="row">
                        <div className="col-sm-12 col-12 mb-1">
                          <label>Item</label>
                          <Select
                            options={TripEntryProductListData}
                            className="text-capitalize"
                            name="txt_addtripEntry_productitem"
                            id="txt_addtripEntry_productitem"
                            placeholder="Select Product"
                            onChange={(e) => {
                              handleTripEntryItem(e);
                            }}
                            value={
                              TripEntryProductListData &&
                              TripEntryProductListData.filter(
                                (option) =>
                                  option.value ===
                                  AddTripEntryFormData.txt_addtripEntry_productitem
                              )
                            }
                          />
                        </div>
                      </div>
                    </div>
                    <div className="col-sm-12 mb-1">
                      <div className="row">
                        <div className="col-sm-6  col-6">
                          <label>Dispatch Weight</label>
                          <div className="input-group">
                          <input
                            type="text"
                            name="txt_addtripEntry_weight_dispatch"
                            id="txt_addtripEntry_weight_dispatch"
                            value={AddTripEntryFormData.txt_addtripEntry_weight_dispatch}
                            className="form-control "
                            onChange={InputChange}
                            required
                          />
                         <div class="input-group-text">Tons</div>
                          </div>
                        </div>
                        <div className="col-sm-6 col-6 ">
                          <label>Recieved Weight</label>
                          <div className="input-group">
                          <input
                            type="text"
                            name="txt_addtripEntry_weight_recieved"
                            id="txt_addtripEntry_weight_recieved"
                            value={
                              AddTripEntryFormData.txt_addtripEntry_weight_recieved
                            }
                            className="form-control "
                            onChange={InputChange}
                            required
                          />
                           <div class="input-group-text">Tons</div>
                          </div>
                        </div>
                      </div>
                    </div>
                    </fieldset>
                    <fieldset className="border  pb-2">
                    <legend className="float-none w-auto legendFontsize text-primary">
                    Vehicle Details:
                    </legend>
                   
                   
                    <div className="col-sm-12">
                      <div className="row">
                        
                      
                    <div className="col-sm-12 mb-1">
                      <div className="row">
                        <div className="col-sm-6  col-6">
                          <label>Challan No</label>
                          <input
                            type="text"
                            name="txt_addtripEntry_challan_no"
                            id="txt_addtripEntry_challan_no"
                            value={
                              AddTripEntryFormData.txt_addtripEntry_challan_no
                            }
                            className="form-control "
                            onChange={InputChange}
                            
                          />
                        </div>
                        <div className="col-sm-6 col-6 ">
                          <label>Voucher No.</label>
                          <input
                            type="text"
                            name="txt_addtripEntry_voucher_no"
                            id="txt_addtripEntry_voucher_no"
                            value={
                              AddTripEntryFormData.txt_addtripEntry_voucher_no
                            }
                            className="form-control "
                            onChange={InputChange}
                            
                          />
                        </div>
                      </div>
                    </div>
                    <div className="col-sm-12 mb-1">
                      <div className="row">
                        <div className="col-sm-6  col-6">
                          <label>Diesel</label>
                          <div className="input-group">
                          <input
                            type="text"
                            name="txt_addtripEntry_diesel"
                            id="txt_addtripEntry_diesel"
                            value={AddTripEntryFormData.txt_addtripEntry_diesel}
                            className="form-control "
                            onChange={InputChange}
                            
                          />
                         <div class="input-group-text">Ltr's</div>
                          </div>
                        </div>
                        <div className="col-sm-6 col-6 ">
                          <label>Urea</label>
                          <div className="input-group">
                          <input
                            type="text"
                            name="txt_addtripEntry_urea"
                            id="txt_addtripEntry_urea"
                            value={
                              AddTripEntryFormData.txt_addtripEntry_urea
                            }
                            className="form-control "
                            onChange={InputChange}
                            
                          />
                           <div class="input-group-text">Ltr's</div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-sm-12 col-12 mb-1">
                      <label>Incentive Amount</label>
                      <input
                        type="text"
                        name="txt_addtripEntry_incentive_amount"
                        id="txt_addtripEntry_incentive_amount"
                        value={
                          AddTripEntryFormData.txt_addtripEntry_incentive_amount
                        }
                        className="form-control "
                        onChange={InputChange}
                        
                      />
                    </div>
                      </div>
                    </div>
                   
                    </fieldset>
                

                  
                    <fieldset className="border  pb-2">
                    <legend className="float-none w-auto legendFontsize text-primary">
                      Other Details:
                    </legend>

                    <div className="col-sm-12">
                      <label>Description</label>
                      <textarea
                        type="text"
                        name="txt_addtripEntry_description"
                        id="txt_addtripEntry_description"
                        value={
                          AddTripEntryFormData.txt_addtripEntry_description
                        }
                        className="form-control "
                        onChange={InputChange}
                        
                        rows={3}
                      ></textarea>
                    </div>
                    </fieldset>
                  </div>
                </div>
                </div>
                <div className="modal-footer mdlFooter">
                  <button
                    type="button"
                    className="btn btn-secondary"
                    data-bs-dismiss="modal"
                    ref={closeAddTripEntry_RB}
                  >
                    Close
                  </button>
                  {!BtnTripEntryLoader && (
                    <>
                      <button
                        type="submit"
                        className="btn btn-primary btnLoader"
                      >
                        Submit
                      </button>
                    </>
                  )}
                  {BtnTripEntryLoader && (
                    <>
                      <button
                        className="btn btn-primary btnLoader"
                        type="button"
                        disabled=""
                      >
                        <span
                          className="spinner-border spinner-border-sm me-1"
                          role="status"
                          aria-hidden="true"
                        ></span>
                        Loading...
                      </button>
                    </>
                  )}
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
