import React, { useEffect, useState } from 'react'
import { Link, useLocation } from 'react-router-dom'

export const LeftSidebar = () => {
  const [LeftbarStatus, setLeftbarStatus] = useState(true)
  let location =useLocation();
  
  // useEffect(() => {
  //   console.log(location.pathname)
  //   if(location.pathname==="/login"){
  //     console.log('i am login')
  //     setLeftbarStatus(false)
  //   }else{
  //     console.log('i am not login');
  //     setLeftbarStatus(true)
  //   }
  //  }, [location])
  return (
    <div> {LeftbarStatus && 
        <div className="left-side-menu">
  <div className="h-100" data-simplebar="">
    {/*- Sidemenu */}
    <div id="sidebar-menu">
      <ul id="side-menu">
        <li className="menu-title">Navigation</li>
        <li>
          <Link to={`${process.env.PUBLIC_URL}/dashboard`}> 
            <span className="badge bg-success float-end">7</span>
            <i className="fe-airplay" />
            <span> Dashboard </span>
            </Link>
        </li>
        <li className="menu-title mt-2">Trip Entry</li>
        <li>
          <Link to={`${process.env.PUBLIC_URL}/trips-entry`}>
            <i className="mdi mdi-slot-machine-outline" />
            <span>Trips Entry </span>
          </Link>
        </li>
        <li>
          <Link to={`${process.env.PUBLIC_URL}/equipment-entry`}>
            <i className="mdi mdi-slot-machine-outline" />
            <span>Equipment Entry </span>
          </Link>
        </li>
       
        <li className="menu-title mt-2">Master</li>
        <li>
          <Link to={`${process.env.PUBLIC_URL}/employee`}>
            <i className=" dripicons-user-group" />
            <span> Employee </span>
          </Link>
        </li>
        <li>
          <Link to={`${process.env.PUBLIC_URL}/machinery`}>
            <i className="mdi mdi-rv-truck"/>
            <span> Machinery </span>
          </Link>
        </li>
        <li>
          <Link to={`${process.env.PUBLIC_URL}/product`}>
            <i className=" dripicons-duplicate" />
            <span> Product </span>
          </Link>
        </li>
        <li>
          <Link to={`${process.env.PUBLIC_URL}/company`}>
            <i className="mdi mdi-city-variant-outline" />
            <span> Company </span>
          </Link>
        </li>
        <li>
          <Link to={`${process.env.PUBLIC_URL}/city`}>
            <i className="mdi mdi-city-variant-outline" />
            <span> City </span>
          </Link>
        </li>
        <li>
          <Link to={`${process.env.PUBLIC_URL}/route`}>
            <i className="mdi mdi-routes" />
            <span> Route </span>
          </Link>
        </li> 
        <li className="menu-title mt-2">User Management</li>
        <li>
          <Link to={`${process.env.PUBLIC_URL}/users`}>
            <i className="fe-users" />
            <span> Users </span>
          </Link>
        </li>
        <li>
          <Link to={`${process.env.PUBLIC_URL}/roles`}>
            <i className="mdi mdi-application-settings" />
            <span> Roles </span>
          </Link>
        </li>
        <li className="menu-title mt-2">Reports</li>
        <li>
          <Link to={`${process.env.PUBLIC_URL}/auditlogs`}>
            <i className="fe-users" />
            <span>Audit Logs </span>
          </Link>
        </li>
        {/* <li>
          <a href="#sidebarAuth" data-bs-toggle="collapse">
            <i className="fe-shield" />
            <span> Auth Pages </span>
            <span className="menu-arrow" />
          </a>
          <div className="collapse" id="sidebarAuth">
            <ul className="nav-second-level">
              <li>
                <a href="auth-login.html">Log In</a>
              </li>
              <li>
                <a href="auth-register.html">Register</a>
              </li>
              <li>
                <a href="auth-recoverpw.html">Recover Password</a>
              </li>
              <li>
                <a href="auth-lock-screen.html">Lock Screen</a>
              </li>
              <li>
                <a href="auth-logout.html">Logout</a>
              </li>
              <li>
                <a href="auth-confirm-mail.html">Confirm Mail</a>
              </li>
              <li>
                <a href="auth-404.html">Error 404</a>
              </li>
              <li>
                <a href="auth-404-alt.html">Error 404-alt</a>
              </li>
              <li>
                <a href="auth-500.html">Error 500</a>
              </li>
            </ul>
          </div>
        </li>
        <li>
          <a href="#sidebarExpages" data-bs-toggle="collapse">
            <i className="fe-book-open" />
            <span> Extra Pages </span>
            <span className="menu-arrow" />
          </a>
          <div className="collapse" id="sidebarExpages">
            <ul className="nav-second-level">
              <li>
                <a href="pages-starter.html">Starter</a>
              </li>
              <li>
                <a href="pages-timeline.html">Timeline</a>
              </li>
              <li>
                <a href="pages-profile.html">Profile</a>
              </li>
              <li>
                <a href="pages-aboutus.html">About Us</a>
              </li>
              <li>
                <a href="pages-contactus.html">Contact Us</a>
              </li>
              <li>
                <a href="pages-faqs.html">FAQs</a>
              </li>
              <li>
                <a href="pages-pricing.html">Pricing</a>
              </li>
              <li>
                <a href="pages-maintenance.html">Maintenance</a>
              </li>
              <li>
                <a href="pages-coming-soon.html">Coming Soon</a>
              </li>
              <li>
                <a href="pages-invoice.html">Invoice</a>
              </li>
            </ul>
          </div>
        </li>
        <li>
          <a href="#sidebarLayouts" data-bs-toggle="collapse">
            <i className="fe-layout" />
            <span className="badge bg-soft-danger text-danger float-end">
              New
            </span>
            <span> Layouts </span>
          </a>
          <div className="collapse" id="sidebarLayouts">
            <ul className="nav-second-level">
              <li>
                <a href="layouts-horizontal.html">Horizontal</a>
              </li>
              <li>
                <a href="layouts-detached.html">Detached</a>
              </li>
            </ul>
          </div>
        </li>
        <li className="menu-title mt-2">Components</li>
        <li>
          <a href="#sidebarUiKit" data-bs-toggle="collapse">
            <i className="fe-briefcase" />
            <span> UI Kit </span>
            <span className="menu-arrow" />
          </a>
          <div className="collapse" id="sidebarUiKit">
            <ul className="nav-second-level">
              <li>
                <a href="ui-buttons.html">Buttons</a>
              </li>
              <li>
                <a href="ui-cards.html">Cards</a>
              </li>
              <li>
                <a href="ui-avatars.html">Avatars</a>
              </li>
              <li>
                <a href="ui-tabs-accordions.html">Tabs &amp; Accordions</a>
              </li>
              <li>
                <a href="ui-modals.html">Modals</a>
              </li>
              <li>
                <a href="ui-progress.html">Progress</a>
              </li>
              <li>
                <a href="ui-notifications.html">Notifications</a>
              </li>
              <li>
                <a href="ui-offcanvas.html">Offcanvas</a>
              </li>
              <li>
                <a href="ui-placeholders.html">Placeholders</a>
              </li>
              <li>
                <a href="ui-spinners.html">Spinners</a>
              </li>
              <li>
                <a href="ui-carousel.html">Carousel</a>
              </li>
              <li>
                <a href="ui-list-group.html">List Group</a>
              </li>
              <li>
                <a href="ui-video.html">Embed Video</a>
              </li>
              <li>
                <a href="ui-dropdowns.html">Dropdowns</a>
              </li>
              <li>
                <a href="ui-general.html">General UI</a>
              </li>
              <li>
                <a href="ui-typography.html">Typography</a>
              </li>
              <li>
                <a href="ui-grid.html">Grid</a>
              </li>
            </ul>
          </div>
        </li>
        <li>
          <a href="#sidebarExtendedui" data-bs-toggle="collapse">
            <i className="fe-target" />
            <span className="badge bg-info float-end">Hot</span>
            <span> Extended UI </span>
          </a>
          <div className="collapse" id="sidebarExtendedui">
            <ul className="nav-second-level">
              <li>
                <a href="extended-nestable.html">Nestable List</a>
              </li>
              <li>
                <a href="extended-range-slider.html">Range Slider</a>
              </li>
              <li>
                <a href="extended-sweet-alert.html">Sweet Alert</a>
              </li>
              <li>
                <a href="extended-tour.html">Tour Page</a>
              </li>
              <li>
                <a href="extended-scrollspy.html">Scrollspy</a>
              </li>
            </ul>
          </div>
        </li>
        <li>
          <a href="widgets.html">
            <i className="fe-gift" />
            <span> Widgets </span>
          </a>
        </li>
        <li>
          <a href="#sidebarIcons" data-bs-toggle="collapse">
            <i className="fe-box" />
            <span> Icons </span>
            <span className="menu-arrow" />
          </a>
          <div className="collapse" id="sidebarIcons">
            <ul className="nav-second-level">
              <li>
                <a href="icons-two-tone.html">Two Tone</a>
              </li>
              <li>
                <a href="icons-colored.html">Colored</a>
              </li>
              <li>
                <a href="icons-feather.html">Feather</a>
              </li>
              <li>
                <a href="icons-mdi.html">Material Design</a>
              </li>
              <li>
                <a href="icons-dripicons.html">Dripicons</a>
              </li>
              <li>
                <a href="icons-font-awesome.html">Font Awesome 5</a>
              </li>
            </ul>
          </div>
        </li>
        <li>
          <a href="#sidebarForms" data-bs-toggle="collapse">
            <i className="fe-disc" />
            <span> Forms </span>
            <span className="menu-arrow" />
          </a>
          <div className="collapse" id="sidebarForms">
            <ul className="nav-second-level">
              <li>
                <a href="forms-elements.html">General Elements</a>
              </li>
              <li>
                <a href="forms-advanced.html">Advanced</a>
              </li>
              <li>
                <a href="forms-validation.html">Validation</a>
              </li>
              <li>
                <a href="forms-pickers.html">Pickers</a>
              </li>
              <li>
                <a href="forms-wizard.html">Wizard</a>
              </li>
              <li>
                <a href="forms-masks.html">Masks</a>
              </li>
              <li>
                <a href="forms-quilljs.html">Quilljs Editor</a>
              </li>
              <li>
                <a href="forms-file-uploads.html">File Uploads</a>
              </li>
            </ul>
          </div>
        </li>
        <li>
          <a href="#sidebarTables" data-bs-toggle="collapse">
            <i className="fe-grid" />
            <span> Tables </span>
            <span className="menu-arrow" />
          </a>
          <div className="collapse" id="sidebarTables">
            <ul className="nav-second-level">
              <li>
                <a href="tables-basic.html">Basic Tables</a>
              </li>
              <li>
                <a href="tables-datatables.html">Data Tables</a>
              </li>
              <li>
                <a href="tables-editable.html">Editable Tables</a>
              </li>
              <li>
                <a href="tables-responsive.html">Responsive Tables</a>
              </li>
              <li>
                <a href="tables-tablesaw.html">Tablesaw Tables</a>
              </li>
            </ul>
          </div>
        </li>
        <li>
          <a href="#sidebarCharts" data-bs-toggle="collapse">
            <i className="fe-bar-chart-line-" />
            <span> Charts </span>
            <span className="menu-arrow" />
          </a>
          <div className="collapse" id="sidebarCharts">
            <ul className="nav-second-level">
              <li>
                <a href="charts-apex.html">Apex Charts</a>
              </li>
              <li>
                <a href="charts-flot.html">Flot Charts</a>
              </li>
              <li>
                <a href="charts-morris.html">Morris Charts</a>
              </li>
              <li>
                <a href="charts-chartjs.html">Chartjs Charts</a>
              </li>
              <li>
                <a href="charts-peity.html">Peity Charts</a>
              </li>
              <li>
                <a href="charts-chartist.html">Chartist Charts</a>
              </li>
              <li>
                <a href="charts-c3.html">C3 Charts</a>
              </li>
              <li>
                <a href="charts-sparklines.html">Sparklines Charts</a>
              </li>
              <li>
                <a href="charts-knob.html">Jquery Knob Charts</a>
              </li>
            </ul>
          </div>
        </li>
        <li>
          <a href="#sidebarMaps" data-bs-toggle="collapse">
            <i className="fe-map" />
            <span> Maps </span>
            <span className="menu-arrow" />
          </a>
          <div className="collapse" id="sidebarMaps">
            <ul className="nav-second-level">
              <li>
                <a href="maps-google.html">Google</a>
              </li>
              <li>
                <a href="maps-vector.html">Vector</a>
              </li>
              <li>
                <a href="maps-mapael.html">Mapael</a>
              </li>
            </ul>
          </div>
        </li>
        <li>
          <a href="#sidebarMultilevel" data-bs-toggle="collapse">
            <i className="fe-folder-plus" />
            <span> Multi Level </span>
            <span className="menu-arrow" />
          </a>
          <div className="collapse" id="sidebarMultilevel">
            <ul className="nav-second-level">
              <li>
                <a href="#sidebarMultilevel2" data-bs-toggle="collapse">
                  Second Level <span className="menu-arrow" />
                </a>
                <div className="collapse" id="sidebarMultilevel2">
                  <ul className="nav-second-level">
                    <li>
                      <a href="javascript: void(0);">Item 1</a>
                    </li>
                    <li>
                      <a href="javascript: void(0);">Item 2</a>
                    </li>
                  </ul>
                </div>
              </li>
              <li>
                <a href="#sidebarMultilevel3" data-bs-toggle="collapse">
                  Third Level <span className="menu-arrow" />
                </a>
                <div className="collapse" id="sidebarMultilevel3">
                  <ul className="nav-second-level">
                    <li>
                      <a href="javascript: void(0);">Item 1</a>
                    </li>
                    <li>
                      <a href="#sidebarMultilevel4" data-bs-toggle="collapse">
                        Item 2 <span className="menu-arrow" />
                      </a>
                      <div className="collapse" id="sidebarMultilevel4">
                        <ul className="nav-second-level">
                          <li>
                            <a href="javascript: void(0);">Item 1</a>
                          </li>
                          <li>
                            <a href="javascript: void(0);">Item 2</a>
                          </li>
                        </ul>
                      </div>
                    </li>
                  </ul>
                </div>
              </li>
            </ul>
          </div>
        </li> */}
      </ul>
    </div>
    {/* End Sidebar */}
    <div className="clearfix" />
  </div>
  {/* Sidebar -left */}
</div> }

    </div>
  )
}
