import React, { useContext, useEffect, useRef, useState } from "react";
import LanguageContext from "../../Include/Context/LanguageContext";
import SnackBarContext from "../../Include/Context/SnackBarContext";

export const AddRole_RB = ({RoleEditStatus,RoleEditData,Time,RoleUpdate,setRoleUpdate}) => {
  let closeAddRole_RB = useRef();
  const {setSnackBarData} = useContext(LanguageContext) ;

  const [RoleAccess, setRoleAccess] = useState([]);
  const [BtnRoleLoader, setBtnRoleLoader] = useState(false)
  const SnackBarStatus = useContext(SnackBarContext);
  const [AddRoleFormData, setAddRoleFormData] = useState({
    txt_addrole_id :'0',
    txt_addrole_name :'',
   txt_addrole_status:'0',
  })
  const AddRoleFormDataClear=()=>{
    setAddRoleFormData({
      
        txt_addrole_id :'0',
        txt_addrole_name :'',
       txt_addrole_status:'0',
      
    });
    setRoleAccess([]);
  }
  const AddRoleFormDataUpdate=(data)=>{
    setRoleAccess(data.data);
     setAddRoleFormData({
      txt_addrole_id :data.roleId,
      txt_addrole_name :data.roleName,
      txt_addrole_status:data.status,
      })
  }
 
useEffect(() => {
  // return () => {
    if(RoleEditStatus===0){
      AddRoleFormDataClear();
      GetModuleRole();
    }
    if(RoleEditStatus===1){
     console.log(RoleEditData)
     AddRoleFormDataUpdate(RoleEditData)
    
    }
}, [Time]);


 const GetModuleRole = async ()=>{
  
  const response = await fetch(`${window.endpoint}page/role/data`,{
   method:"GET",
   headers:{
    "Content-Type":"application/json",
   }
  });
  let result =await response.json();
  // setRoleAccess(RoleData[0].data);
  console.table(result.data)
  setRoleAccess(result.data);
 }
  // const getRoleData = () => {
  //   setRoleAccess(RoleData[0].data);
  // };
  const checkRoleAccess = (e, from) => {
    var tempRoleData = RoleAccess;
    let tempData_Role = tempRoleData.map((data) => {
      if (data.id === e.target.value) {
        if (from === "MAIN") {
          let action = data.moduleAction === 0 || data.moduleAction === "0" ? "1" : "0";
          return {
            ...data,
            moduleAction: action,
            addAction: 1,
            deleteAction: 1,
            updateAction: 1,
            viewAction: 1,
            downloadAction: 1,
          };
        }
        if (from === "ADD") {
          let action = e.target.checked?'0':'1';
          // let action = data.addAction === 0 || data.addAction === "0" ? "1" : "0";
          return { ...data, addAction: action };
        }
        if (from === "VIEW") {
          let action = e.target.checked?'0':'1';

          return { ...data, viewAction: action };
        }
        if (from === "EDIT") {
          let action = e.target.checked?'0':'1';

          return { ...data, updateAction: action };
        }
        if (from === "DELETE") {
          let action = e.target.checked?'0':'1';

          return { ...data, deleteAction: action };
        }
        if (from === "DOWNLOAD") {
          let action = e.target.checked?'0':'1';

          return { ...data, downloadAction: action };
        }
      } else {
        return data;
      }
    });
    setRoleAccess(tempData_Role);
  };

  const handleInput = (e)=>{
    setAddRoleFormData({
      ...AddRoleFormData,
      [e.target.name]: e.target.value,
    });
  }
  const frmAddRoleSubmit =async (e)=>{
    e.preventDefault();
    setBtnRoleLoader(true)
    // console.log(addFormData);
    // `${window.endpoint}role/add`
    const response =await fetch(`${window.endpoint}role/add`,
    {
      method:"POST",
      headers:{
        "Content-Type":"application/json",
      },
      body:JSON.stringify({
        txt_addrole_id:AddRoleFormData.txt_addrole_id,
        txt_addrole_name :AddRoleFormData.txt_addrole_name,
        txt_addrole_access:RoleAccess,
        txt_addrole_status:AddRoleFormData.txt_addrole_status,
  
      })
    })
    let result = await response.json();
    console.log(result);
    if(result.data.status===0 || result.data.status==="0"){
      RoleUpdate?setRoleUpdate(false):setRoleUpdate(true)
      closeAddRole_RB.current.click();
      setSnackBarData({
        alert: "success",
        message:result.data.message,
        open: true
      })
    }else{

    }
    setBtnRoleLoader(false)
  }

  
  return (
    <>
      {/* Right modal content */}
      <div
        id="Mdl_AddRole_RB"
        className="modal fade"
        tabIndex={-1}
        role="dialog"
        aria-hidden="true"
      >
        <div
          className="modal-dialog modal-md modal-right"
          style={{ maxWidth: "500px", width: "100%" }}
        >
          <div className="modal-content  h-100">
            <h6 className="fw-semibold px-3 m-0 py-2 font-16 bg-light">
              <span className="d-block py-2">Add Roles</span>
            </h6>
            <div className="modal-header p-0 border-0">
              {/* <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              /> */}
            </div>
            <form id="frm_AddRole" onSubmit={(e)=>{frmAddRoleSubmit(e)}}>
            <div className="modal-body">
              <label className="form-label">Role Name</label>
              <input
                type="text"
                name="txt_addrole_name"
                id="txt_addrole_name"
                value={AddRoleFormData.txt_addrole_name}
                className="form-control mb-2"
                placeholder="Enter Role Name"
                onChange={handleInput}
                required
              />
              <hr></hr>
              <label className="form-label">Role Access Setup</label>
              {RoleAccess &&
                RoleAccess.map((data, index) => (
                  <div key={index}>
                    <div className="form-check">
                      <input
                        className="form-check-input"
                        type="checkbox"
                        id={`txt_${data.id}`}
                        checked={
                          data.moduleAction === 0 || data.moduleAction === "0"
                            ? true
                            : false
                        }
                        value={data.id}
                        onChange={(e) => {
                          checkRoleAccess(e, "MAIN");
                        }}
                      />
                      <label
                        className="form-check-label"
                        htmlFor={`txt_${data.id}`}
                      >
                        {data.moduleName.toUpperCase()}
                      </label>
                    </div>
                    {(data.moduleAction === 0 || data.moduleAction === "0") && (
                      <div className="">
                        <div className="row">
                          <div className="col-sm-2 col-2"></div>
                          <div className="col-sm-10 col-10">
                            <div className="form-check">
                              <input
                                className="form-check-input"
                                type="checkbox"
                                value={data.id}
                                id={`txt_Add_${data.id}`}
                                checked={
                                  data.addAction === 0 || data.addAction === "0"
                                    ? true
                                    : false
                                }
                                onChange={(e) => {
                                  checkRoleAccess(e, "ADD");
                                }}
                              />
                              <label
                                className="form-check-label"
                                htmlFor={`txt_Add_${data.id}`}
                              >
                                Add
                              </label>
                            </div>
                          </div>
                          <div className="col-sm-2 col-2"></div>
                          <div className="col-sm-10 col-10">
                            <div className="form-check">
                              <input
                                className="form-check-input"
                                type="checkbox"
                                checked={
                                  data.viewAction === 0 || data.viewAction === "0"
                                    ? true
                                    : false
                                }
                                value={data.id}
                                id={`txt_View_${data.id}`}
                                onChange={(e) => {
                                  checkRoleAccess(e, "VIEW");
                                }}
                              />
                              <label
                                className="form-check-label"
                                htmlFor={`txt_View_${data.id}`}
                              >
                                View
                              </label>
                            </div>
                          </div>
                          <div className="col-sm-2 col-2"></div>
                          <div className="col-sm-10 col-10">
                            <div className="form-check">
                              <input
                                className="form-check-input"
                                type="checkbox"
                                checked={
                                  data.updateAction === 0 ||
                                  data.updateAction === "0"
                                    ? true
                                    : false
                                }
                                id={`txt_Edit_${data.id}`}
                                value={data.id}
                                onChange={(e) => {
                                  checkRoleAccess(e, "EDIT");
                                }}
                              />
                              <label
                                className="form-check-label"
                                htmlFor={`txt_Edit_${data.id}`}
                              >
                                Edit
                              </label>
                            </div>
                          </div>
                          <div className="col-sm-2 col-2"></div>
                          <div className="col-sm-10 col-10">
                            <div className="form-check">
                              <input
                                className="form-check-input"
                                type="checkbox"
                                checked={
                                  data.deleteAction === 0 ||
                                  data.deleteAction === "0"
                                    ? true
                                    : false
                                }
                                id={`txt_Delete_${data.id}`}
                                value={data.id}
                                onChange={(e) => {
                                  checkRoleAccess(e, "DELETE");
                                }}
                              />
                              <label
                                className="form-check-label"
                                htmlFor={`txt_Delete_${data.id}`}
                              >
                                Delete
                              </label>
                            </div>
                          </div>
                          <div className="col-sm-2 col-2"></div>
                          <div className="col-sm-10 col-10">
                            <div className="form-check">
                              <input
                                className="form-check-input"
                                type="checkbox"
                                checked={
                                  data.downloadAction === 0 ||
                                  data.downloadAction === "0"
                                    ? true
                                    : false
                                }
                                id={`txt_Download_${data.id}`}
                                value={data.id}
                                onChange={(e) => {
                                  checkRoleAccess(e, "DOWNLOAD");
                                }}
                              />
                              <label
                                className="form-check-label"
                                htmlFor={`txt_Download_${data.id}`}
                              >
                                Download
                              </label>
                            </div>
                          </div>
                        </div>
                      </div>
                    )}
                    {/* <hr></hr> */}
                  </div>
                ))}

              <div className="modal-footer mdlFooter">
                <button
                  type="button"
                  className="btn btn-secondary"
                  data-bs-dismiss="modal"
                  ref={closeAddRole_RB}
                >
                  Close
                </button>
                {!BtnRoleLoader && <button type="submit" className="btn btn-primary btnLoader">Submit</button>}
                {BtnRoleLoader && <>
                <button class="btn btn-primary btnLoader" type="button" disabled="">
                                                        <span class="spinner-border spinner-border-sm me-1" role="status" aria-hidden="true"></span>
                                                        Loading...
                                                    </button>
              </>}
              </div>
            </div>
            </form>
          </div>
        </div>
      </div>
    </>
  );
};
