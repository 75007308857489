import React, { Component } from 'react';
import ReactApexChart from "react-apexcharts";

class BarChart extends Component {
    constructor(props) {
        super(props);
        this.state = {
            series: [
                {
                    type: "bar",
                    data: [
                        { x: "03/26/2021 00:00:00 UTC", y: 213.33 },
                        { x: "03/27/2021 00:00:00 UTC", y: 211.03 },
                        { x: "03/28/2021 00:00:00 UTC", y: 210.42 },
                        { x: "03/29/2021 00:00:00 UTC", y: 213.68 },
                        { x: "03/30/2021 00:00:00 UTC", y: 209.17 },
                        { x: "03/31/2021 00:00:00 UTC", y: 210.79 },
                        { x: "04/01/2021 00:00:00 UTC", y: 211.66 },
                        { x: "04/02/2021 00:00:00 UTC", y: 211.67 }
                    ]
                },
                {
                    type: "line",
                    data: [
                        { x: "03/26/2021 00:00:00 UTC", y: 9.21 },
                        { x: "03/27/2021 00:00:00 UTC", y: 9.37 },
                        { x: "03/28/2021 00:00:00 UTC", y: 9.68 },
                        { x: "03/29/2021 00:00:00 UTC", y: 9.21 },
                        { x: "03/30/2021 00:00:00 UTC", y: 9.36 },
                        { x: "03/31/2021 00:00:00 UTC", y: 9.63 },
                        { x: "04/01/2021 00:00:00 UTC", y: 9.47 },
                        { x: "04/02/2021 00:00:00 UTC", y: 9.2 }
                    ]
                }
            ],
            options: {
                chart: {
                    height: 350,
                    type: "line",
                    toolbar: {
                        show: false
                    }
                },
                stroke: {
                    // width: [0, 4]
                },
                title: {
                    text: ""
                },
                dataLabels: {
                    enabled: false,
                    enabledOnSeries: [1]
                },
                legend: {
                    show: false
                },
                xaxis: {
                    type: "datetime"
                },
                yaxis: [
                    {
                        title: {
                            text: ""
                        },
                        axisBorder: {
                            show: true,
                            color: "blue"
                        }
                    },
                    {
                        opposite: true,
                        title: {
                            text: ""
                        },
                        axisBorder: {
                            show: true,
                            color: "blue"
                        }
                    }
                ]
            }
        }
    }
    render() {
        return (
            <div>
                <ReactApexChart
                    options={this.state.options}
                    series={this.state.series}
                    type="line"
                    height={350}
                />
            </div>
        );
    }
}



export default BarChart;